// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Inventory} from '@supermove/models';
import {withFragment, uuid} from '@supermove/utils';

// App
import ItemTypeKind from '@shared/modules/Inventory/enums/ItemTypeKind';
import ItemAttachmentForm from '@shared/modules/Inventory/forms/ItemAttachmentForm';
import ItemTagItemForm from '@shared/modules/Inventory/forms/ItemTagItemForm';

const edit = withFragment(
  (item, {index}) => ({
    itemId: item.id,
    collectionId: item.collectionId,
    name: item.name,
    takeCount: item.takeCount,
    leaveCount: item.leaveCount,
    weight: item.weight,
    volume: item.volume,
    price: item.price,
    isDerivedWeight: item.isDerivedWeight,
    itemTypeId: item.itemTypeId,
    isVoid: item.isVoid,
    isDeleted: item.isDeleted,
    notes: item.notes,
    kind: item?.itemType?.kind || ItemTypeKind.ITEM,
    uuid: item.uuid,
    // Only used for driver inventory
    lotNumber: item.lotNumber,
    color: item.color,
    itemNumber: item.itemNumber,
    itemTagItemForms: item.itemTagItems.map((itemTagItem) => ItemTagItemForm.edit(itemTagItem)),
    itemAttachmentForms: item.attachments.map((itemAttachment) =>
      ItemAttachmentForm.edit(itemAttachment),
    ),

    // private
    isDirty: false,
    // This makes sure we default the take property to true
    // We only consider the item as not take if the leave count is greater than 0
    take: !(item.leaveCount > 0),
    // Index is passed in here so we have a unique identifier for each item
    // itemId cannot be used as a unique id because new items do not have an id
    index,
  }),
  gql`
    ${ItemTagItemForm.edit.fragment}
    ${ItemAttachmentForm.edit.fragment}

    fragment ItemFormV2_edit on Item {
      id
      uuid
      collectionId
      name
      notes
      takeCount
      leaveCount
      weight
      volume
      price
      lotNumber
      color
      itemNumber
      isDerivedWeight
      itemTypeId
      isVoid
      isDeleted
      itemType {
        id
        kind
      }
      itemTagItems {
        id
        ...ItemTagItemForm_edit
      }
      attachments {
        id
        ...ItemAttachmentForm_edit
      }
    }
  `,
);

const _new = ({
  collectionId,
  name = '',
  weight = 0,
  volume = 0,
  price = 0,
  notes = '',
  isDerivedWeight = false,
  itemTypeId,
  index,
  kind,
  isVoid = false,
  lotNumber = null,
  color = null,
  itemNumber = null,
  itemTagItems = [],
  itemAttachments = [],
}) => ({
  // Temp item ID used so that we have a unique identifier to delete the correct row in the virtualized table
  // Without a unique identifier we can't identify which row to rerender in the table
  itemId: `NEW_${uuid()}`,
  collectionId,
  name,
  takeCount: 1,
  leaveCount: 0,
  weight,
  volume,
  price,
  isDerivedWeight,
  itemTypeId,
  isVoid,
  isDeleted: false,
  notes,
  kind,
  uuid: uuid(),
  // Only used for driver inventory
  lotNumber,
  color,
  itemNumber,
  itemTagItemForms: itemTagItems.map((itemTagItem) => ItemTagItemForm.new(itemTagItem)),
  itemAttachmentForms: itemAttachments.map((itemAttachment) =>
    ItemAttachmentForm.new(itemAttachment),
  ),

  // private
  isDirty: true,
  take: true,
  index,
});

const toForm = ({
  itemId,
  collectionId,
  name,
  takeCount,
  leaveCount,
  weight,
  volume,
  price,
  isDerivedWeight,
  itemTypeId,
  isVoid,
  isDeleted,
  notes,
  kind,
  uuid,
  lotNumber,
  color,
  itemNumber,
  itemTagItemForms,
  itemAttachmentForms,

  // private
  isDirty,
  take,
  index,
}) => ({
  itemId,
  collectionId,
  name,
  takeCount,
  leaveCount,
  weight,
  volume,
  price,
  isDerivedWeight,
  itemTypeId,
  isVoid,
  isDeleted,
  notes,
  kind,
  uuid,
  lotNumber,
  color,
  itemNumber,
  itemTagItemForms: itemTagItemForms.map((itemTagItemForm) =>
    ItemTagItemForm.toForm(itemTagItemForm),
  ),
  itemAttachmentForms: itemAttachmentForms.map((itemAttachmentForm) =>
    ItemAttachmentForm.toForm(itemAttachmentForm),
  ),

  // private
  isDirty,
  take,
  index,
});

const toMutation = ({
  itemId,
  collectionId,
  name,
  takeCount,
  leaveCount,
  weight,
  volume,
  price,
  isDerivedWeight,
  itemTypeId,
  isVoid,
  isDeleted,
  notes,
  uuid,
  lotNumber,
  color,
  itemNumber,
  itemTagItemForms,
  itemAttachmentForms,
}) => ({
  // Do not send itemId if it's a newly created item
  itemId: itemId.includes('NEW') ? undefined : itemId,
  collectionId,
  name,
  takeCount: Inventory.getFloatValue(takeCount),
  leaveCount: Inventory.getFloatValue(leaveCount),
  weight: Inventory.getFloatValue(weight),
  volume: Inventory.getFloatValue(volume),
  price,
  isDerivedWeight,
  itemTypeId,
  isVoid,
  isDeleted,
  notes,
  uuid,
  lotNumber: !lotNumber ? null : lotNumber,
  color: !color ? null : color,
  itemNumber,
  itemTagItemForms: itemTagItemForms.map((itemTagItemForm) =>
    ItemTagItemForm.toMutation({...itemTagItemForm, itemUuid: uuid}),
  ),
  itemAttachmentForms: itemAttachmentForms.map((itemAttachmentForm) =>
    ItemAttachmentForm.toMutation({...itemAttachmentForm, itemUuid: uuid}),
  ),
});

const getItemTypeMatchesMinItemNumber = ({minItemNumber, itemForm}) => {
  const min = _.toNumber(minItemNumber);
  if (min) {
    return itemForm.itemNumber >= min;
  }
  return true;
};

const getItemTypeMatchesMaxItemNumber = ({maxItemNumber, itemForm}) => {
  const max = _.toNumber(maxItemNumber);
  if (max) {
    return itemForm.itemNumber <= max;
  }
  return true;
};

const getItemTypeMatchesRoom = ({roomIds, allRooms, itemForm}) => {
  const itemRoom = allRooms.find((room) =>
    room.itemForms.some((filteredItem) => _.isEqual(filteredItem, itemForm)),
  );
  if (roomIds) {
    return _.includes(roomIds, itemRoom.roomId);
  }
  return true;
};

const getItemTypeMatchesLot = ({lotNumbers, itemForm}) => {
  if (lotNumbers) {
    return _.includes(lotNumbers, `${itemForm.lotNumber}-${itemForm.color}`);
  }
  return true;
};

const getItemTypeMatchesTagOrException = ({tagsOrExceptions, itemTagItemKind, itemForm}) => {
  // This is for both item tag or exception types
  if (tagsOrExceptions) {
    return itemForm.itemTagItemForms.some((itemTagItem) => {
      return (
        itemTagItem.kind === itemTagItemKind &&
        itemTagItem.itemTagIds.some((itemTagId) => tagsOrExceptions.includes(itemTagId.toString()))
      );
    });
  }
  return true;
};

const getItemTypeShowVoidedLabel = ({showVoidedLabels, itemForm}) => {
  return showVoidedLabels !== 'false' || itemForm.isVoid === false;
};

const ItemFormV2 = {
  edit,
  new: _new,
  toForm,
  toMutation,

  // Helpers
  getItemTypeMatchesMinItemNumber,
  getItemTypeMatchesMaxItemNumber,
  getItemTypeMatchesRoom,
  getItemTypeMatchesLot,
  getItemTypeMatchesTagOrException,
  getItemTypeShowVoidedLabel,
};

export default ItemFormV2;
