// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useState} from '@supermove/hooks';
import FieldValueExpandable from '@supermove/manager/src/modules/Project/V2/Show/Blocks/components/FieldValueExpandable';
import {Job} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import FieldValue from '@shared/design/components/Field/FieldValue';

const JobContainer = Styled.View`
  width: 100%;
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 4px;
  background-color: ${colors.white};
`;

const Container = Styled.View`
  padding: 16px;
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.gray.border};
`;

const DescriptionView = Styled.View`
  zIndex: ${(props) => 100 - props.index}
`;

const Text = Styled.Text`
  ${Typography.Mobile.Body}
`;

const Label = Styled.Text`
  ${Typography.Mobile.Label}
`;

const Subheading = Styled.Text`
  ${Typography.Mobile.Subheading}
`;

const JOB_DESCRIPTION = 'description';
const PROJECT_DESCRIPTION = 'project.description';

const getDateRange = ({job}) => {
  const startDate = Datetime.toDisplayDateWithWeekdayNameAndYear(new Date(job.startDate));
  const endDate =
    job.endDate && job.endDate !== job.startDate
      ? ` - ${Datetime.convertToDisplayDate(job.endDate, Datetime.DISPLAY_DATE)}`
      : '';
  return `${startDate}${endDate}`;
};

const JobOverviewBlock = ({job}) => {
  const [truncated, setTruncated] = useState({
    [JOB_DESCRIPTION]: true,
    [PROJECT_DESCRIPTION]: true,
  });
  const [enabledToggle, setEnabledToggle] = useState({
    [JOB_DESCRIPTION]: false,
    [PROJECT_DESCRIPTION]: false,
  });

  return (
    <JobContainer>
      <Container>
        <Subheading>Job Overview</Subheading>
      </Container>
      <Line />
      <Container>
        <Label>Date</Label>
        <Space height={4} />
        <Text>{getDateRange({job})}</Text>
        <Space height={8} />
        <Label>Origin Arrival Window</Label>
        <Space height={4} />
        <Text>{Job.getArrivalWindow(job)}</Text>
        <Space height={8} />
        <Label>Job Description</Label>
        <Space height={4} />
        <DescriptionView index={0}>
          <FieldValueExpandable
            size={FieldValue.SIZE.MEDIUM}
            data={job}
            property={JOB_DESCRIPTION}
            numberOfLines={2}
            isTruncated={truncated[JOB_DESCRIPTION]}
            isEnabledToggle={enabledToggle[JOB_DESCRIPTION]}
            setTruncated={setTruncated}
            setEnabledToggle={setEnabledToggle}
          />
        </DescriptionView>
        <Space height={8} />
        <Label>Project Description</Label>
        <Space height={4} />
        <DescriptionView index={1}>
          <FieldValueExpandable
            size={FieldValue.SIZE.MEDIUM}
            data={job}
            property={PROJECT_DESCRIPTION}
            numberOfLines={2}
            isTruncated={truncated[PROJECT_DESCRIPTION]}
            isEnabledToggle={enabledToggle[PROJECT_DESCRIPTION]}
            setTruncated={setTruncated}
            setEnabledToggle={setEnabledToggle}
          />
        </DescriptionView>
        <Space height={4} />
      </Container>
    </JobContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobOverviewBlock.fragment = gql`
  fragment JobOverviewBlock on Job {
    id
    startTime1
    startTime2
    startDate
    endDate
    description
    project {
      id
      description
    }
  }
`;

export default JobOverviewBlock;
