// Libraries
import React from 'react';

// Supermove
import {MapView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import MapMarker from '@supermove/manager/src/modules/App/Map/components/MapMarker';
import SkeletonLoader from '@supermove/manager/src/modules/App/components/SkeletonLoader';
import {colors} from '@supermove/styles';
import {Location} from '@supermove/utils';

const SkeletonComponent = ({height}) => {
  return <SkeletonLoader height={height} />;
};

const LocationsMap = ({
  locations,
  organization,
  mapRef,
  setLocationIdsWithStreetView,
  showRoute,
  onRouteUpdate,
}) => {
  const validLocations = locations.filter((location) => location.latitude && location.longitude);
  const locationPoints = validLocations.map((location) => Location.create(location));
  const warehousePosition =
    organization.warehouseLocation && Location.create(organization.warehouseLocation);

  return (
    <MapView
      mapTypeControl
      streetViewControl
      bounds={[...locationPoints, ...(warehousePosition ? [warehousePosition] : [])]}
      directions={showRoute && locationPoints}
      onRouteUpdate={onRouteUpdate}
      style={{flex: 1, maxHeight: 400}}
      onReady={(mapProps, map) => {
        if (mapRef) {
          mapRef.current = map;
        }
      }}
    >
      {({route, ...props}) => {
        const streetViewService = new props.google.maps.StreetViewService();
        return (
          <React.Fragment>
            {warehousePosition && (
              <MapMarker kind={MapMarker.WAREHOUSE} position={warehousePosition} {...props} />
            )}
            {validLocations.map((location, index) => {
              const latLng = new props.google.maps.LatLng(location.latitude, location.longitude);
              streetViewService.getPanorama({location: latLng}, (data, status) => {
                if (status === 'OK') {
                  setLocationIdsWithStreetView((locationIdsWithStreetView) => {
                    if (locationIdsWithStreetView.includes(location.id)) {
                      return locationIdsWithStreetView;
                    }
                    return [...locationIdsWithStreetView, location.id];
                  });
                }
              });
              return (
                <React.Fragment key={location.id}>
                  <MapMarker
                    kind={MapMarker.DEFAULT}
                    label={MapMarker.createMarkerLabel(index + 1)}
                    position={Location.create({
                      latitude: location.latitude,
                      longitude: location.longitude,
                    })}
                    {...props}
                  />
                  {showRoute && (
                    <MapView.Polyline
                      path={route}
                      strokeColor={colors.gray.primary}
                      strokeWeight={5}
                      {...props}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </React.Fragment>
        );
      }}
    </MapView>
  );
};

LocationsMap.SkeletonComponent = SkeletonComponent;

// --------------------------------------------------
// Data
// --------------------------------------------------
LocationsMap.fragment = gql`
  fragment LocationsMap_Location on Location {
    id
    latitude
    longitude
  }
  fragment LocationsMap_Organization on Organization {
    id
    warehouseLocation {
      latitude
      longitude
    }
  }
`;

export default LocationsMap;
