// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import Toast from '@shared/design/components/Toast';

const SuccessToast = ({message, actionText, handleAction, handleDismiss, handleRemove}) => {
  return (
    <Toast
      icon={Icon.CheckCircle}
      message={message}
      actionText={actionText}
      handleAction={handleAction}
      handleDismiss={handleDismiss}
      handleRemove={handleRemove}
      color={colors.green.status}
    />
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
SuccessToast.propTypes = {
  message: PropTypes.string.isRequired,
  actionText: PropTypes.string,
  handleAction: PropTypes.func,
  handleDismiss: PropTypes.func,
  handleRemove: PropTypes.func,
};

SuccessToast.defaultProps = {
  actionText: '',
  handleAction: () => {},
  handleDismiss: () => {},
  handleRemove: () => {},
};

export default SuccessToast;
