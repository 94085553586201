// Libraries
import React from 'react';

// Supermove
import {Space, Loading, SupermoveLogo} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';

// App
import Drawer from '@shared/design/components/Drawer';
import MobileHeader from 'modules/App/components/MobileHeader';
import MobileNavigationSidebar from 'modules/App/components/MobileNavigationSidebar';
import {getSidebarItems} from 'modules/App/components/data/getSidebarItems';

const MobileNavigationDrawer = ({isOpen, handleClose, selected}) => {
  const {loading, data} = useQuery(MobileNavigationDrawer.query);

  return (
    <Drawer isOpen={isOpen} handleClose={handleClose} isLeft>
      <MobileHeader handleClose={handleClose}>
        <Space style={{flex: 1}} />
        <SupermoveLogo.Name size={16} />
        <Space width={16} />
      </MobileHeader>
      <Loading loading={loading}>
        {() => (
          <MobileNavigationSidebar
            viewer={data.viewer}
            selected={selected}
            sidebarItems={getSidebarItems({viewer: data.viewer})}
            handleClose={handleClose}
          />
        )}
      </Loading>
    </Drawer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
MobileNavigationDrawer.query = gql`
  ${getSidebarItems.fragment}
  ${MobileNavigationSidebar.fragment}

  query MobileNavigationDrawer {
    ${gql.query}
    viewer {
      id
      ...getSidebarItems
      ...MobileNavigationSidebar
    }
  }
`;

export default MobileNavigationDrawer;
