const MISSED = 'MISSED';
const IN_PROGRESS = 'IN_PROGRESS';
const COMPLETE = 'COMPLETE';

const CallStatus = {
  MISSED,
  IN_PROGRESS,
  COMPLETE,
};

export default CallStatus;
