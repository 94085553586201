// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (jobUser) => ({
    jobUserId: jobUser.id,
    jobId: jobUser.jobId,
    crewId: jobUser.crewId,
    userId: jobUser.userId,
    position: jobUser.position,
    branchCode: jobUser.branchCode,
    moverPositionIds: jobUser.jobUserMoverPositions.map(
      (jobUserMoverPosition) => jobUserMoverPosition.moverPositionId,
    ),

    // Only for rendering.
    firstName: jobUser.user.firstName,
  }),
  gql`
    fragment JobUserForm_edit on JobUser {
      id
      jobId
      crewId
      userId
      position
      branchCode
      user {
        id
        firstName
      }
      jobUserMoverPositions {
        id
        moverPositionId
      }
    }
  `,
);

const _new = ({jobId, crewId, userId, firstName, position, branchCode, moverPositionIds}) => ({
  jobUserId: null,
  jobId,
  crewId,
  userId,
  position,
  branchCode,
  moverPositionIds,

  // Only for rendering.
  firstName,
});

const toForm = ({
  jobUserId,
  jobId,
  crewId,
  userId,
  firstName,
  position,
  branchCode,
  moverPositionIds,
}) => ({
  jobUserId,
  jobId,
  crewId,
  userId,
  position,
  branchCode,
  moverPositionIds,

  // Only for rendering.
  firstName,
});

const toMutation = ({
  jobUserId,
  jobId,
  crewId,
  userId,
  position,
  branchCode,
  moverPositionIds,
}) => ({
  jobUserId,
  jobId,
  crewId,
  userId,
  position,
  branchCode,
  moverPositionIds,
});

const JobUserForm = {
  edit,
  new: _new,
  toForm,
  toMutation,
};

export default JobUserForm;
