// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {
  useDebouncedCallback,
  useEffect,
  useInternet,
  useQuery,
  useNavigation,
  useBeforeRefreshEffect,
  useSubmitMutationWithKeepAlive,
} from '@supermove/hooks';
import {Datetime} from '@supermove/utils';

// App
import InventoryRoomsForm from '@shared/modules/Inventory/forms/InventoryRoomsForm';
import useUpdateInventoryRoomsMutation from '@shared/modules/Inventory/hooks/useUpdateInventoryRoomsMutation';
import MobileHeaderPage from 'modules/App/components/MobileHeaderPage';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import DriverInventoryItemFields from 'modules/DriverInventory/Items/DriverInventoryItemFields';
import DriverInventoryRoomFields from 'modules/DriverInventory/Rooms/DriverInventoryRoomFields';
import FilterRoomItemsDrawer from 'modules/DriverInventory/Rooms/FilterRoomItemsDrawer';
import ShowDriverInventoryRoom from 'modules/DriverInventory/Rooms/ShowDriverInventoryRoom';
import ShowDriverInventory from 'modules/DriverInventory/ShowDriverInventory';
import DriverInventoryContext from 'modules/DriverInventory/context/DriverInventoryContext';

const resetIsDirty = (form) => {
  form.setFieldValue('inventoryRoomsForm.isDirty', false);

  const {roomItemsForms} = form.values.inventoryRoomsForm;
  roomItemsForms.forEach((roomItemsForm, roomItemsFormIndex) => {
    form.setFieldValue(`inventoryRoomsForm.roomItemsForms.${roomItemsFormIndex}.isDirty`, false);
    roomItemsForm.itemForms.forEach((_itemForm, itemFormIndex) => {
      form.setFieldValue(
        `inventoryRoomsForm.roomItemsForms.${roomItemsFormIndex}.itemForms.${itemFormIndex}.isDirty`,
        false,
      );
    });
  });
};

const DriverInventoryMainScreenContent = ({viewer, inventory, job, children, refetch}) => {
  const persistedForm = JSON.parse(localStorage.getItem(`inventory_${inventory.id}`));
  const {form, handleSubmit, mutation} = useUpdateInventoryRoomsMutation({
    inventoryRoomsForm: persistedForm || InventoryRoomsForm.edit(inventory, {jobId: job.id}),
    // NOTE(cooper): Using enableReinitialize can result in tricky bugs since any unsaved updates that
    // have been made on the form will get wiped when the form in reinitialized (upon refetch of the
    // original query). To address this, we make sure that we _only_ refetch the page in certain
    // circumstances where losses cannot occur (ie. upon navigating back to the inventory homepage)
    enableReinitialize: true,
    onSuccess: () => {
      // Mark the lastSyncedAt timestamp. This is also tracked on the backend but we
      // are not refetching after saves, so we need to update it here as well
      form.setFieldValue('inventoryRoomsForm.lastSyncedAt', Datetime.toTimestamp(Datetime.now));
    },
    onError: (errors) => console.log(errors),
  });

  const handleSubmitWithKeepAlive = useSubmitMutationWithKeepAlive({
    mutation,
    variables: {
      inventoryRoomsForm: InventoryRoomsForm.toMutation(form.values.inventoryRoomsForm),
    },
  });

  const {isConnected} = useInternet();
  const {hasUnsavedChanges} = InventoryRoomsForm.getInfo(form.values.inventoryRoomsForm);

  const handleSave = ({useKeepAlive = false} = {}) => {
    if (useKeepAlive) {
      handleSubmitWithKeepAlive({
        onSuccess: () => {
          form.setFieldValue('inventoryRoomsForm.lastSyncedAt', Datetime.toTimestamp(Datetime.now));
        },
      });
    } else {
      handleSubmit();
    }

    resetIsDirty(form);
    localStorage.removeItem(`inventory_${inventory.id}`);
  };

  // Execute a mutation every 10 seconds if internet connection is established and changes are detected
  const debouncedHandleSubmit = useDebouncedCallback(() => {
    if (isConnected && hasUnsavedChanges) {
      handleSave();
    }
  }, 10000);

  useEffect(() => debouncedHandleSubmit(), [form, debouncedHandleSubmit, isConnected]);

  // NOTE(cooper): Take some actions if there are unsaved changes when the user exits Driver Inventory,
  // notice that we persist the form in localStorage in the event that we don't have internet connection.
  const handleExitDriverInventoryExperience = () => {
    if (hasUnsavedChanges) {
      if (!isConnected) {
        // If there is no connection, persist the form in local storage
        localStorage.setItem(
          `inventory_${inventory.id}`,
          JSON.stringify(form.values.inventoryRoomsForm),
        );
      } else {
        // If we have connection, initiate a save before exiting
        handleSave({useKeepAlive: true});
        debouncedHandleSubmit.cancel(); // Cancel any pending debounced saves
      }
    }
  };

  // Initiate a save if the user refreshes the page with unsaved changes
  useBeforeRefreshEffect(
    ({handleExitDriverInventoryExperience}) => {
      handleExitDriverInventoryExperience();
    },
    {handleExitDriverInventoryExperience},
  );

  return (
    <DriverInventoryContext.Provider
      value={{
        form,
        inventory,
        job,
        viewer,
        onExitDriverInventoryExperience: handleExitDriverInventoryExperience,
        // NOTE(cooper): We only expose refetch for use in RARE cases, children should not be calling refetch regularly
        // and we only allow refetching when internet is connected
        refetch: () => {
          if (isConnected) {
            if (hasUnsavedChanges) {
              // If we have unsaved changes, initiate a save before refetching so that we don't lose any new changes
              handleSave();
              debouncedHandleSubmit.cancel(); // Cancel any pending debounced saves
            }

            refetch();
          }
        },
      }}
    >
      {children}
    </DriverInventoryContext.Provider>
  );
};

/**
 * NOTE(cooper): Driver Inventory on the mover app is designed to operate in suboptimal network conditions.
 * We achieve this by minimizing the amount of queries and mutations within the driver inventory views.
 * This component takes care of fetching all of the information necessary to perform inventory capture
 * as well as maintains a form that represents the state of the inventory during capture. Downstream
 * components assume that the form is the single source of truth rather than making their own queries.
 * Similarly, the mutation that's made in this file takes care of upserting all of the information for an
 * inventory, notice that optimizations are made such that we do not attempt to update subforms that are unchanged.
 */
const DriverInventoryMainScreen = ({children}) => {
  const {params} = useNavigation();
  const {loading, data, refetch} = useQuery(DriverInventoryMainScreen.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      inventoryUuid: params.inventoryUuid,
      jobUuids: [params.jobUuid],
      jobUuid: params.jobUuid,
    },
  });

  if (loading) {
    return <PageLoadingIndicator />;
  }

  if (params.hideHeader) {
    return (
      <React.Fragment>
        <DriverInventoryMainScreenContent
          viewer={data.viewer}
          inventory={data.inventory}
          job={data.job}
          children={children}
          refetch={refetch}
        />
      </React.Fragment>
    );
  }

  return (
    <MobileHeaderPage selected={'calendar'}>
      <DriverInventoryMainScreenContent
        viewer={data.viewer}
        inventory={data.inventory}
        job={data.job}
        children={children}
        refetch={refetch}
      />
    </MobileHeaderPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DriverInventoryMainScreen.query = gql`
  ${ShowDriverInventory.inventoryFragment}
  ${ShowDriverInventory.jobFragment}
  ${ShowDriverInventoryRoom.fragment}
  ${DriverInventoryRoomFields.fragment}
  ${DriverInventoryItemFields.fragment}
  ${FilterRoomItemsDrawer.fragment}
  ${InventoryRoomsForm.edit.fragment}

  query DriverInventoryMainScreen($inventoryUuid: String!, $jobUuids: [String]!, $jobUuid: String!) {
    ${gql.query}
    viewer {
      id
    }
    inventory(uuid: $inventoryUuid) {
      id
      ...ShowDriverInventory_inventory
      ...ShowDriverInventoryRoom
      ...DriverInventoryRoomFields
      ...DriverInventoryItemFields
      ...InventoryRoomsForm_edit
      project {
        id
        projectType {
          id
          defaultDriverInventoryLibrary {
            id
            ...FilterRoomItemsDrawer
          }
        }
      }
    }
    job(uuid: $jobUuid) {
      id
      ...ShowDriverInventory_job
    }
  }
`;

export default DriverInventoryMainScreen;
