// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon, Space} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import QuaternaryButton from '@shared/design/components/Button/QuaternaryButton';
import SmallModal from '@shared/design/components/Modal/SmallModal';

const ActionModal = ({
  color,
  iconColor,
  titleColor,
  icon,
  title,
  message,
  isOpen,
  handlePressOutside,
  handlePrimaryAction,
  handleSecondaryAction,
  primaryActionText,
  secondaryActionText,
  isSubmitting,
  isDisabled,
  isLoading,
  children,
}) => {
  const responsive = useResponsive();

  return (
    <SmallModal
      isOpen={isOpen}
      handlePressOutside={handlePressOutside}
      style={{alignItems: 'center'}}
      isLoading={isLoading}
      isResponsive
    >
      {!!icon && (
        <React.Fragment>
          <SmallModal.HeaderIcon source={icon} color={iconColor || color} />
          <Space height={16} />
        </React.Fragment>
      )}
      {!!title && (
        <React.Fragment>
          <SmallModal.HeaderText style={{color: titleColor || color, textAlign: 'center'}}>
            {title}
          </SmallModal.HeaderText>
          <Space height={12} />
        </React.Fragment>
      )}
      <SmallModal.Text responsive={responsive} style={{textAlign: 'center'}}>
        {message}
      </SmallModal.Text>
      {children}
      <Space height={16} />
      <SmallModal.Footer isResponsive>
        {/* HACK(dan) We add a setTimeout to the button onPress handlers to avoid a bug
        where the click action is received by the content behind the modal. This does
        not get resolved by preventing propagation and may have to do with the behavior
        of onMouseUp vs onClick. */}
        {!!handleSecondaryAction && (
          <React.Fragment>
            <QuaternaryButton
              text={secondaryActionText}
              textColor={color}
              onPress={() => setTimeout(handleSecondaryAction, 0)}
              isWidthOfContainer
              style={responsive.mobile ? null : {flex: 1}}
              isResponsive
            />
            <Space width={8} height={12} />
          </React.Fragment>
        )}
        {!!handlePrimaryAction && (
          <Button
            text={primaryActionText}
            color={color}
            onPress={() => setTimeout(handlePrimaryAction, 0)}
            isWidthOfContainer
            style={responsive.mobile ? null : {flex: 1}}
            isSubmitting={isSubmitting}
            isDisabled={isDisabled}
            isResponsive
          />
        )}
      </SmallModal.Footer>
    </SmallModal>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
ActionModal.propTypes = {
  isOpen: PropTypes.bool,
  color: PropTypes.string,
  iconColor: PropTypes.string,
  titleColor: PropTypes.string,
  icon: Icon.SourcePropType,
  title: PropTypes.string,
  handlePressOutside: PropTypes.func,
  handlePrimaryAction: PropTypes.func,
  primaryActionText: PropTypes.string,
  handleSecondaryAction: PropTypes.func,
  secondaryActionText: PropTypes.string,
  isSubmitting: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};

ActionModal.defaultProps = {
  isOpen: false,
  color: colors.blue.interactive,
  iconColor: null,
  titleColor: null,
  icon: null,
  title: null,
  handlePressOutside: () => {},
  handlePrimaryAction: null,
  primaryActionText: '',
  handleSecondaryAction: null,
  secondaryActionText: '',
  isSubmitting: false,
  isDisabled: false,
  isLoading: false,
};

export default ActionModal;
