// Libraries
import _ from 'lodash';

// App
import {Currency} from '@supermove/utils';

const OTHER_METHOD = {
  value: 'OTHER',
  name: 'Other',
  settingsName: 'Other',
  action: 'Other',
  screen: 'OtherPaymentCustomerBillJob',
  projectBillingScreen: 'OtherMethodNewPaymentBillingProjectJobPage',
};

const Payment = {
  /**
   * List of methods that customers can pay with.
   *   value: Database value.
   *   name: Name of the payment method shown with "Paid By: {name}".
   *   action: Name shown on the Crew app to use this method.
   *   screen: Crew app screen that should be navigated to.
   */
  METHODS: [
    {
      value: 'CASH',
      name: 'Cash',
      settingsName: 'Cash',
      action: 'Cash',
      screen: 'CashPaymentCustomerBillJob',
      projectBillingScreen: 'CashMethodNewPaymentBillingProjectJobPage',
    },
    {
      value: 'CHECK',
      name: 'Check',
      settingsName: 'Check',
      action: 'Check',
      screen: 'CheckPaymentCustomerBillJob',
      projectBillingScreen: 'CheckMethodNewPaymentBillingProjectJobPage',
    },
    {
      value: 'ACH',
      name: 'ACH',
      settingsName: 'ACH',
      action: 'ACH',
      // This method isn't supposed on the crew app so we have it route to the "Other" page for now.
      screen: 'OtherPaymentCustomerBillJob',
      projectBillingScreen: 'OtherMethodNewPaymentBillingProjectJobPage',
    },
    {
      value: 'AUTHORIZE_DOT_NET',
      name: 'Credit Card (Authorize.net)',
      settingsName: 'Credit Card (Authorize.net)',
      action: 'Credit Card - Pay Now',
      screen: 'AuthorizeDotNetPaymentCustomerBillJob',
      projectBillingScreen: 'AuthorizeDotNetMethodNewPaymentBillingProjectJobPage',
    },
    {
      value: 'EXTERNAL',
      name: 'External Credit Card',
      settingsName: 'External Credit Card',
      action: 'External Credit Card',
      screen: 'ExternalPaymentCustomerBillJob',
      projectBillingScreen: 'ExternalMethodNewPaymentBillingProjectJobPage',
    },
    {
      value: 'PAYPAL',
      name: 'PayPal',
      settingsName: 'PayPal',
      action: 'PayPal',
      screen: 'PayPalPaymentCustomerBillJob',
      projectBillingScreen: 'PaypalMethodNewPaymentBillingProjectJobPage',
    },
    {
      value: 'PAYENGINE_CREDIT_CARD',
      name: 'Credit Card',
      settingsName: 'Credit Card (Supermove)',
      action: 'Credit Card - Pay Now',
      screen: undefined, // 'screen' should be deprecated in favor of projectBillingScreen
      projectBillingScreen: 'PayEngineCreditCardMethodNewPaymentBillingProjectJobPage',
    },
    {
      value: 'STRIPE_CREDIT_CARD',
      name: 'Credit Card',
      settingsName: 'Credit Card (Stripe)',
      action: 'Credit Card - Pay Now',
      screen: 'CreditCardPaymentCustomerBillJob',
      projectBillingScreen: 'CreditCardMethodNewPaymentBillingProjectJobPage',
    },
    {
      value: 'STRIPE_TERMINAL',
      name: 'Swiped Credit Card',
      settingsName: 'Swiped Credit Card',
      action: 'Swipe Credit Card',
      screen: 'TerminalPaymentCustomerBillJob',
    },
    {
      value: 'VENMO',
      name: 'Venmo',
      settingsName: 'Venmo',
      action: 'Venmo',
      screen: 'VenmoPaymentCustomerBillJob',
      projectBillingScreen: 'VenmoMethodNewPaymentBillingProjectJobPage',
    },

    // Pay Later options, these are not valid PaymentMethods but they have
    // options that the customer can select.
    {
      value: 'INVOICE',
      name: 'Invoice',
      settingsName: 'Invoice',
      action: 'Invoice - Pay Later',
      screen: 'InvoicePaymentCustomerBillJob',
      projectBillingScreen: 'InvoiceMethodNewPaymentBillingProjectJobPage',
    },
    {
      value: 'STRIPE_SAVE_CARD',
      name: 'Save Card & Pay Later',
      settingsName: 'Save Card & Pay Later',
      action: 'Save Card & Pay Later',
      screen: 'SaveCardPaymentCustomerBillJob',
      projectBillingScreen: 'SaveCardMethodNewPaymentBillingProjectJobPage',
    },
    {
      value: 'PAYENGINE_SAVE_CARD',
      name: 'Save Card & Pay Later',
      settingsName: 'Save Card & Pay Later',
      action: 'Save Card & Pay Later',
      screen: undefined, // 'screen' should be deprecated in favor of projectBillingScreen
      projectBillingScreen: 'PayEngineSaveCardMethodNewPaymentBillingProjectJobPage',
    },

    // Generic catch-all method for when a PaymentMethod isn't known.
    OTHER_METHOD,
  ],

  // Used to determine the methods available in the `Payment > Record Payment` dropdown on the job
  // page. Credit card methods are hidden here, because the `Payment > Charge Credit Card` option
  // should handle those charges
  getOfficeMethodOptions: () => {
    // Credit card methods are hidden here
    const hiddenOfficeMethods = [
      'STRIPE_SAVE_CARD',
      'INVOICE',
      'STRIPE_TERMINAL',
      'STRIPE_CREDIT_CARD',
      'PAYENGINE_CREDIT_CARD',
      'PAYENGINE_SAVE_CARD',
    ];
    const visibleOfficeMethods = Payment.METHODS.filter(
      (method) => !_.includes(hiddenOfficeMethods, method.value),
    );
    return _.sortBy(visibleOfficeMethods, (method) => method.name);
  },

  /**
   * Returns true if the specified method is a known method this client can handle.
   */
  isValidMethod: ({method}) => {
    return !!_.find(Payment.METHODS, (option) => option.value === method);
  },

  getMethodOption: ({method}) => {
    return _.find(Payment.METHODS, (option) => option.value === method) || OTHER_METHOD;
  },

  getMethodOptions: ({methods}) => {
    return methods.map((method) => Payment.getMethodOption({method}));
  },

  /**
   * Name of the payment method displayed on billing-related pages. This should be the default used
   * in most parts of the app.
   */
  getDisplayMethod: ({method}) => {
    return _.get(Payment.getMethodOption({method}), 'name');
  },

  /**
   * Name of the payment method displayed on settings pages. This displays more details on the
   * underlying payment processor (e.g. 'Credit Card (Stripe)' instead of just 'Credit Card').
   */
  getSettingsDisplayMethod: ({method}) => {
    return _.get(Payment.getMethodOption({method}), 'settingsName');
  },

  getMethodDropdownOptions: ({methods}) => {
    const options = Payment.getMethodOptions({methods});
    return options.map((option) => ({
      label: option.name,
      value: option.value,
    }));
  },

  getActionForMethod: ({method}) => {
    return _.get(Payment.getMethodOption({method}), 'action');
  },

  getScreenForMethod: ({method}) => {
    return _.get(Payment.getMethodOption({method}), 'screen');
  },

  getProjectBillingScreenForMethod: ({method}) => {
    return _.get(Payment.getMethodOption({method}), 'projectBillingScreen');
  },

  toForm: ({paymentId, method, totalAmount}) => ({
    paymentId,
    method,
    totalAmount: Currency.toForm(totalAmount),
  }),

  toMutation: ({paymentId, method, totalAmount}) => ({
    paymentId,
    method,
    totalAmount: Currency.toMutation(totalAmount),
  }),
};

export default Payment;
