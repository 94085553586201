// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Styled, Space, ScrollView} from '@supermove/components';
import {
  useDebouncedCallback,
  useDrawer,
  useEffect,
  useState,
  useContext,
  useNavigation,
  useUrlFilters,
} from '@supermove/hooks';
import {Inventory} from '@supermove/models';
import {Typography, colors} from '@supermove/styles';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import EmptyState from '@shared/design/components/EmptyState';
import SearchBar from '@shared/design/components/SearchBar';
import ItemTagItemKind from '@shared/modules/Inventory/enums/ItemTagItemKind';
import ItemFormV2 from '@shared/modules/Inventory/forms/ItemFormV2';
import FilterRoomItemsDrawer from 'modules/DriverInventory/Rooms/FilterRoomItemsDrawer';
import ItemsTable from 'modules/DriverInventory/components/ItemsTable';
import LastSavedInfo from 'modules/DriverInventory/components/LastSavedInfo';
import DriverInventoryContext from 'modules/DriverInventory/context/DriverInventoryContext';

const Container = Styled.View`
  padding-top: 16px;
  padding-horizontal: 16px;
  height: 100%;
  background-color: ${colors.gray.background}
`;

const EmptyStateContainer = Styled.View`
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 4px;
  background-color: ${colors.white};
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const TitleText = Styled.Text`
  ${Typography.PageHeading}
`;

const DescriptionText = Styled.Text`
  ${Typography.Body}
  color: ${(props) => props.color};
`;

const clearUrlFilters = ({urlFilters}) => {
  // Clear param filters before navigating to a different page
  urlFilters.handleUpdate({
    query: null,
    minItemNumber: null,
    maxItemNumber: null,
    roomIds: null,
    lotNumbers: null,
    colors: null,
    itemTags: null,
    exceptionTypes: null,
    showVoidedLabels: null,
    isOpen: null,
  });
};

const getItemTypeMatchesSearch = ({searchTerm, item, roomItemForms}) => {
  const colorMatchSearch = () => {
    const colorOptions = Inventory.getColorOptions();
    const colorOption = colorOptions.find((option) => option.value === item.color);
    return colorOption ? colorOption.label.toLowerCase().includes(searchTerm.toLowerCase()) : false;
  };

  const itemNumberSearch = item.itemNumber.toLowerCase().includes(searchTerm.toLowerCase());
  const lotNumberSearch = item.lotNumber
    ? item.lotNumber.toLowerCase().includes(searchTerm.toLowerCase())
    : false;
  const nameSearch = item.name.toLowerCase().includes(searchTerm.toLowerCase());

  const itemRoom = roomItemForms.find((room) =>
    room.itemForms.some((itemForm) => _.isEqual(itemForm, item)),
  );
  const itemRoomNameSearch = itemRoom.name.toLowerCase().includes(searchTerm.toLowerCase());

  const hasSearchTerm =
    searchTerm &&
    (itemNumberSearch || lotNumberSearch || colorMatchSearch() || nameSearch || itemRoomNameSearch);

  return hasSearchTerm || !searchTerm;
};

const getFilteredItemTypes = ({filters, roomItemForms}) => {
  const items = _.orderBy(
    _.flatMap(roomItemForms, (room) => room.itemForms),
    [
      (item) => !item.lotNumber && !item.color,
      'lotNumber',
      'color',
      (item) => _.toNumber(item.itemNumber),
    ],
    ['asc', 'asc', 'asc', 'asc'],
  );
  const searchTerm = filters.query || '';
  const {
    minItemNumber,
    maxItemNumber,
    roomIds,
    lotNumbers,
    itemTags,
    exceptionTypes,
    showVoidedLabels,
  } = filters;

  return _.filter(items, (item) => {
    return (
      ItemFormV2.getItemTypeMatchesMinItemNumber({minItemNumber, itemForm: item}) &&
      ItemFormV2.getItemTypeMatchesMaxItemNumber({maxItemNumber, itemForm: item}) &&
      ItemFormV2.getItemTypeMatchesRoom({
        roomIds,
        allRooms: roomItemForms,
        itemForm: item,
      }) &&
      ItemFormV2.getItemTypeMatchesLot({lotNumbers, itemForm: item}) &&
      ItemFormV2.getItemTypeMatchesTagOrException({
        tagsOrExceptions: itemTags,
        itemTagItemKind: ItemTagItemKind.ITEM_TAG,
        itemForm: item,
      }) &&
      ItemFormV2.getItemTypeMatchesTagOrException({
        tagsOrExceptions: exceptionTypes,
        itemTagItemKind: ItemTagItemKind.EXCEPTION,
        itemForm: item,
      }) &&
      ItemFormV2.getItemTypeShowVoidedLabel({showVoidedLabels, itemForm: item}) &&
      getItemTypeMatchesSearch({searchTerm, item, roomItemForms})
    );
  });
};

const getGroupedItems = (items) => {
  return _.groupBy(items, (item) => {
    // NOTE(cassie): This replaces null with tilde, which is after all alphanumeric characters. This will put null groups last.
    const lotNumber = item.lotNumber ?? '~';
    const color = item.color ?? '~';
    return `${lotNumber}-${color}`;
  });
};

const SearchInput = ({urlFilters, params}) => {
  const handleUpdateQuery = useDebouncedCallback((text) => {
    urlFilters.handleUpdate({query: text});
  }, 500);
  return (
    <SearchBar
      placeholder={'Search inventory'}
      iconColor={colors.gray.tertiary}
      containerStyle={{flex: 1}}
      style={{width: '100%'}}
      onChangeText={handleUpdateQuery}
      defaultValue={params.query}
      isResponsive
      isClearable
    />
  );
};

const InventorySection = ({form, urlFilters, navigator, params}) => {
  const [filteredGroupedItems, setFilteredGroupedItems] = useState([]);
  const filterItemDrawer = useDrawer({name: 'Filter Item Drawer', enableTracking: true});
  const filterCount = urlFilters.getFilterCount({
    filterKeys: [
      'minItemNumber',
      'maxItemNumber',
      'roomIds',
      'lotNumbers',
      'itemTags',
      'exceptionTypes',
      'showVoidedLabels',
    ],
  });
  const roomItemForms = _.get(form.values, 'inventoryRoomsForm.roomItemsForms');
  useEffect(() => {
    setFilteredGroupedItems(
      getGroupedItems(
        getFilteredItemTypes({
          filters: urlFilters.getFilters(),
          roomItemForms,
        }),
      ),
    );
  }, [urlFilters, roomItemForms]);
  const firstGroupedItemsKey = _.first(_.keys(filteredGroupedItems));
  const lastGroupedItemsKey = _.last(_.keys(filteredGroupedItems));

  return (
    <>
      <TitleText>View Full Inventory</TitleText>
      <Space height={16} />
      <DescriptionText>View the full list of inventory captured for this job.</DescriptionText>
      <Space height={16} />
      <LastSavedInfo lastSyncedAt={form.values.inventoryRoomsForm.lastSyncedAt} />
      <Space height={16} />
      <Row>
        <SearchInput urlFilters={urlFilters} params={params} />
        <Space width={8} />
        <SecondaryButton
          onPress={() => urlFilters.handleUpdate({isOpen: true})}
          text={`(${filterCount})`}
          iconLeft={Icon.Filter}
          style={{minWidth: 65, height: 40}}
        />
      </Row>
      <Space height={16} />
      {_.size(filteredGroupedItems) === 0 && (
        <EmptyStateContainer>
          <EmptyState title={'No inventory added yet.'} message={'To get started, add an item.'} />
          <Space height={16} />
        </EmptyStateContainer>
      )}
      {_.map(filteredGroupedItems, (groupItems, groupKey) => {
        return (
          <ItemsTable
            key={`collapsible-${groupKey}`}
            collapsibleTableStyle={{
              borderTopStartRadius: firstGroupedItemsKey === groupKey ? 4 : 0,
              borderTopEndRadius: firstGroupedItemsKey === groupKey ? 4 : 0,
              borderBottomStartRadius: lastGroupedItemsKey === groupKey ? 4 : 0,
              borderBottomEndRadius: lastGroupedItemsKey === groupKey ? 4 : 0,
              borderBottomWidth: lastGroupedItemsKey === groupKey ? 1 : 0,
            }}
            roomItemForms={roomItemForms}
            navigator={navigator}
            urlFilters={urlFilters}
            groupItems={groupItems}
          />
        );
      })}
      <FilterRoomItemsDrawer
        key={filterItemDrawer.key}
        urlFilters={urlFilters}
        filterCount={filterCount}
        showRoomFilter
        isOpen={urlFilters.get('isOpen') === 'true'}
        handleClose={() => urlFilters.handleUpdate({isOpen: null})}
      />
    </>
  );
};

const ViewFullInventory = () => {
  const {form, refetch} = useContext(DriverInventoryContext);
  const {navigator, params} = useNavigation();
  const urlFilters = useUrlFilters({
    getRoute: () => 'view',
    filterKeys: [
      'query',
      'minItemNumber',
      'maxItemNumber',
      'roomIds',
      'lotNumbers',
      'itemTags',
      'exceptionTypes',
      'showVoidedLabels',
      'isOpen',
    ],
  });

  return (
    <React.Fragment>
      <Container>
        <TertiaryButton
          iconLeft={Icon.AngleLeft}
          iconSize={14}
          text={'Capture Inventory Home'}
          onPress={() => {
            clearUrlFilters({urlFilters});
            navigator.navigate('ShowDriverInventory');
            refetch();
          }}
        />
        <Space height={16} />
        <ScrollView>
          <InventorySection
            form={form}
            urlFilters={urlFilters}
            navigator={navigator}
            params={params}
          />
          <Space height={84} />
        </ScrollView>
      </Container>
    </React.Fragment>
  );
};

export default ViewFullInventory;
