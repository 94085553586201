// Libraries
import {
  createStackNavigator,
  createSwitchWrapperNavigator,
  createStackWrapperNavigator,
} from '@supermove/navigation';

// App
import BaseScreen from 'modules/App/BaseScreen';
import AuthenticatedWrapper from 'modules/App/components/AuthenticatedWrapper';
import RouterWrapper from 'modules/App/components/RouterWrapper';
import GenerateLoginCodePage from 'modules/Authentication/Login/GenerateLoginCodePage';
import MoverAppLoginPage from 'modules/Authentication/Login/MoverAppLoginPage';
import MoverAppSelectAccountPage from 'modules/Authentication/Login/MoverAppSelectAccountPage';
import Calendar from 'modules/Calendar/Calendar';
import MoverJobPage from 'modules/Calendar/Jobs/MoverJobPage';
import DriverInventoryMainScreen from 'modules/DriverInventory/DriverInventoryMainScreen';
import DriverInventoryItemFields from 'modules/DriverInventory/Items/DriverInventoryItemFields';
import DriverInventoryRoomFields from 'modules/DriverInventory/Rooms/DriverInventoryRoomFields';
import ShowDriverInventoryRoom from 'modules/DriverInventory/Rooms/ShowDriverInventoryRoom';
import ShowDriverInventory from 'modules/DriverInventory/ShowDriverInventory';
import ViewFullInventory from 'modules/DriverInventory/ViewFullInventory';

const Authentication = createStackNavigator(
  {
    GenerateLoginCodePage: {
      path: '',
      screen: GenerateLoginCodePage,
    },
    MoverAppLoginPage: {
      path: ':phoneNumber',
      screen: MoverAppLoginPage,
    },
  },
  {
    initialRouteName: 'GenerateLoginCodePage',
    headerMode: 'none',
    backBehavior: 'history',
  },
);

const DriverInventory = createStackWrapperNavigator(
  {
    ShowDriverInventory: {
      path: '',
      screen: ShowDriverInventory,
    },
    ViewFullInventory: {
      path: 'view',
      screen: ViewFullInventory,
    },
    ShowDriverInventoryRoom: {
      path: 'rooms/:roomUuid/show',
      screen: ShowDriverInventoryRoom,
    },
    CreateDriverInventoryRoom: {
      path: 'rooms/new',
      screen: DriverInventoryRoomFields,
    },
    EditDriverInventoryRoom: {
      path: 'rooms/:roomUuid/edit',
      screen: DriverInventoryRoomFields,
    },
    CreateDriverInventoryItem: {
      path: 'rooms/:roomUuid/items/new',
      screen: DriverInventoryItemFields,
    },
    EditDriverInventoryItem: {
      path: 'rooms/:roomUuid/items/:itemUuid/edit',
      screen: DriverInventoryItemFields,
    },
  },
  {
    initialRouteName: 'ShowDriverInventory',
    backBehavior: 'history',
    wrapper: DriverInventoryMainScreen,
  },
);

const App = createStackNavigator(
  {
    BaseScreen: {
      path: '',
      screen: BaseScreen,
    },
    Calendar: {
      path: 'calendar',
      screen: Calendar,
    },
    Job: {
      path: 'job/:jobUuid',
      screen: MoverJobPage,
    },
    SelectAccount: {
      path: 'select-account',
      screen: MoverAppSelectAccountPage,
    },
    DriverInventory: {
      path: 'inventories/:inventoryUuid',
      screen: DriverInventory,
    },
  },
  {
    initialRouteName: 'Calendar',
    headerMode: 'none',
    backBehavior: 'history',
  },
);

const Authenticated = createSwitchWrapperNavigator(
  {
    App: {
      path: '',
      screen: App,
    },
  },
  {
    initialRouteName: 'App',
    backBehavior: 'history',
    wrapper: AuthenticatedWrapper,
  },
);

// A SwitchWrapperNavigator is a SwitchNavigator with an extra component rendered
// which has access to the `navigation` prop.
const Router = createSwitchWrapperNavigator(
  {
    Authentication: {
      path: 'login',
      screen: Authentication,
    },
    Authenticated: {
      path: '',
      screen: Authenticated,
    },
  },
  {
    initialRouteName: 'Authenticated',
    backBehavior: 'history',
    wrapper: RouterWrapper,
  },
);

export default Router;
