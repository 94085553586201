// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import MoverAppLoginForm from '@shared/modules/User/forms/MoverAppLoginForm';

const useMoverAppLoginMutation = ({moverAppLoginForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      moverAppLoginForm: MoverAppLoginForm.toForm(moverAppLoginForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useMoverAppLoginMutation.mutation,
    variables: {
      moverAppLoginForm: MoverAppLoginForm.toMutation(form.values.moverAppLoginForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useMoverAppLoginMutation.mutation = gql`
  mutation useMoverAppLoginMutation($moverAppLoginForm: MoverAppLoginForm) {
    response: moverAppLogin(moverAppLoginForm: $moverAppLoginForm) {
      ${gql.errors}
      token
      user {
        id
      }
    }
  }
`;

export default useMoverAppLoginMutation;
