const _new = ({phoneNumber}) => ({
  phoneNumber,
  code: '',
});

const toForm = ({phoneNumber, code}) => ({
  phoneNumber,
  code,
});

const toMutation = ({phoneNumber, code}) => ({
  phoneNumber,
  code,
});

const MoverAppLoginForm = {
  new: _new,
  toForm,
  toMutation,
};

export default MoverAppLoginForm;
