// Libraries
import _ from 'lodash';

import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';
import {S3} from '@supermove/sdk';

import CompleteUploadFileForm from '@shared/modules/File/forms/CompleteUploadFileForm';
import RequestUploadFileForm from '@shared/modules/File/forms/RequestUploadFileForm';
import UploadFileForm from '@shared/modules/File/forms/UploadFileForm';

// Supermove

// App

const useUploadFileForm = ({uploadFileForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      uploadFileForm: UploadFileForm.toForm(uploadFileForm),
    },
  });

  // Complete Upload File (#3)
  const {
    submitting: submittingCompleteUploadFile,
    handleSubmit: handleSubmitCompleteUploadFile,
  } = useFormMutation({
    form,
    mutation: useUploadFileForm.completeUploadFileMutation,
    variables: {
      completeUploadFileForm: CompleteUploadFileForm.toMutation(
        form.values.uploadFileForm.completeUploadFileForm,
      ),
    },
    onSuccess,
    onError,
  });

  // Upload to S3 (#2)
  const uploadFile = async ({url, fields, file, setSubmitting}) => {
    try {
      console.log('Starting upload for:', fields);
      const response = await S3.uploadFile({
        url,
        fields: JSON.parse(fields),
        file,
        onProgress: (progress) => console.log({progress, submitting: form.isSubmitting}),
      });
      console.log('Upload success', {response});
      await handleSubmitCompleteUploadFile();
    } catch (error) {
      // Error thrown by S3.uploadFile is just a string, so format the error as a MutationError
      // because the onError callback expects MutationError objects. This co-opts the
      // uploadFileForm.file field because no other code sets errors on this field.
      const errorReason = error ? `(${error})` : '';
      const mutationError = {
        field: `uploadFileForm.file`,
        message: `File upload failed ${errorReason}`,
      };
      form.setFieldError(mutationError.field, mutationError.message);
      onError([error]);
    } finally {
      setSubmitting(false);
    }
  };
  // Request Upload File (#1)
  const {
    submitting: submittingRequestUploadFile,
    handleSubmit: handleSubmitRequestUploadFile,
  } = useFormMutation({
    form,
    mutation: useUploadFileForm.requestUploadFileMutation,
    variables: {
      requestUploadFileForm: RequestUploadFileForm.toMutation(
        form.values.uploadFileForm.requestUploadFileForm,
      ),
    },
    onSuccess: async ({file}) => {
      form.setFieldValue('uploadFileForm.completeUploadFileForm.uuid', file.uuid);
      await uploadFile({
        url: file.s3PresignedPostMetadata.url,
        fields: file.s3PresignedPostMetadata.fields,
        file: form.values.uploadFileForm.file,
        setSubmitting: form.setSubmitting,
      });
    },
    onError: (errors) => {
      _.forEachRight(errors, (error) =>
        form.setFieldError(_.camelCase(error.field), error.message),
      );
      onError(errors);
    },
  });

  return {
    form,
    submitting: submittingRequestUploadFile || submittingCompleteUploadFile || form.isSubmitting,
    handleSubmit: handleSubmitRequestUploadFile,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUploadFileForm.requestUploadFileMutation = gql`
  mutation useUploadFileForm($requestUploadFileForm: RequestUploadFileForm!) {
    response: requestUploadFile(requestUploadFileForm: $requestUploadFileForm) {
      ${gql.errors}
      file {
        id
        uuid
        s3PresignedPostMetadata {
          url
          fields
        }
      }
    }
  }
`;

useUploadFileForm.completeUploadFileMutation = gql`
  mutation useUploadFileForm($completeUploadFileForm: CompleteUploadFileForm!) {
    response: completeUploadFile(completeUploadFileForm: $completeUploadFileForm) {
      ${gql.errors}
      file {
        id
        downloadUrl
        filename
        mimetype
        playbackUrl
      }
    }
  }
`;

export default useUploadFileForm;
