// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import ProjectTypeCategory from '@shared/modules/Project/enums/ProjectTypeCategory';

const getCrewPaymentMethods = withFragment(
  (projectType) => {
    return projectType.crewPaymentMethods;
  },
  gql`
    fragment ProjectType_getCrewPaymentMethods on ProjectType {
      id
      crewPaymentMethods
    }
  `,
);

const getDisplayCategory = withFragment(
  ({category}) => {
    switch (category) {
      case ProjectTypeCategory.MOVE:
        return 'Moves';
      case ProjectTypeCategory.STORAGE:
        return 'Storage';
      default:
        return '';
    }
  },
  gql`
    fragment ProjectType_getDisplayCategory on ProjectType {
      id
      category
    }
  `,
);

const COLOR_OPTIONS = {
  Purple: '#9B51E0',
  Blue: '#34ACE0',
  Green: '#27AE60',
  Yellow: '#F1C40F',
  Orange: '#F2A12C',
  Red: '#E74C3C',
  Pink: '#E84393',
  Grey: '#616161',
  Black: '#000000',
};

const getColorOptions = () =>
  Object.keys(COLOR_OPTIONS).map((key, index) => ({label: key, value: COLOR_OPTIONS[key]}));

const ProjectType = {
  getCrewPaymentMethods,
  getColorOptions,
  getDisplayCategory,
};

export default ProjectType;
