// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation, useOnPressOutside, useQuery, useState} from '@supermove/hooks';
import {colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import MobileHeaderPage from 'modules/App/components/MobileHeaderPage';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import CalendarDayPicker from 'modules/Calendar/CalendarDayPicker';
import ListMoverJobs from 'modules/Calendar/ListMoverJobs';

const CalendarContentContainer = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
`;

const DayPickerContainer = Styled.View``;

const CalendarContent = ({viewer, selectedDate, setSelectedDate}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const refCallback = useOnPressOutside(() => setIsCollapsed(true));

  return (
    <React.Fragment>
      <DayPickerContainer style={{position: 'relative', zIndex: 101}} ref={refCallback}>
        <CalendarDayPicker
          organization={viewer.organization}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
        />
      </DayPickerContainer>
      <ListMoverJobs
        organization={viewer.organization}
        selectedDate={selectedDate}
        isCollapsed={isCollapsed}
      />
    </React.Fragment>
  );
};

const Calendar = () => {
  const {params} = useNavigation();
  const [selectedDate, setSelectedDate] = useState(
    params.date || Datetime.toMutationDate(Datetime.today),
  );

  const {loading, data} = useQuery(Calendar.query, {
    fetchPolicy: 'cache-and-network',
  });

  if (loading || !data.viewer) return <PageLoadingIndicator />;

  return (
    <MobileHeaderPage selected={'calendar'}>
      <CalendarContentContainer>
        <CalendarContent
          viewer={data.viewer}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      </CalendarContentContainer>
    </MobileHeaderPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
Calendar.query = gql`
  ${CalendarDayPicker.fragment}
  ${ListMoverJobs.fragment}
  query Calendar {
    ${gql.query}
    viewer {
      id
      firstName
      organization {
        id
        name
        ...CalendarDayPicker
        ...ListMoverJobs
      }
    }
  }
`;

export default Calendar;
