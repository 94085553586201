// Libararies
import React from 'react';

// Supermove
import {Icon, Drawer, Space, Styled} from '@supermove/components';
import UpdateJobUserMutation from '@supermove/manager/src/modules/Employee/Job/Details/components/UpdateJobUserMutation';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import JobUserStatus from '@shared/modules/Job/enums/JobUserStatus';

const DrawerContainer = Styled.View`
  flex: 1;
  padding-horizontal: 16px;
  background-color: ${colors.gray.background}
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
`;

const Row = Styled.View`
  align-items: center;
  flex-direction: row;
`;

const DrawerTitle = Styled.Text`
  ${Typography.Mobile.Heading1}
`;

const BodyText = Styled.Text`
  ${Typography.Mobile.Body}
`;

const AcceptDeclineJobDrawer = ({jobId, viewerId, status, isOpen, handleClose, refetch}) => {
  const message =
    status === JobUserStatus.CONFIRMED
      ? 'This job is currently accepted. You can decline this job at anytime.'
      : 'This job is currently declined. You can accept this job at any time.';
  return (
    <Drawer
      isOpen={isOpen}
      onClose={handleClose}
      position={'BOTTOM'}
      contentContainerStyle={{position: 'absolute', bottom: 0, height: '192px'}}
      shouldCloseOnClickOutside
    >
      <DrawerContainer>
        <Space height={16} />
        <Row>
          <DrawerTitle>Accept or Decline Job</DrawerTitle>
          <Space style={{flex: 1}} />
          <TertiaryButton onPress={handleClose} text={'Close'} />
        </Row>
        <Space height={16} />
        <BodyText>{message}</BodyText>
        <Space height={16} />
        <Row style={{justifyContent: 'space-between'}}>
          <UpdateJobUserMutation
            jobId={jobId}
            userId={viewerId}
            status={JobUserStatus.REMOVED}
            onSuccess={() => refetch()}
          >
            {({loading, handleSubmit}) => (
              <Button
                style={{width: '48%'}}
                iconLeft={Icon.Times}
                iconSize={9}
                color={colors.red.warning}
                isWidthOfContainer
                text={'Decline'}
                loading={loading}
                onPress={handleSubmit}
                isDisabled={status === JobUserStatus.REMOVED}
              />
            )}
          </UpdateJobUserMutation>
          <UpdateJobUserMutation
            jobId={jobId}
            userId={viewerId}
            status={JobUserStatus.CONFIRMED}
            onSuccess={() => refetch()}
          >
            {({loading, handleSubmit}) => (
              <Button
                style={{width: '48%'}}
                iconLeft={Icon.Check}
                iconSize={9}
                isWidthOfContainer
                text={'Accept'}
                loading={loading}
                onPress={handleSubmit}
                isDisabled={status === JobUserStatus.CONFIRMED}
              />
            )}
          </UpdateJobUserMutation>
        </Row>
      </DrawerContainer>
    </Drawer>
  );
};

export default AcceptDeclineJobDrawer;
