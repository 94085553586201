// Libraries
import React from 'react';

// Supermove
import {useResponsive} from '@supermove/hooks';

// App
import MultiDropdownInput from '@shared/design/components/MultiDropdownInput';

// DEPRECATED: Use MultiDropdownInput instead. It is responsive by default.
const ResponsiveMultiDropdownInput = (props) => {
  const responsive = useResponsive();
  return <MultiDropdownInput isMobile={responsive.mobile} {...props} />;
};

// --------------------------------------------------
// Props
// --------------------------------------------------
ResponsiveMultiDropdownInput.propTypes = MultiDropdownInput.propTypes;

ResponsiveMultiDropdownInput.defaultProps = MultiDropdownInput.defaultProps;

export default ResponsiveMultiDropdownInput;
