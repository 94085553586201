// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

// App
import DropdownSheetOptionsList from '@shared/design/components/DropdownInput/components/DropdownSheetOptionsList';

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.gray.border};
  width: 100%;
`;

const SectionLabel = Styled.Text`
  ${Typography.Mobile.Label}
`;

const DropdownSheetSectionedList = ({
  options,
  name,
  value,
  onChangeValue,
  setFieldValue,
  isMultiSelect,
  showDescriptionInOption,
  handleClose,
  query,
  NoResultsComponent,
  noResultsComponentProps,
}) => {
  return (
    <React.Fragment>
      {options.map((section, index) => {
        return (
          <React.Fragment key={section.label}>
            <Space height={16} />
            {index > 0 && (
              <React.Fragment>
                <Line />
                <Space height={16} />
              </React.Fragment>
            )}
            <SectionLabel>{section.label}</SectionLabel>
            <Space height={16} />
            <DropdownSheetOptionsList
              options={section.options}
              name={name}
              value={value}
              onChangeValue={onChangeValue}
              setFieldValue={setFieldValue}
              isMultiSelect={isMultiSelect}
              showDescriptionInOption={showDescriptionInOption}
              handleClose={handleClose}
              optionStyle={{paddingLeft: 16}}
              query={query}
              NoResultsComponent={NoResultsComponent}
              noResultsComponentProps={noResultsComponentProps}
            />
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
DropdownSheetSectionedList.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
          handleAction: PropTypes.func,
        }),
      ).isRequired,
    }),
  ),
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  ]),
  onChangeValue: PropTypes.func,
  setFieldValue: PropTypes.func,
  showDescriptionInOption: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  query: PropTypes.string,
  NoResultsComponent: PropTypes.func,
  noResultsComponentProps: PropTypes.object,
};

DropdownSheetSectionedList.defaultProps = {
  options: [],
  name: '',
  value: '',
  onChangeValue: () => {},
  setFieldValue: () => {},
  showDescriptionInOption: false,
  query: '',
  NoResultsComponent: null,
  noResultsComponentProps: {},
};

export default DropdownSheetSectionedList;
