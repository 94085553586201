// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';
import {Phone} from '@supermove/utils';

const JobContainer = Styled.View`
  width: 100%;
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 4px;
  background-color: ${colors.white};
`;

const Container = Styled.View`
  padding: 16px;
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.gray.border};
`;

const Text = Styled.Text`
  ${Typography.Mobile.Body}
`;

const Label = Styled.Text`
  ${Typography.Mobile.Label}
`;

const Subheading = Styled.Text`
  ${Typography.Mobile.Subheading}
`;

const ClientBlock = ({job}) => {
  const jobClient = job.project.client;
  return (
    <JobContainer>
      <Container>
        <Subheading>Client Information</Subheading>
      </Container>
      <Line />
      <Container>
        <Label>Company Name</Label>
        <Space height={4} />
        <Text>{jobClient.name}</Text>
        <Space height={8} />
        <Label>Contact Name</Label>
        <Space height={4} />
        <Text>{jobClient.primaryContact.fullName}</Text>
        <Space height={8} />
        <Label>Phone Number</Label>
        <Space height={4} />
        <Text>{Phone.display(jobClient.primaryContact.phoneNumber)}</Text>
        <Space height={8} />
        <Label>Email</Label>
        <Space height={4} />
        <Text>{jobClient.primaryContact.email}</Text>
      </Container>
    </JobContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ClientBlock.fragment = gql`
  fragment ClientBlock on Job {
    id
    project {
      id
      client {
        id
        name
        primaryContact {
          id
          email
          fullName
          phoneNumber
        }
      }
    }
  }
`;

export default ClientBlock;
