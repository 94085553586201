// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const getRemainingBalance = withFragment(
  (compensationReport) => {
    const {total, paidOutAmount} = compensationReport;
    return total - paidOutAmount;
  },
  gql`
    fragment CompensationReport_getRemainingBalance on CompensationReport {
      id
      total
      paidOutAmount
    }
  `,
);

const CompensationReport = {
  getRemainingBalance,
};

export default CompensationReport;
