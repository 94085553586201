// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import GenerateMoverAppLoginCodeForm from '@shared/modules/User/forms/GenerateMoverAppLoginCodeForm';

const useGenerateMoverAppLoginCodeMutation = ({
  generateMoverAppLoginCodeForm,
  onSuccess,
  onError,
}) => {
  const form = useForm({
    initialValues: {
      generateMoverAppLoginCodeForm: GenerateMoverAppLoginCodeForm.toForm(
        generateMoverAppLoginCodeForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useGenerateMoverAppLoginCodeMutation.mutation,
    variables: {
      generateMoverAppLoginCodeForm: GenerateMoverAppLoginCodeForm.toMutation(
        form.values.generateMoverAppLoginCodeForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useGenerateMoverAppLoginCodeMutation.mutation = gql`
  mutation useGenerateMoverAppLoginCodeMutation($generateMoverAppLoginCodeForm: GenerateMoverAppLoginCodeForm) {
    response: generateMoverAppLoginCode(generateMoverAppLoginCodeForm: $generateMoverAppLoginCodeForm) {
      ${gql.errors}
      ok
    }
  }
`;

export default useGenerateMoverAppLoginCodeMutation;
