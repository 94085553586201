// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, useNavigation, useQuery} from '@supermove/hooks';
import UpdateJobUserMutation from '@supermove/manager/src/modules/Employee/Job/Details/components/UpdateJobUserMutation';
import {colors, Typography} from '@supermove/styles';
import {Datetime, Json} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Callout from '@shared/design/components/Callout';
import CreateDriverInventoryForJobForm from '@shared/modules/Inventory/forms/CreateDriverInventoryForJobForm';
import useCreateDriverInventoryForJobMutation from '@shared/modules/Inventory/hooks/useCreateDriverInventoryForJobMutation';
import JobUserStatus from '@shared/modules/Job/enums/JobUserStatus';
import MobileHeaderPage from 'modules/App/components/MobileHeaderPage';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import AcceptDeclineJobDrawer from 'modules/Calendar/Jobs/components/AcceptDeclineJobDrawer';
import ClientBlock from 'modules/Calendar/Jobs/components/ClientBlock';
import DispatchBlock from 'modules/Calendar/Jobs/components/DispatchBlock';
import JobOverviewBlock from 'modules/Calendar/Jobs/components/JobOverviewBlock';
import StopsBlock from 'modules/Calendar/Jobs/components/StopsBlock';

const MoverJobContentContainer = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
`;

const ButtonContainer = Styled.View`
  border-width: 1px;
  border-color: ${colors.gray.border};
  padding-top: 16px;
  padding-horizontal: 16px;
  height: ${(props) => props.height};
  background-color: ${colors.white}
`;

const Container = Styled.View`
  padding-top: 16px;
  padding-horizontal: 16px;
  height: 100%;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const StatusBadgeButton = Styled.ButtonV2``;

const StatusBadge = Styled.View`
  padding-vertical: 4px;
  padding-horizontal: 12px;
  background-color: ${(props) => props.color};
  border-radius: 2px;
  height: 32px;
`;

const StatusBadgeText = Styled.Text`
  ${Typography.Mobile.Label}
  color: ${(props) => props.color};
`;

const BodyText = Styled.Text`
  ${Typography.Mobile.Body}
`;

const Header = Styled.Text`
  ${Typography.Mobile.Heading1}
`;

const Subheading = Styled.Text`
  ${Typography.Mobile.Subheading}
  color: ${colors.gray.secondary}
`;

const getStatusBadge = ({status, allowMoverAcceptDeclineJob, acceptDeclineJobDrawer}) => {
  switch (status) {
    case JobUserStatus.CONFIRMED:
      return (
        <StatusBadgeButton
          onPress={allowMoverAcceptDeclineJob ? acceptDeclineJobDrawer.handleOpen : () => {}}
        >
          <StatusBadge color={colors.green.accent}>
            <Row style={{alignItems: 'center'}}>
              <Icon source={Icon.CheckCircle} color={colors.green.status} size={16} />
              <Space width={8} />
              <StatusBadgeText color={colors.green.status}>Accepted</StatusBadgeText>
              <Space width={8} />
              <Icon source={Icon.AngleDown} color={colors.green.status} size={16} />
            </Row>
          </StatusBadge>
        </StatusBadgeButton>
      );
    case JobUserStatus.REMOVED:
      return (
        <StatusBadgeButton
          onPress={allowMoverAcceptDeclineJob ? acceptDeclineJobDrawer.handleOpen : () => {}}
        >
          <StatusBadge color={colors.red.accent}>
            <Row style={{alignItems: 'center'}}>
              <Icon source={Icon.TimesCircle} color={colors.red.warning} size={16} />
              <Space width={8} />
              <StatusBadgeText color={colors.red.warning}>Declined</StatusBadgeText>
              <Space width={8} />
              <Icon source={Icon.AngleDown} color={colors.red.warning} size={16} />
            </Row>
          </StatusBadge>
        </StatusBadgeButton>
      );
    default:
      return (
        <StatusBadge color={colors.orange.accent}>
          <Row style={{alignItems: 'center'}}>
            <Icon source={Icon.ExclamationTriangle} color={colors.orange.status} size={11} />
            <Space width={8} />
            <StatusBadgeText color={colors.orange.status}>Action Required</StatusBadgeText>
          </Row>
        </StatusBadge>
      );
  }
};

const JobHeader = ({viewer, job, status, refetch}) => {
  const acceptDeclineJobDrawer = useDrawer({
    name: 'Accept Decline Job Drawer',
    enableTracking: true,
  });
  const {allowMoverAcceptDeclineJob} = viewer.organization.settings;
  return (
    <React.Fragment>
      <Row>
        {allowMoverAcceptDeclineJob && (
          <React.Fragment>
            {getStatusBadge({status, allowMoverAcceptDeclineJob, acceptDeclineJobDrawer})}
            <Space width={8} />
          </React.Fragment>
        )}
        {job.project.isTest && (
          <React.Fragment>
            <StatusBadge color={colors.purple.accent}>
              <StatusBadgeText color={colors.purple.status}>Training Job</StatusBadgeText>
            </StatusBadge>
            <Space height={16} />
          </React.Fragment>
        )}
      </Row>
      <Space height={16} />
      <Header>{job.fullName}</Header>
      <Subheading>{job.name}</Subheading>
      <AcceptDeclineJobDrawer
        jobId={job.id}
        viewerId={viewer.id}
        status={status}
        isOpen={acceptDeclineJobDrawer.isOpen}
        handleClose={acceptDeclineJobDrawer.handleClose}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const ActionRequiredComponent = ({job, viewer, refetch}) => {
  return (
    <ButtonContainer height={168}>
      <Row style={{alignItems: 'center'}}>
        <Icon source={Icon.ExclamationTriangle} color={colors.orange.status} size={18} />
        <Space width={8} />
        <Header>Action Required</Header>
      </Row>
      <Space height={16} />
      <BodyText>Please accept or decline this job.</BodyText>
      <Space height={16} />
      <Row style={{justifyContent: 'space-between'}}>
        <UpdateJobUserMutation
          jobId={job.id}
          userId={viewer.id}
          status={JobUserStatus.REMOVED}
          onSuccess={() => refetch()}
        >
          {({loading, handleSubmit}) => (
            <Button
              style={{width: '48%'}}
              iconLeft={Icon.Times}
              iconSize={9}
              color={colors.red.warning}
              isWidthOfContainer
              text={'Decline'}
              loading={loading}
              onPress={handleSubmit}
            />
          )}
        </UpdateJobUserMutation>
        <UpdateJobUserMutation
          jobId={job.id}
          userId={viewer.id}
          status={JobUserStatus.CONFIRMED}
          onSuccess={() => refetch()}
        >
          {({loading, handleSubmit}) => (
            <Button
              style={{width: '48%'}}
              iconLeft={Icon.Check}
              iconSize={9}
              isWidthOfContainer
              text={'Accept'}
              loading={loading}
              onPress={handleSubmit}
            />
          )}
        </UpdateJobUserMutation>
      </Row>
    </ButtonContainer>
  );
};

const StartJobButton = ({navigator, job, isDisabled}) => {
  const {handleSubmit} = useCreateDriverInventoryForJobMutation({
    createDriverInventoryForJobForm: CreateDriverInventoryForJobForm.new(job),
    onSuccess: ({driverInventory}) => {
      navigator.push('DriverInventory', {
        inventoryUuid: driverInventory.uuid,
        jobUuid: job.uuid,
      });
    },
    onError: (errors) => console.log(errors),
  });

  return (
    <ButtonContainer height={68}>
      <Button
        isWidthOfContainer
        text={'Start Job'}
        isDisabled={isDisabled}
        onPress={() => {
          if (!job.driverInventory) {
            handleSubmit();
          } else {
            navigator.push('DriverInventory', {
              inventoryUuid: job.driverInventory.uuid,
              jobUuid: job.uuid,
            });
          }
        }}
      />
    </ButtonContainer>
  );
};

const MoverJobContent = ({viewer, job, refetch}) => {
  const {navigator} = useNavigation();
  const {status} = _.find(job.jobUsers, (user) => String(user.userId) === String(viewer.id));
  const isDisabledStartJob = job.startDate > Datetime.toMutationDate(Datetime.today);
  const {allowMoverAcceptDeclineJob} = viewer.organization.settings;

  return (
    <React.Fragment>
      <ScrollView>
        <Container>
          <TertiaryButton
            iconLeft={Icon.AngleLeft}
            iconSize={14}
            text={'Back to Calendar'}
            onPress={() =>
              navigator.push('Calendar', {
                block: job.startDate,
                date: job.startDate,
              })
            }
          />
          <Space height={16} />
          <JobHeader viewer={viewer} job={job} status={status} refetch={refetch} />
          <Space height={16} />
          {status === JobUserStatus.CONFIRMED && isDisabledStartJob && (
            <React.Fragment>
              <Callout text={`You'll be able to start the job on the scheduled start date.`} />
              <Space height={16} />
            </React.Fragment>
          )}
          <JobOverviewBlock job={job} />
          {Json.parse(viewer.features).VIEW_CUSTOMER_INFO && (
            <React.Fragment>
              <Space height={16} />
              <DispatchBlock organization={viewer.organization} job={job} />
            </React.Fragment>
          )}
          <Space height={16} />
          <StopsBlock job={job} />
          {Json.parse(viewer.features).VIEW_CUSTOMER_INFO && (
            <React.Fragment>
              <Space height={16} />
              <ClientBlock job={job} />
            </React.Fragment>
          )}
          <Space height={84} />
        </Container>
      </ScrollView>
      {allowMoverAcceptDeclineJob &&
        status !== JobUserStatus.CONFIRMED &&
        status !== JobUserStatus.REMOVED && (
          <ActionRequiredComponent job={job} viewer={viewer} refetch={refetch} />
        )}
      {(!allowMoverAcceptDeclineJob || status === JobUserStatus.CONFIRMED) && (
        <StartJobButton navigator={navigator} job={job} isDisabled={isDisabledStartJob} />
      )}
    </React.Fragment>
  );
};

const MoverJobPage = () => {
  const {params} = useNavigation();

  const {loading, data, refetch} = useQuery(MoverJobPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      jobUuid: params.jobUuid,
    },
  });

  if (loading || !data.job) return <PageLoadingIndicator />;

  return (
    <MobileHeaderPage selected={'calendar'}>
      <MoverJobContentContainer>
        <MoverJobContent viewer={data.viewer} job={data.job} refetch={refetch} />
      </MoverJobContentContainer>
    </MobileHeaderPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
MoverJobPage.query = gql`
  ${JobOverviewBlock.fragment}
  ${DispatchBlock.fragment}
  ${DispatchBlock.organizationFragment}
  ${StopsBlock.fragment}
  ${ClientBlock.fragment}
  query MoverJobPage($jobUuid: String!) {
    ${gql.query}
    viewer {
      id
      features
      organization {
        id
        settings {
          id
          allowMoverAcceptDeclineJob
        }
        ...DispatchBlock_organizationFragment
      }
    }
    job(uuid: $jobUuid) {
      id
      uuid
      name
      fullName
      startDate
      endDate
      jobUsers {
        id
        userId
        status
      }
      driverInventory {
        id
        uuid
      }
      project {
        id
        isTest
      }
      ...JobOverviewBlock
      ...DispatchBlock
      ...StopsBlock
      ...ClientBlock
    }
  }
`;

export default MoverJobPage;
