// Libararies
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon, Styled, Space} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

const Container = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  ${({width, isWidthOfContainer}) =>
    isWidthOfContainer ? `width: 100%` : width ? `width: ${width}px` : ''};
  height: ${({size}) => (size === SIZE.LARGE ? '32px' : '26px')};
  padding-horizontal: ${({size}) => (size === SIZE.LARGE ? '4px' : '0px')};
  border-radius: 4px;
  background-color: ${({color}) => color};
`;

const IconContainer = Styled.ButtonV2`
  padding-vertical: 4px;
  padding-horizontal: 8px;
`;

const BadgeText = Styled.Text`
  ${({size}) => (size === 'LARGE' ? Typography.Subheading : Typography.MicroLabel)}
  color: ${({color}) => color};
`;

const getIconSize = ({size}) => {
  if (size === SIZE.LARGE) {
    return 12;
  } else {
    return 8;
  }
};

const SIZE = {
  SMALL: 'SMALL',
  LARGE: 'LARGE',
};

const getBackgroundColor = ({color, onIconRightPress}) => {
  if (color) {
    return colors.getBackgroundColor(color);
  }
  if (onIconRightPress) {
    return colors.getBackgroundColor(colors.blue.interactive);
  }
  return colors.gray.border;
};

const getTextColor = ({color, onIconRightPress}) => {
  if (color) {
    return color;
  }
  if (onIconRightPress) {
    return colors.blue.interactive;
  }
  return colors.gray.secondary;
};

const BadgeIconWithOnPress = ({icon, size, color, style, onPress}) => {
  return (
    <IconContainer onPress={onPress}>
      <Icon source={icon} size={size} color={color} style={style} />
    </IconContainer>
  );
};

const BadgeIcon = ({icon, size, color, style}) => {
  return (
    <IconContainer disabled>
      <Icon source={icon} size={size} color={color} style={style} />
    </IconContainer>
  );
};

const IconRight = ({iconRight, color, size, onIconRightPress}) => {
  if (onIconRightPress) {
    return (
      <BadgeIconWithOnPress icon={iconRight} color={color} size={size} onPress={onIconRightPress} />
    );
  }
  return <BadgeIcon icon={iconRight} color={color} size={size} />;
};

const Badge = ({
  label,
  color,
  width,
  size,
  backgroundColor,
  textColor,
  iconLeft,
  iconRight,
  iconSize,
  isWidthOfContainer,
  onIconRightPress,
  style,
}) => {
  const displayBackgroundColor = backgroundColor || getBackgroundColor({color, onIconRightPress});
  const displayTextColor = textColor || getTextColor({color, onIconRightPress});
  return (
    <Container
      style={style}
      width={width}
      size={size}
      color={displayBackgroundColor}
      isWidthOfContainer={isWidthOfContainer}
    >
      {iconLeft ? (
        <BadgeIcon
          icon={iconLeft}
          color={displayTextColor}
          size={iconSize || getIconSize({size})}
        />
      ) : (
        <Space width={8} />
      )}
      <BadgeText color={displayTextColor} size={size}>
        {label}
      </BadgeText>
      {iconRight ? (
        <IconRight
          iconRight={iconRight}
          color={displayTextColor}
          size={iconSize || getIconSize({size})}
          onIconRightPress={onIconRightPress}
        />
      ) : (
        <Space width={8} />
      )}
    </Container>
  );
};

Badge.SIZE = SIZE;

// --------------------------------------------------
// Props
// --------------------------------------------------
Badge.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  width: PropTypes.number,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  iconLeft: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  iconRight: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  iconSize: PropTypes.number,
  isWidthOfContainer: PropTypes.bool,
  onIconRightPress: PropTypes.func,
  style: PropTypes.object,
};

Badge.defaultProps = {
  label: null,
  color: null,
  size: null,
  width: null,
  backgroundColor: null,
  textColor: null,
  iconLeft: null,
  iconRight: null,
  iconSize: null,
  isWidthOfContainer: false,
  onIconRightPress: null,
  style: null,
};

export default Badge;
