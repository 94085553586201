// Libraries
import React from 'react';

// Supermove
import {ZendeskWebWidget} from '@supermove/analytics';
import {MaintenancePage, SupermoveApp} from '@supermove/app';
import {UpdateChecker, ToastContainer} from '@supermove/components';
import {Environment} from '@supermove/sdk';

// App
import {AppInfo} from 'config';
import ErrorModal from 'modules/App/components/ErrorModal';
import UpdateAppBanner from 'modules/App/components/UpdateAppBanner';

const App = ({router: Router}) => {
  const showMaintenancePage = Environment.get('SHOW_MAINTENANCE_PAGE');
  const zendeskWebWidgetKey = Environment.get('ZENDESK_WEB_WIDGET_KEY');
  const isZendeskChatVisible = false;
  if (showMaintenancePage) {
    return <MaintenancePage />;
  }

  return (
    <ErrorModal
      trigger={({handleOpen}) => (
        <React.Fragment>
          <SupermoveApp
            name={'Mover'}
            version={AppInfo.getVersion()}
            buildNumber={AppInfo.getBuildNumber()}
            onError={() => {
              handleOpen();
              if (window.zE) {
                // Opens the Zendesk chat widget:
                // https://developer.zendesk.com/api-reference/widget-messaging/web/core/#open
                window.zE('messenger', 'open');
              }
            }}
            showOfflineOverlay={false}
          >
            {({viewer}) => (
              <React.Fragment>
                <UpdateChecker
                  name={'Mover'}
                  buildNumber={AppInfo.getBuildNumber()}
                  pollInterval={1000 * 30}
                >
                  {({isVisible, isRequired}) =>
                    isVisible && !Environment.isLocal ? <UpdateAppBanner /> : null
                  }
                </UpdateChecker>
                <Router />
                <ToastContainer position={ToastContainer.Position.TOP_CENTER} />
              </React.Fragment>
            )}
          </SupermoveApp>
          <ZendeskWebWidget
            zendeskWebWidgetKey={zendeskWebWidgetKey}
            options={{
              webWidget: {
                offset: {
                  horizontal: '75px',
                  vertical: '0px',
                },
              },
            }}
            isHidden={!isZendeskChatVisible}
          />
        </React.Fragment>
      )}
    />
  );
};

export default App;
