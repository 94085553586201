// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon, Space, Styled, Loading, SupermoveLogo} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, useQuery} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import MobileHeader from 'modules/App/components/MobileHeader';
import MobileNavigationDrawer from 'modules/App/components/MobileNavigationDrawer';

import PageLoadingIndicator from './PageLoadingIndicator';

const Container = Styled.View`
  flex: 1;
  flex-direction: column;
`;

const ContentContainer = Styled.View`
  flex: 1;
`;

const OrganizationNameText = Styled.Text`
  ${Typography.Mobile.Label}
  color: ${colors.white};
`;

const MobileHeaderContent = ({viewer, selected}) => {
  const mobileNavigationDrawer = useDrawer({name: 'Mobile Navigation Drawer'});

  return (
    <React.Fragment>
      <MobileHeader>
        <Space width={12} />
        <TertiaryButton
          onPress={mobileNavigationDrawer.handleOpen}
          style={{
            paddingHorizontal: 8,
            paddingVertical: 8,
          }}
        >
          <Icon source={Icon.Bars} size={20} color={colors.white} />
        </TertiaryButton>
        <Space width={4} />
        <SupermoveLogo.Icon size={48} />
        <Space width={4} />
        <OrganizationNameText>{viewer.organization.name}</OrganizationNameText>
        <Space style={{flex: 1}} />
        <Space width={16} />
      </MobileHeader>
      <MobileNavigationDrawer
        isOpen={mobileNavigationDrawer.isOpen}
        handleClose={mobileNavigationDrawer.handleClose}
        selected={selected}
      />
    </React.Fragment>
  );
};

const MobileHeaderPage = ({children, alwaysUpdate, selected}) => {
  const {loading, data} = useQuery(MobileHeaderPage.query, {fetchPolicy: 'cache-and-network'});

  return (
    <Container>
      <Loading alwaysUpdate={alwaysUpdate} loading={loading || !data} as={PageLoadingIndicator}>
        {() => {
          return (
            <React.Fragment>
              <MobileHeaderContent viewer={data.viewer} selected={selected} />
              <ContentContainer>{children}</ContentContainer>
            </React.Fragment>
          );
        }}
      </Loading>
    </Container>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
MobileHeaderPage.propTypes = {
  alwaysUpdate: PropTypes.bool,
};

MobileHeaderPage.defaultProps = {
  alwaysUpdate: false,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
MobileHeaderPage.query = gql`
  query MobileHeaderPage {
    ${gql.query}
    viewer {
      id
      organization {
        id
        name
      }
    }
  }
`;

export default MobileHeaderPage;
