// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import Callout from '@shared/design/components/Callout';

const SuccessCallout = ({text, width}) => {
  return (
    <Callout
      text={text}
      width={width}
      textColor={colors.green.status}
      backgroundColor={colors.green.accent}
      borderColor={colors.green.status}
      icon={Icon.CheckCircle}
      iconColor={colors.green.status}
    />
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
SuccessCallout.propTypes = {
  text: PropTypes.string,
  width: PropTypes.number,
};

SuccessCallout.defaultProps = {
  text: '',
  width: null,
};

export default SuccessCallout;
