// Libraries
import React from 'react';

// Supermove
import {Space, Styled, SupermoveLogo} from '@supermove/components';
import {useNavigation} from '@supermove/hooks';
import {Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import FieldInput from '@shared/design/components/Field/FieldInput';
import GenerateMoverAppLoginCodeForm from '@shared/modules/User/forms/GenerateMoverAppLoginCodeForm';
import useGenerateMoverAppLoginCodeMutation from '@shared/modules/User/hooks/useGenerateMoverAppLoginCodeMutation';

const Content = Styled.View`
  flex: 1;
  width: 100%;
  max-width: 600px;
  padding-horizontal: 24px;
  flex-direction: column;
  justify-content: center;
`;

const TitleText = Styled.Text`
  ${Typography.PageHeading}
`;

const BodyText = Styled.Text`
  ${Typography.Body}
`;

const GenerateLoginCodePage = () => {
  const {navigator} = useNavigation();
  const {form, handleSubmit, submitting} = useGenerateMoverAppLoginCodeMutation({
    generateMoverAppLoginCodeForm: GenerateMoverAppLoginCodeForm.new(),
    onSuccess: () => {
      navigator.navigate('MoverAppLoginPage', {
        phoneNumber: form.values.generateMoverAppLoginCodeForm.phoneNumber,
      });
    },
    onError: (errors) => {
      console.log(errors);
    },
  });

  return (
    <Content>
      <SupermoveLogo.Name color={SupermoveLogo.COLORS.BLACK} size={36} />
      <Space height={24} />
      <TitleText>Welcome back!</TitleText>
      <Space height={16} />
      <BodyText>Enter your phone number and we'll send you a code to log in.</BodyText>
      <Space height={24} />
      <FieldInput
        {...form}
        name={'generateMoverAppLoginCodeForm.phoneNumber'}
        label={'Phone Number'}
        size={FieldInput.SIZE.MEDIUM}
        input={{
          placeholder: 'Enter your phone number',
          keyboardType: 'numeric',
          setFieldValue: form.setFieldValue,
        }}
      />
      <Space height={56} />
      <Button
        isWidthOfContainer
        text={'Get Code'}
        onPress={handleSubmit}
        isSubmitting={submitting}
      />
      <Space height={80} />
    </Content>
  );
};

export default GenerateLoginCodePage;
