// Supermove
import {Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';
import {Phone, withFragment} from '@supermove/utils';

// App
import CallDirection from '@shared/modules/Call/enums/CallDirection';
import CallStatus from '@shared/modules/Call/enums/CallStatus';

const getDisplayPhoneNumber = withFragment(
  (call) => {
    const {calleePhoneNumber, callerPhoneNumber, direction} = call;
    if (direction === CallDirection.INBOUND) {
      return Phone.display(callerPhoneNumber);
    } else {
      return Phone.display(calleePhoneNumber);
    }
  },
  gql`
    fragment Call_getDisplayPhoneNumber on Call {
      id
      direction
      calleePhoneNumber
      callerPhoneNumber
    }
  `,
);

const getCallBadge = withFragment(
  (call) => {
    switch (true) {
      case call.direction === CallDirection.OUTBOUND:
        return {
          icon: Icon.PhoneOutgoing,
          color: colors.blue.interactive,
          text: 'Outgoing',
        };
      case call.status === CallStatus.MISSED:
        return {
          icon: Icon.PhoneMissed,
          color: colors.red.warning,
          text: 'Missed',
        };
      default:
        return {
          icon: Icon.PhoneIncoming,
          color: colors.green.status,
          text: 'Incoming',
        };
    }
  },
  gql`
    fragment Call_getCallBadge on Call {
      id
      direction
      status
    }
  `,
);

const getDisplayLocation = withFragment(
  (call) => {
    const {calleeCity, calleeState, callerCity, callerState, direction} = call;
    if (direction === CallDirection.INBOUND) {
      if (!callerCity || !callerState) {
        return '';
      }
      return `${callerCity}, ${callerState}`;
    } else {
      if (!calleeCity || !calleeState) {
        return '';
      }
      return `${calleeCity}, ${calleeState}`;
    }
  },
  gql`
    fragment Call_getDisplayLocation on Call {
      id
      direction
      calleeCity
      calleeState
      callerCity
      callerState
    }
  `,
);

const Call = {
  getDisplayPhoneNumber,
  getCallBadge,
  getDisplayLocation,
};

export default Call;
