// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {Animation, colors} from '@supermove/styles';

const Loader = Styled.View`
  height: ${({height}) => height}px;
  width: ${({isFullWidth, width}) => (isFullWidth ? '100%' : `${width}px`)};
  border-radius: 4px;
  background-color: ${({color}) => color};
  ${Animation.pulse}
`;

const SkeletonLoader = ({height, isFullWidth, width, color, style, children}) => (
  <Loader isFullWidth={isFullWidth} height={height} width={width} color={color} style={style}>
    {children}
  </Loader>
);

SkeletonLoader.HEIGHT = {
  // Typography - matches line height
  Text: 20,
  Heading1Text: 32,
  Heading2Text: 28,
  SubheadingText: 24,
  MicroText: 18,

  Button: 36,
  ButtonSmall: 28,
};

// --------------------------------------------------
// Props
// --------------------------------------------------
SkeletonLoader.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  color: PropTypes.string,
  style: PropTypes.object,
  isFullWidth: PropTypes.bool,
};

SkeletonLoader.defaultProps = {
  height: null,
  width: null,
  color: colors.gray.disabled,
  style: null,
  isFullWidth: false,
};

export default SkeletonLoader;
