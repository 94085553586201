// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useNavigation} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const ItemText = Styled.Text`
  ${({vars}) => (vars.isSelected ? Typography.Label : Typography.Body)}
  color: ${({color}) => color};
`;

const Button = Styled.ButtonV2`
  width: 100%;
  padding: 12px;
  align-items: center;
  justify-content: flex-start;
  border-width: 1px;
  border-color: ${({isSelected}) => (isSelected ? colors.gray.border : colors.transparent)};
  border-radius: 4px;
  background-color: ${({isSelected}) => (isSelected ? colors.gray.background : colors.transparent)};
  flex-direction: row;
`;

const getColor = ({isSelected}) => (isSelected ? colors.blue.interactive : colors.gray.tertiary);

const SidebarItemButton = ({isSelected, to, onPress, handleClose, children}) => {
  const {navigator} = useNavigation();

  return (
    <Button
      onPress={() => {
        handleClose && handleClose();
        to && navigator.navigate(to);
        onPress && onPress();
      }}
      isSelected={isSelected}
    >
      {children}
    </Button>
  );
};

const MobileNavigationSidebarItem = ({name, isSelected, source, to, onPress, handleClose}) => {
  return (
    <SidebarItemButton to={to} onPress={onPress} handleClose={handleClose} isSelected={isSelected}>
      <Icon
        source={source}
        color={getColor({isSelected})}
        size={20}
        style={{height: 20, width: 20}}
      />
      <Row style={{flex: 1}}>
        <Space width={8} />
        <ItemText vars={{isSelected}} color={getColor({isSelected})}>
          {name}
        </ItemText>
        <Space style={{flex: 1}} />
      </Row>
    </SidebarItemButton>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
MobileNavigationSidebarItem.propTypes = {
  name: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  source: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  to: PropTypes.string,
};

MobileNavigationSidebarItem.defaultProps = {
  to: '',
};

export default MobileNavigationSidebarItem;
