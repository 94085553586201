// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Drawer, Icon, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useContext, useNavigation, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import ResponsiveMultiDropdownInput from '@shared/design/components/MultiDropdownInput/ResponsiveMultiDropdownInput';
import Switch from '@shared/design/components/Switch/index';
import ItemTagKind from '@shared/modules/Inventory/enums/ItemTagKind';
import DriverInventoryContext from 'modules/DriverInventory/context/DriverInventoryContext';

const View = Styled.View`
`;

const DrawerContainer = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background}
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const Row = Styled.View`
  align-items: center;
  flex-direction: row;
`;

const FiltersRow = Styled.View`
  flex-direction: row;
  align-items: flex-end;
`;

const BodyText = Styled.Text`
  ${Typography.Body}
  padding-bottom: 12px;
`;

const DrawerTitle = Styled.Text`
  ${Typography.PageHeading}
`;

const FilterContainer = Styled.View`
  z-index: ${({index}) => 100 - index};
`;

const BottomButtonContainer = Styled.View`
  background-color: ${colors.gray.background}
  padding: 16px;
  border-top-width: 1px;
  border-color: ${colors.gray.border}
`;

const MobileBodyText = Styled.Text`
  ${Typography.Mobile.Body}
`;

const ColorDot = Styled.View`
  height: 8px;
  width: 8px;
  border-radius: 4px;
  border-width: 1px;
  border-color: ${({borderColor, color}) => borderColor || color};
  background-color: ${({color}) => color};
`;

const ItemNumberFilter = ({index, filterValues, setFilterValues, paramKey, placeholder}) => {
  return (
    <FilterContainer style={{flex: 1}} index={index}>
      <FieldInput
        index={index}
        size={FieldInput.SIZE.MEDIUM}
        input={{
          value: filterValues[paramKey],
          placeholder,
          onChangeText: (itemNumber) => {
            setFilterValues({...filterValues, [paramKey]: itemNumber});
          },
        }}
      />
    </FilterContainer>
  );
};

const MinMaxItemNumberFilters = ({index, filterValues, setFilterValues}) => {
  return (
    <>
      <FiltersRow>
        <FieldInput.LabelText size={FieldInput.SIZE.MEDIUM}>Item Number Range</FieldInput.LabelText>
      </FiltersRow>
      <Space height={4} />
      <FiltersRow>
        <ItemNumberFilter
          index={index}
          paramKey={'minItemNumber'}
          placeholder={'Minimum'}
          filterValues={filterValues}
          setFilterValues={setFilterValues}
        />
        <Space width={8} />
        <BodyText>-</BodyText>
        <Space width={8} />
        <ItemNumberFilter
          index={index + 1}
          paramKey={'maxItemNumber'}
          placeholder={'Maximum'}
          filterValues={filterValues}
          setFilterValues={setFilterValues}
        />
      </FiltersRow>
    </>
  );
};

const RoomFilter = ({index, filterValues, setFilterValues, form}) => {
  const rooms = _.map(_.get(form.values, 'inventoryRoomsForm.roomItemsForms'), (roomItemsForm) => ({
    label: (
      <Row>
        <Space width={8} />
        <MobileBodyText>{roomItemsForm.name}</MobileBodyText>
      </Row>
    ),
    value: roomItemsForm.roomId,
  }));
  return (
    <FilterContainer index={index}>
      <FieldInput.LabelText size={FieldInput.SIZE.MEDIUM}>Rooms</FieldInput.LabelText>
      <Space height={4} />
      <ResponsiveMultiDropdownInput
        index={index}
        size={FieldInput.SIZE.MEDIUM}
        value={filterValues.roomIds}
        options={rooms}
        placeholder={'Select rooms'}
        numberOfVisibleSelections={2}
        style={{flex: 1}}
        isSearchable={false}
        isPortaled
        closeMenuOnSelect={false}
        setFieldValue={() => {}}
        onChangeValue={(roomIds) => {
          setFilterValues({...filterValues, roomIds});
        }}
      />
    </FilterContainer>
  );
};

const ColorLotBadge = ({item}) => {
  const {color, lotNumber} = item;
  return (
    <Row>
      <Space width={8} />
      {color ? (
        <>
          <ColorDot
            color={color}
            borderColor={_.toLower(color) === _.toLower(colors.white) && colors.gray.secondary}
          />
          <Space width={4} />
        </>
      ) : (
        <Space width={12} />
      )}
      <MobileBodyText style={{paddingTop: 2}}>{lotNumber}</MobileBodyText>
    </Row>
  );
};

const LotFilter = ({index, filterValues, setFilterValues, form}) => {
  const {params} = useNavigation();
  const room = _.find(form.values.inventoryRoomsForm.roomItemsForms, {uuid: params.roomUuid});
  const itemForms = params.roomUuid
    ? room.itemForms
    : _.get(form.values, 'inventoryRoomsForm.roomItemsForms').flatMap((room) => room.itemForms);
  const lotNumbers = params.roomUuid
    ? _.chain(itemForms)
        .filter((item) => item.lotNumber !== null && item.lotNumber !== '')
        .orderBy('lotNumber', 'color')
        .map((item) => ({
          label: <ColorLotBadge item={item} />,
          value: `${item.lotNumber}-${item.color}`,
        }))
        .uniqBy('value')
        .value()
    : _.chain(itemForms)
        .filter((item) => item.lotNumber !== null && item.lotNumber !== '')
        .orderBy('lotNumber', 'color')
        .map((item) => ({
          label: <ColorLotBadge item={item} />,
          value: `${item.lotNumber}-${item.color}`,
        }))
        .uniqBy('value')
        .value();
  return (
    <FilterContainer index={index}>
      <FieldInput.LabelText size={FieldInput.SIZE.MEDIUM}>Lots</FieldInput.LabelText>
      <Space height={4} />
      <ResponsiveMultiDropdownInput
        index={index}
        size={FieldInput.SIZE.MEDIUM}
        value={filterValues.lotNumbers}
        options={lotNumbers}
        placeholder={'Select lots'}
        numberOfVisibleSelections={2}
        style={{flex: 1}}
        isSearchable={false}
        isPortaled
        closeMenuOnSelect={false}
        setFieldValue={() => {}}
        onChangeValue={(lotNumbers) => {
          setFilterValues({...filterValues, lotNumbers});
        }}
      />
    </FilterContainer>
  );
};

const ItemTagFilter = ({index, filterValues, setFilterValues, itemTags}) => {
  const itemTagOptions = _.map(itemTags, (itemTag) => ({
    label: (
      <Row>
        <Space width={8} />
        <MobileBodyText>{itemTag.name}</MobileBodyText>
      </Row>
    ),
    value: itemTag.id,
  }));
  return (
    <FilterContainer index={index}>
      <FieldInput.LabelText size={FieldInput.SIZE.MEDIUM}>Item Tags</FieldInput.LabelText>
      <Space height={4} />
      <ResponsiveMultiDropdownInput
        index={index}
        size={FieldInput.SIZE.MEDIUM}
        value={filterValues.itemTags}
        options={itemTagOptions}
        placeholder={'Select item tags'}
        numberOfVisibleSelections={2}
        style={{flex: 1}}
        isSearchable={false}
        isPortaled
        closeMenuOnSelect={false}
        setFieldValue={() => {}}
        onChangeValue={(itemTags) => {
          setFilterValues({...filterValues, itemTags});
        }}
      />
    </FilterContainer>
  );
};

const ExceptionTypeFilter = ({index, filterValues, setFilterValues, exceptionTypes}) => {
  const exceptionTypeOptions = _.map(exceptionTypes, (exceptionType) => ({
    label: (
      <Row>
        <Space width={8} />
        <MobileBodyText>{exceptionType.name}</MobileBodyText>
      </Row>
    ),
    value: exceptionType.id,
  }));
  return (
    <FilterContainer index={index}>
      <FieldInput.LabelText size={FieldInput.SIZE.MEDIUM}>Exception Types</FieldInput.LabelText>
      <Space height={4} />
      <ResponsiveMultiDropdownInput
        index={index}
        size={FieldInput.SIZE.MEDIUM}
        value={filterValues.exceptionTypes}
        options={exceptionTypeOptions}
        placeholder={'Select exception types'}
        numberOfVisibleSelections={2}
        style={{flex: 1}}
        isSearchable={false}
        isPortaled
        closeMenuOnSelect={false}
        setFieldValue={() => {}}
        onChangeValue={(exceptionTypes) => {
          setFilterValues({...filterValues, exceptionTypes});
        }}
      />
    </FilterContainer>
  );
};

const VoidLabelFilter = ({index, filterValues, setFilterValues}) => {
  return (
    <FieldInput
      index={index}
      component={Switch}
      size={FieldInput.SIZE.MEDIUM}
      input={{
        labelRight: 'Show voided tags',
        isMobile: true,
        onChange: (showVoidedLabels) => {
          setFilterValues({...filterValues, showVoidedLabels});
        },
        isOn: filterValues.showVoidedLabels,
      }}
    />
  );
};

const FilterRoomItemsDrawerContent = ({
  urlFilters,
  filterValues,
  setFilterValues,
  showRoomFilter,
  itemTags,
  exceptionTypes,
}) => {
  const {form} = useContext(DriverInventoryContext);

  return (
    <ScrollView>
      <VoidLabelFilter
        index={0}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        urlFilters={urlFilters}
      />
      <Space height={16} />
      <MinMaxItemNumberFilters
        index={1}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        urlFilters={urlFilters}
      />
      <Space height={16} />
      {showRoomFilter && (
        <>
          <RoomFilter
            index={3}
            filterValues={filterValues}
            setFilterValues={setFilterValues}
            urlFilters={urlFilters}
            form={form}
          />
          <Space height={16} />
        </>
      )}
      <LotFilter
        index={4}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        urlFilters={urlFilters}
        form={form}
      />
      <Space height={16} />
      <ItemTagFilter
        index={6}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        urlFilters={urlFilters}
        itemTags={itemTags}
      />
      <Space height={16} />
      <ExceptionTypeFilter
        index={7}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        urlFilters={urlFilters}
        exceptionTypes={exceptionTypes}
      />
      <Space height={128} />
    </ScrollView>
  );
};

const FilterRoomItemsDrawer = ({urlFilters, showRoomFilter, isOpen, handleClose}) => {
  const {
    inventory: {
      project: {
        projectType: {
          defaultDriverInventoryLibrary: {itemTags, exceptionTypes},
        },
      },
    },
  } = useContext(DriverInventoryContext);
  const [filterValues, setFilterValues] = useState({
    minItemNumber: urlFilters.get('minItemNumber') || '',
    maxItemNumber: urlFilters.get('maxItemNumber') || '',
    roomIds: urlFilters.get('roomIds') || [],
    lotNumbers: urlFilters.get('lotNumbers') || [],
    itemTags: urlFilters.get('itemTags') || [],
    exceptionTypes: urlFilters.get('exceptionTypes') || [],
    showVoidedLabels: true,
  });
  const defaultFilterValues = {
    minItemNumber: '',
    maxItemNumber: '',
    roomIds: [],
    lotNumbers: [],
    itemTags: [],
    exceptionTypes: [],
    showVoidedLabels: true,
  };
  const filterCount = _.differenceWith(
    _.toPairs(filterValues),
    _.toPairs(defaultFilterValues),
    _.isEqual,
  ).length;

  return (
    <Drawer
      isOpen={isOpen}
      onClose={handleClose}
      position={'BOTTOM'}
      contentContainerStyle={{top: '15svh', height: '85svh'}}
      // TODO(cassie): shouldCloseOnClickOutside must be disabled on mobile views due to an intermittent issue
      // where the popover opens and immediately closes. We should look into why this happens and how to get
      // around it. See this GitHub issue for more info
      // https://github.com/mui/material-ui/issues/3335
      shouldCloseOnClickOutside={false}
    >
      <DrawerContainer>
        <View style={{flex: 1, paddingHorizontal: 16}}>
          <Space height={16} />
          <Row>
            <DrawerTitle>{`Filters (${filterCount})`}</DrawerTitle>
            <Space style={{flex: 1}} />
            <TertiaryButton
              onPress={() => {
                setFilterValues({
                  minItemNumber: urlFilters.get('minItemNumber') || '',
                  maxItemNumber: urlFilters.get('maxItemNumber') || '',
                  roomIds: urlFilters.get('roomIds') || [],
                  lotNumbers: urlFilters.get('lotNumbers') || [],
                  itemTags: urlFilters.get('itemTags') || [],
                  exceptionTypes: urlFilters.get('exceptionTypes') || [],
                  showVoidedLabels: urlFilters.get('showVoidedLabels') !== 'false',
                });
                handleClose();
              }}
              text={'Close'}
            />
          </Row>
          <View style={{flex: 1}}>
            <Space height={16} />
            <FilterRoomItemsDrawerContent
              urlFilters={urlFilters}
              showRoomFilter={showRoomFilter}
              filterValues={filterValues}
              setFilterValues={setFilterValues}
              itemTags={itemTags}
              exceptionTypes={exceptionTypes}
            />
          </View>
        </View>
        <BottomButtonContainer>
          <Row>
            <SecondaryButton
              iconLeft={Icon.Trash}
              onPress={() => {
                setFilterValues({
                  minItemNumber: '',
                  maxItemNumber: '',
                  roomIds: [],
                  lotNumbers: [],
                  itemTags: [],
                  exceptionTypes: [],
                  showVoidedLabels: true,
                });
              }}
              text={'Clear'}
              style={{width: 116, height: '40px'}}
            />
            <Space width={16} />
            <Button
              onPress={() => {
                urlFilters.handleUpdate({
                  minItemNumber: filterValues.minItemNumber,
                  maxItemNumber: filterValues.maxItemNumber,
                  roomIds: filterValues.roomIds,
                  lotNumbers: filterValues.lotNumbers,
                  itemTags: filterValues.itemTags,
                  exceptionTypes: filterValues.exceptionTypes,
                  showVoidedLabels:
                    filterValues.showVoidedLabels === false ? filterValues.showVoidedLabels : null,
                  isOpen: null,
                });
              }}
              text={'Apply Filters'}
              style={{flex: 1, height: '40px'}}
              isWidthOfContainer
            />
          </Row>
        </BottomButtonContainer>
      </DrawerContainer>
    </Drawer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
FilterRoomItemsDrawer.fragment = gql`
  fragment FilterRoomItemsDrawer on InventoryLibrary {
    id
    itemTags: itemTagsByKind(itemTagKind: "${ItemTagKind.ITEM_TAG}") {
      id
      name
    }
    exceptionTypes: itemTagsByKind(itemTagKind: "${ItemTagKind.EXCEPTION_TYPE}") {
      id
      name
    }
  }
`;

export default FilterRoomItemsDrawer;
