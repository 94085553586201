// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {colors} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';

const SecondaryButtonText = ({isDisabled, color, style, children}) => {
  return (
    <Button.Text color={isDisabled ? colors.gray.border : color} style={style}>
      {children}
    </Button.Text>
  );
};

const SecondaryButton = ({children, isDisabled, textColor, style, ...props}) => {
  return (
    <Button
      {...props}
      isDisabled={isDisabled}
      textColor={isDisabled ? colors.gray.border : textColor}
      style={{
        borderWidth: 1,
        borderColor: isDisabled ? colors.gray.border : textColor || colors.blue.interactive,
        backgroundColor: colors.white,
        ...style,
      }}
    >
      {children}
    </Button>
  );
};

SecondaryButton.Text = SecondaryButtonText;

// --------------------------------------------------
// Props
// --------------------------------------------------
SecondaryButton.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  textColor: PropTypes.string,
  onPress: PropTypes.func,
  isSubmitting: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isWidthOfContainer: PropTypes.bool,
  style: PropTypes.object,
};

SecondaryButton.defaultProps = {
  color: colors.white,
  width: null,
  textColor: colors.blue.interactive,
  onPress: () => {},
  isSubmitting: false,
  isDisabled: false,
  isWidthOfContainer: false,
  style: {},
};

SecondaryButtonText.propTypes = {
  color: PropTypes.string,
  isDisabled: PropTypes.bool,
  style: PropTypes.object,
};

SecondaryButtonText.defaultProps = {
  color: colors.blue.interactive,
  isDisabled: false,
  style: null,
};

export default SecondaryButton;
