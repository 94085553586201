// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Styled, Space, Modal} from '@supermove/components';
import {useInternet, useModal} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import Callout from '@shared/design/components/Callout';
import FileViewer from '@shared/modules/File/components/FileViewer';

const SubHeadingText = Styled.Text`
  ${Typography.Heading2}
`;

const GridContainer = Styled.View`
  overflow: hidden;
  width: 100%;
`;

// width: calc() is neccesary here since it's a nested flex with negative margin
// otherwise the parent container will flex ignoring the negative margin
const AttachmentContainer = Styled.View`
  width: calc(100% + 6px);
  margin-horizontal: -3px;
  margin-bottom: -12px;
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(120px, 1fr));
`;

const GridItemContainer = Styled.ButtonV2`
  flex-direction: column;
  overflow: hidden;
  margin-horizontal: 8px;
  margin-bottom: 8px;
`;

const PreviewImageContainer = Styled.View`
  height: 126px;
  background-image: ${({fileUrl}) => `url(${fileUrl})`};
  background-size: contain;
  background-position: center center;  
  background-repeat: no-repeat;
  background-origin: content-box;
`;

const Overlay = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Wrapper = Styled.View`
  width: 100%;
  height: 100%;
  padding-horizontal: 16px;
`;

const View = Styled.View``;

const IconButton = Styled.ButtonV2`
  position: absolute;
  top: 16px;
  right: 0;
  z-index: 1000;
`;

const FullScreenImageModal = ({isOpen, handleClose, itemAttachment}) => {
  return (
    <Modal.Content isOpen={isOpen} onClose={handleClose}>
      <Overlay pointerEvents={'box-none'}>
        <Wrapper>
          <View style={{flex: 1}}>
            <IconButton onPress={() => handleClose()}>
              <Icon color={colors.white} size={36} source={Icon.Times} />
            </IconButton>
            <FileViewer
              file={{
                downloadUrl: itemAttachment.downloadUrl,
                filename: itemAttachment.filename,
                mimetype: 'image/png',
              }}
            />
          </View>
        </Wrapper>
      </Overlay>
    </Modal.Content>
  );
};

const PhotoDisplay = ({itemAttachment}) => {
  const fullScreenImageModal = useModal({name: 'Full Screen Image Modal'});

  return (
    <React.Fragment>
      <GridItemContainer onPress={() => fullScreenImageModal.handleOpen()}>
        <PreviewImageContainer fileUrl={itemAttachment.downloadUrl} />
      </GridItemContainer>
      <FullScreenImageModal
        isOpen={fullScreenImageModal.isOpen}
        handleClose={fullScreenImageModal.handleClose}
        itemAttachment={itemAttachment}
      />
    </React.Fragment>
  );
};

const ItemPhotosSection = ({itemAttachments, uploadPhotosDrawer}) => {
  const {isConnected} = useInternet();
  const photos = _.filter(itemAttachments, {isDeleted: false});

  return (
    <React.Fragment>
      <SubHeadingText>Photos</SubHeadingText>
      <Space height={16} />
      {!isConnected && (
        <React.Fragment>
          <Callout
            text={
              'You must have internet connection to upload photos. You can take the photo directly on your device and upload later once you have internet connection.'
            }
          />
          <Space height={16} />
        </React.Fragment>
      )}
      <SecondaryButton
        isWidthOfContainer
        text={'Add or Edit Photos'}
        onPress={uploadPhotosDrawer.handleOpen}
        isDisabled={!isConnected}
      />
      <Space height={16} />
      <GridContainer>
        <AttachmentContainer>
          {photos.map((itemAttachment, index) => {
            return (
              <PhotoDisplay
                key={index}
                itemAttachment={itemAttachment}
                uploadPhotosDrawer={uploadPhotosDrawer}
              />
            );
          })}
        </AttachmentContainer>
      </GridContainer>
      {photos.length > 0 && <Space height={16} />}
    </React.Fragment>
  );
};

export default ItemPhotosSection;
