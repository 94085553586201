// Supermove
import {useNavigation, useMountEffect} from '@supermove/hooks';

const BaseScreen = () => {
  const {navigator} = useNavigation();
  useMountEffect(() => {
    navigator.navigate('Calendar');
  });

  return null;
};

export default BaseScreen;
