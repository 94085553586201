// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Styled, Space} from '@supermove/components';
import {Typography, colors} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import Badge from '@shared/design/components/Badge';
import Table from '@shared/design/components/Table';
import ItemTagItemKind from '@shared/modules/Inventory/enums/ItemTagItemKind';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const HeaderCell = Styled.View`
`;

const BodyCell = Styled.View`
  flex: 1;
`;

const CollapsibleHeaderText = Styled.Text`
  ${Typography.Label}
  color: ${colors.gray.secondary};
`;

const TableHeaderText = Styled.Text`
  ${Typography.MicroLabel}
  color: ${colors.gray.primary};
`;

const TableText = Styled.Text`
  ${Typography.Micro}
  color: ${({color}) => color || colors.gray.primary};
`;

const HeavyTableText = Styled.Text`
  ${Typography.MicroLabel}
  color: ${colors.blue.interactive};
`;

const ColorDot = Styled.View`
  height: 8px;
  width: 8px;
  border-radius: 4px;
  border-width: 1px;
  border-color: ${({borderColor, color}) => borderColor || color};
  background-color: ${({color}) => color};
`;

const Touchable = Styled.Touchable``;

const getColumnDefinitions = ({roomItemForms, roomUuid, navigator, urlFilters}) => {
  return [
    {
      flex: 2,
      headerContent: () => (
        <HeaderCell style={{paddingLeft: 16}}>
          <TableHeaderText numberOfLines={1}>Item No.</TableHeaderText>
        </HeaderCell>
      ),
      cellContent: ({item: itemForm}) => (
        <BodyCell style={{paddingLeft: 16}}>
          <TableText
            color={itemForm.isVoid ? colors.gray.tertiary : colors.gray.primary}
            numberOfLines={1}
          >
            {itemForm.itemNumber}
          </TableText>
        </BodyCell>
      ),
    },
    {
      flex: 4,
      headerContent: () => (
        <HeaderCell style={{paddingLeft: 8}}>
          <TableHeaderText>Item</TableHeaderText>
        </HeaderCell>
      ),
      cellContent: ({item: itemForm}) => (
        <BodyCell style={{paddingLeft: 8}}>
          <Row>
            {itemForm.isVoid && (
              <>
                <Badge
                  textColor={colors.red700}
                  backgroundColor={colors.red50}
                  label={'Void'}
                  size={Badge.SIZE.SMALL}
                />
                <Space width={4} />
              </>
            )}
            <TableText
              color={itemForm.isVoid ? colors.gray.tertiary : colors.gray.primary}
              numberOfLines={1}
            >
              {itemForm.name}
            </TableText>
          </Row>
        </BodyCell>
      ),
    },
    {
      flex: 1,
      headerContent: () => (
        <HeaderCell>
          <TableHeaderText>Tags</TableHeaderText>
        </HeaderCell>
      ),
      cellContent: ({item: itemForm}) => {
        const itemTagsCount = _.filter(
          itemForm.itemTagItemForms,
          ({kind, isDeleted}) => kind === ItemTagItemKind.ITEM_TAG && !isDeleted,
        ).length;
        return (
          <BodyCell>
            <Touchable
              onPress={() =>
                navigateToItemView({
                  item: itemForm,
                  roomItemForms,
                  roomUuid,
                  navigator,
                  urlFilters,
                  scrollToBottom: true,
                })
              }
            >
              <Row>
                {itemTagsCount ? (
                  <React.Fragment>
                    <TableText color={colors.blue.interactive} numberOfLines={1}>
                      {itemTagsCount}
                    </TableText>
                    <Space width={4} />
                    <Icon source={Icon.Tag} color={colors.blue.interactive} size={11} />
                  </React.Fragment>
                ) : (
                  <HeavyTableText>Add</HeavyTableText>
                )}
              </Row>
            </Touchable>
          </BodyCell>
        );
      },
    },
    {
      flex: 2,
      headerContent: () => (
        <HeaderCell>
          <TableHeaderText>Exceptions</TableHeaderText>
        </HeaderCell>
      ),
      cellContent: ({item: itemForm}) => {
        const exceptionsCount = _.filter(
          itemForm.itemTagItemForms,
          ({kind, isDeleted}) => kind === ItemTagItemKind.EXCEPTION && !isDeleted,
        ).length;
        return (
          <BodyCell>
            <Touchable
              onPress={() =>
                navigateToItemView({
                  item: itemForm,
                  roomItemForms,
                  roomUuid,
                  navigator,
                  urlFilters,
                  scrollToBottom: true,
                })
              }
            >
              <Row>
                {exceptionsCount ? (
                  <React.Fragment>
                    <TableText color={colors.blue.interactive} numberOfLines={1}>
                      {exceptionsCount}
                    </TableText>
                    <Space width={4} />
                    <Icon source={Icon.Wrench} color={colors.blue.interactive} size={12} />
                  </React.Fragment>
                ) : (
                  <HeavyTableText>Add</HeavyTableText>
                )}
              </Row>
            </Touchable>
          </BodyCell>
        );
      },
    },
  ];
};

const clearUrlFilters = ({urlFilters}) => {
  // Clear param filters before navigating to a different page
  urlFilters.handleUpdate({
    query: null,
    minItemNumber: null,
    maxItemNumber: null,
    roomIds: null,
    lotNumbers: null,
    colors: null,
    itemTags: null,
    exceptionTypes: null,
    showVoidedLabels: null,
    isOpen: null,
  });
};

const navigateToItemView = ({
  item,
  roomItemForms = null,
  roomUuid = null,
  navigator,
  urlFilters,
  scrollToBottom = false,
}) => {
  // Find the room UUID where the item is located if roomUuid isn't provided
  const room = roomItemForms
    ? _.find(roomItemForms, (room) =>
        _.some(room.itemForms, (itemForm) => _.isEqual(itemForm, item)),
      )
    : null;
  clearUrlFilters({urlFilters});
  navigator.push('EditDriverInventoryItem', {
    roomUuid: roomUuid || room.uuid,
    itemUuid: item.uuid,
    fromViewFullInventory: !roomUuid,
    scrollToBottom,
  });
};

const ItemsTable = ({
  collapsibleTableStyle,
  roomItemForms,
  roomUuid,
  navigator,
  urlFilters,
  groupItems,
}) => {
  // All groups have at least one item
  const {lotNumber, color} = groupItems[0];
  const TitleComponent = () => (
    <>
      {color ? (
        <>
          <ColorDot
            color={color}
            borderColor={_.toLower(color) === _.toLower(colors.white) && colors.gray.secondary}
          />
          <Space width={4} />
        </>
      ) : (
        <Space width={12} />
      )}
      <CollapsibleHeaderText numberOfLines={1}>{lotNumber}</CollapsibleHeaderText>
      <Space style={{flex: 1}} />
      <CollapsibleHeaderText numberOfLines={1}>
        {pluralize('item', groupItems.length, true)}
      </CollapsibleHeaderText>
    </>
  );
  return (
    <Table.Collapsible
      TitleComponent={TitleComponent}
      columnDefinitions={getColumnDefinitions({
        roomItemForms,
        roomUuid,
        navigator,
        urlFilters,
      })}
      emptyStateText={'No items'}
      items={groupItems}
      itemKey={'itemId'}
      isClickable
      onRowPress={(item) =>
        navigateToItemView({
          item,
          roomItemForms,
          roomUuid,
          navigator,
          urlFilters,
        })
      }
      defaultCollapsed={false}
      collapsibleTableStyle={collapsibleTableStyle}
    />
  );
};

export default ItemsTable;
