// App
import CompleteUploadFileForm from '@shared/modules/File/forms/CompleteUploadFileForm';
import CreateAttachmentForm from '@shared/modules/File/forms/CreateAttachmentForm';
import RequestUploadFileForm from '@shared/modules/File/forms/RequestUploadFileForm';

const _new = ({
  organizationId,
  creatorId,
  projectId,
  jobId,
  inventoryId,
  roomId,
  attachmentCategoryKinds,
}) => ({
  requestUploadFileForm: RequestUploadFileForm.new({
    organizationId,
    creatorId,
  }),
  completeUploadFileForm: CompleteUploadFileForm.new(),
  createAttachmentForm: CreateAttachmentForm.new({
    projectId,
    jobId,
    inventoryId,
    roomId,
    attachmentCategoryKinds,
    description: '',
  }),
  file: null,
  url: null,
  fields: null,
});

const toForm = ({
  requestUploadFileForm,
  completeUploadFileForm,
  createAttachmentForm,
  file,
  url,
  fields,
}) => ({
  requestUploadFileForm: RequestUploadFileForm.toForm(requestUploadFileForm),
  completeUploadFileForm: CompleteUploadFileForm.toForm(completeUploadFileForm),
  createAttachmentForm: CreateAttachmentForm.toForm(createAttachmentForm),
  file,
  url,
  fields,
});

const UploadFileForm = {
  new: _new,
  toForm,
  // This form uses RequestUploadFileForm and CompleteUploadFileForm for toMutation
};

export default UploadFileForm;
