// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useRef, useState} from '@supermove/hooks';
import FieldValueExpandable from '@supermove/manager/src/modules/Project/V2/Show/Blocks/components/FieldValueExpandable';
import LocationsMap from '@supermove/manager/src/modules/Project/V2/Show/Blocks/components/LocationsMap';
import {Job, Location} from '@supermove/models';
import {colors, fontWeight, Typography} from '@supermove/styles';
import {pluralize, Location as LocationUtils} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import FieldValue from '@shared/design/components/Field/FieldValue';
import LocationKind from '@shared/modules/Location/enums/LocationKind';

const JobContainer = Styled.View`
  width: 100%;
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 4px;
  background-color: ${colors.white};
`;

const Container = Styled.View`
  padding: 16px;
`;

const MapContainer = Styled.View`
  flex: 1;
  overflow: hidden;
`;

const AdditionalInformationContainer = Styled.View`
  padding-horizontal: 16px;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.gray.border};
`;

const VerticalLine = Styled.View`
  height: 100%;
  width: 1px;
  background-color: ${colors.gray.border};
`;

const LocationBadge = Styled.View`
  padding-vertical: 4px;
  padding-horizontal: 8px;
  background-color: ${(props) => props.color};
  border-radius: 2px;
`;

const LocationBadgeText = Styled.Text`
  ${Typography.MicroLabel}
  ${fontWeight(800)}
  color: ${(props) => props.color};
`;

const Text = Styled.Text`
  ${Typography.Mobile.Body}
  color: ${(props) => props.color};
`;

const Label = Styled.Text`
  ${Typography.Mobile.Label}
`;

const Subheading = Styled.Text`
  ${Typography.Mobile.Subheading}
`;

const MAP_MIN_HEIGHT = 320;
const NOTES = 'notes';

const getAdditionalInformationKeys = ({location}) => {
  return Location.AdditionalInformation.Values.filter(
    (key) => !!location[key] || location[key] === false,
  );
};

const LocationItemHeader = ({location, totalLocations, index}) => {
  return (
    <Row>
      <Label style={{color: colors.gray.secondary}}>{`Location ${
        index + 1
      } of ${totalLocations}`}</Label>
      <Space style={{flex: 1}} />
      {location.kind === LocationKind.PICK_UP && (
        <LocationBadge color={colors.blue.accent}>
          <LocationBadgeText color={colors.blue.interactive}>Pick-up</LocationBadgeText>
        </LocationBadge>
      )}
      {location.kind === LocationKind.DROP_OFF && (
        <LocationBadge color={colors.green.accent}>
          <LocationBadgeText color={colors.green.status}>Drop-off</LocationBadgeText>
        </LocationBadge>
      )}
    </Row>
  );
};

const LocationAddress = ({location}) => {
  return (
    <FieldValue
      newTab
      size={FieldValue.SIZE.MEDIUM}
      link={LocationUtils.createGoogleMapsUrl(location.address)}
      value={Location.getDisplayAddressCityStateZip(location)}
      style={{display: 'block'}}
    />
  );
};

const LocationInformation = ({location, informationKey}) => {
  const information = location[informationKey];

  switch (informationKey) {
    case Location.AdditionalInformation.UNIT:
      return <Text>{`Unit ${information}`}</Text>;
    case Location.AdditionalInformation.FLOOR_NUMBER:
      return <Text>{`Floor ${information}`}</Text>;
    case Location.AdditionalInformation.BUILDING_TYPE:
      return <Text>{information}</Text>;
    case Location.AdditionalInformation.STAIR_COUNT:
      return <Text>{`${pluralize('flight', information, true)} of stairs`}</Text>;
    case Location.AdditionalInformation.HAS_ELEVATOR:
      return (
        <LocationBadge color={colors.gray.border} style={{width: 83}}>
          <Row>
            <Icon
              source={information ? Icon.Check : Icon.Xmark}
              size={12}
              color={colors.gray.secondary}
            />
            <Space width={8} />
            <LocationBadgeText color={colors.gray.secondary}>Elevator</LocationBadgeText>
          </Row>
        </LocationBadge>
      );
    case Location.AdditionalInformation.HAS_LONG_WALK:
      return (
        <LocationBadge color={colors.gray.border} style={{width: 96}}>
          <Row>
            <Icon
              source={information ? Icon.Check : Icon.Xmark}
              size={12}
              color={colors.gray.secondary}
            />
            <Space width={8} />
            <LocationBadgeText color={colors.gray.secondary}>Long Walk</LocationBadgeText>
          </Row>
        </LocationBadge>
      );
    default:
      return null;
  }
};

const AdditionalInformation = ({location}) => {
  const [truncated, setTruncated] = useState({
    [NOTES]: true,
  });
  const [enabledToggle, setEnabledToggle] = useState({
    [NOTES]: false,
  });
  const additionalInformationKeys = getAdditionalInformationKeys({location});
  const displayKeyOrder = [
    Location.AdditionalInformation.UNIT,
    Location.AdditionalInformation.FLOOR_NUMBER,
    Location.AdditionalInformation.BUILDING_TYPE,
    Location.AdditionalInformation.STAIR_COUNT,
  ];
  return (
    <React.Fragment>
      <Row>
        <LocationInformation
          location={location}
          informationKey={Location.AdditionalInformation.HAS_ELEVATOR}
        />
        <Space width={8} />
        <LocationInformation
          location={location}
          informationKey={Location.AdditionalInformation.HAS_LONG_WALK}
        />
      </Row>
      <AdditionalInformationContainer>
        {_.map(displayKeyOrder, (key) => {
          if (_.includes(additionalInformationKeys, key)) {
            return (
              <React.Fragment key={key}>
                <Space height={4} />
                {key === Location.AdditionalInformation.UNIT &&
                _.includes(
                  additionalInformationKeys,
                  Location.AdditionalInformation.FLOOR_NUMBER,
                ) ? (
                  <Row>
                    <LocationInformation
                      location={location}
                      informationKey={Location.AdditionalInformation.UNIT}
                    />
                    <Space width={12} />
                    <VerticalLine />
                    <Space width={12} />
                    <LocationInformation
                      location={location}
                      informationKey={Location.AdditionalInformation.FLOOR_NUMBER}
                    />
                  </Row>
                ) : key === Location.AdditionalInformation.FLOOR_NUMBER &&
                  _.includes(
                    additionalInformationKeys,
                    Location.AdditionalInformation.FLOOR_NUMBER,
                  ) ? null : (
                  <LocationInformation location={location} informationKey={key} />
                )}
              </React.Fragment>
            );
          }
        })}
        {!!location.notes && (
          <React.Fragment>
            <Space height={4} />
            <Row style={{alignItems: 'flex-start'}}>
              <Text style={{textDecorationLine: 'underline'}} color={colors.gray.secondary}>
                Note
              </Text>
              <Text color={colors.gray.secondary}>:</Text>
              <Space width={4} />
              <FieldValueExpandable
                size={FieldValue.SIZE.MEDIUM}
                data={location}
                property={NOTES}
                numberOfLines={2}
                isTruncated={truncated[NOTES]}
                isEnabledToggle={enabledToggle[NOTES]}
                setTruncated={setTruncated}
                setEnabledToggle={setEnabledToggle}
              />
            </Row>
          </React.Fragment>
        )}
      </AdditionalInformationContainer>
    </React.Fragment>
  );
};

const StopsBlock = ({job}) => {
  const {navigator} = useNavigationDOM();
  const mapRef = useRef();
  const [isReadyForMap, setIsReadyForMap] = useState(false);
  const totalLocations = job.locations.length;

  return (
    <React.Fragment>
      <JobContainer>
        <Container>
          <Subheading>{`Stops (${totalLocations})`}</Subheading>
          <Space height={4} />
          <Text>{`${pluralize('mile', _.round(job.totalDistance, 2), true)} total`}</Text>
        </Container>
        <Line />
        <Container style={{width: '100%'}}>
          <Label>Arrival Window</Label>
          <Space height={4} />
          <Text>{Job.getArrivalWindow(job)}</Text>
          {_.map(job.locations, (location, index) => {
            return (
              <React.Fragment key={index}>
                <Space height={16} />
                <LocationItemHeader
                  location={location}
                  totalLocations={totalLocations}
                  index={index}
                />
                <Space height={4} />
                {!!location.name && (
                  <React.Fragment>
                    <Label>{location.name}</Label>
                    <Space height={4} />
                  </React.Fragment>
                )}
                <LocationAddress location={location} />
                <Space height={4} />
                <AdditionalInformation location={location} />
              </React.Fragment>
            );
          })}
          <Space height={16} />
          <Row style={{justifyContent: 'space-between'}}>
            <Button
              style={{width: '82%'}}
              isWidthOfContainer
              text={'Open Route'}
              onPress={() => {
                // job.locations is already ordered by index
                const origin = job.locations[0].address;
                const destination = job.locations[job.locations.length - 1].address;
                const waypoints = _.chain(job.locations).slice(1, -1).map('address').value();

                const link =
                  job.locations.length > 1
                    ? LocationUtils.createGoogleMapsRouteUrl({origin, destination, waypoints})
                    : LocationUtils.createGoogleMapsUrl(origin);
                navigator.pushNewTab(link);
              }}
            />
            <SecondaryButton
              iconLeft={Icon.MapMarkedAlt}
              iconSize={12}
              onPress={() => setIsReadyForMap(!isReadyForMap)}
            />
          </Row>
        </Container>
        {isReadyForMap && (
          <MapContainer style={{minHeight: MAP_MIN_HEIGHT}}>
            <LocationsMap
              key={`map_${job.id}`}
              locations={job.locations}
              organization={job.organization}
              mapRef={mapRef}
              setLocationIdsWithStreetView={() => {}}
              showRoute
              onRouteUpdate={() => {}}
            />
          </MapContainer>
        )}
      </JobContainer>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
StopsBlock.fragment = gql`
  ${Location.getDisplayAddressCityStateZip.fragment}
  ${Location.AdditionalInformation.fragment}
  ${LocationsMap.fragment}
  fragment StopsBlock on Job {
    id
    startTime1
    startTime2
    totalDistance
    locations {
      id
      name
      kind
      notes
      address
      ...Location_getDisplayAddressCityStateZip
      ...Location_AdditionalInformation
      ...LocationsMap_Location
    }
    organization {
      id
      ...LocationsMap_Organization
    }
  }
`;

export default StopsBlock;
