const _new = () => ({
  uuid: null,
});

const toForm = ({uuid}) => ({
  uuid,
});

const toMutation = ({uuid}) => ({
  uuid,
});

const CompleteUploadFileForm = {
  new: _new,
  toForm,
  toMutation,
};

export default CompleteUploadFileForm;
