// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {colors} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';

const QuaternaryButtonText = ({isDisabled, color, style, children}) => {
  return (
    <Button.Text color={isDisabled ? colors.gray.border : color} style={style}>
      {children}
    </Button.Text>
  );
};

const QuaternaryButton = ({children, isDisabled, textColor, style, ...props}) => {
  return (
    <Button
      {...props}
      isSubmitting={false}
      isDisabled={isDisabled}
      textColor={isDisabled ? colors.gray.border : textColor}
      style={{
        backgroundColor: 'transparent',
        ...style,
      }}
    >
      {children}
    </Button>
  );
};

QuaternaryButton.Text = QuaternaryButtonText;

// --------------------------------------------------
// Props
// --------------------------------------------------
QuaternaryButton.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  textColor: PropTypes.string,
  onPress: PropTypes.func,
  isDisabled: PropTypes.bool,
  style: PropTypes.object,
};

QuaternaryButton.defaultProps = {
  color: 'transparent',
  width: null,
  textColor: colors.gray.secondary,
  onPress: () => {},
  isDisabled: false,
  style: {},
};

QuaternaryButtonText.propTypes = {
  color: PropTypes.string,
  isDisabled: PropTypes.bool,
  style: PropTypes.object,
};

QuaternaryButtonText.defaultProps = {
  color: colors.gray.secondary,
  isDisabled: false,
  style: null,
};

export default QuaternaryButton;
