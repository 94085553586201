const _new = ({userId}) => ({
  userId,
});

const toForm = ({userId}) => ({
  userId,
});

const toMutation = ({userId}) => ({
  userId,
});

const MoverAppSelectAccountForm = {
  new: _new,
  toForm,
  toMutation,
};

export default MoverAppSelectAccountForm;
