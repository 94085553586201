// Libraries
import _ from 'lodash';

const DAYS = 'DAYS';
const HOURS = 'HOURS';
const MINUTES = 'MINUTES';

const getLabel = ({kind}) => {
  switch (kind) {
    case DAYS:
      return 'Days';
    case MINUTES:
      return 'Minutes';
    case HOURS:
      return 'Hours';
    default:
      return kind;
  }
};

const getDropdownOptions = ({kinds}) => {
  return _.map(kinds, (kind) => {
    return {
      label: getLabel({kind}),
      value: kind,
    };
  });
};

const getMomentUnits = ({kind}) => {
  switch (kind) {
    case MINUTES:
      return 'm';
    case HOURS:
      return 'h';
    case DAYS:
      return 'd';
    default:
      return kind;
  }
};

const ExpirationTimeframeUnitKind = {
  DAYS,
  HOURS,
  MINUTES,

  ALL_UNIT_KINDS: [DAYS, HOURS, MINUTES],

  getLabel,
  getDropdownOptions,
  getMomentUnits,
};

export default ExpirationTimeframeUnitKind;
