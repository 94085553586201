/* eslint-disable react-hooks/exhaustive-deps */
// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useEffect,
  useMountEffect,
  useNavigation,
  useNavigationDOM,
  useQuery,
  useScrollView,
  useState,
} from '@supermove/hooks';
import {Job, Location} from '@supermove/models';
import {colors, fontWeight, Typography} from '@supermove/styles';
import {Datetime, URL} from '@supermove/utils';

// App
import JobUserStatus from '@shared/modules/Job/enums/JobUserStatus';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';

const Touchable = Styled.ButtonV2``;

const GetPositionWrapper = Styled.View`
  z-index: ${({index}) => 100 - index};
`;

const Container = Styled.View`
  padding-top: 16px;
  padding-horizontal: 16px;
  height: 100%;
`;

const JobContainer = Styled.View`
  width: 100%;
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 4px;
  background-color: ${colors.white};
  padding-horizontal: 12px;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const JobsListColumn = Styled.View`
  flex: 1;
  align-items: center;
`;

const MoveDateContainer = Styled.View`
  align-items: center;
  width: 50px;
`;

const MoveDateIconContainer = Styled.View`
    height: 32px;
    width: 32px;
    border-radius: 50%;
    align-items: center;
    border-width: 1px;
    background-color: ${({selected}) => {
      if (selected) {
        return colors.blue.interactive;
      }
      return colors.white;
    }};
    border-color: ${({selected}) => {
      if (selected) {
        return colors.blue.interactive;
      }
      return colors.gray.border;
    }};
`;

const TrainingBadge = Styled.View`
  padding-vertical: 4px;
  padding-horizontal: 8px;
  background-color: ${colors.purple.accent};
  border-radius: 2px;
  width: 86px;
`;

const TrainingBadgeText = Styled.Text`
  ${Typography.MicroLabel}
  ${fontWeight(800)}
  color: ${colors.purple.status};
`;

const CustomDayText = Styled.Text`
    ${Typography.Body}
    paddingTop: 6px;
    color: ${({selected}) => {
      if (selected) {
        return colors.white;
      }
      return colors.gray.secondary;
    }};
`;

const CustomWeekText = Styled.Text`
    ${Typography.Body}
    color: ${({selected}) => {
      if (selected) {
        return colors.blue.interactive;
      }
      return colors.gray.secondary;
    }};
`;

const DescriptionText = Styled.Text`
  ${Typography.Mobile.Body}
  color: ${colors.gray.tertiary};
  text-align: center;
`;

const LabelText = Styled.Text`
${Typography.Mobile.Label}
`;

const JobDetailText = Styled.Text`
  ${Typography.Mobile.Body}
  color: ${colors.gray.primary};
`;

const AddressText = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.secondary};
`;

// This is the number of pixels from the top where we consider
// a block to be the current block
const POSITION_Y_MARKER_OFFSET = 16;

const getAdjustedPositionY = (positionY) => positionY + POSITION_Y_MARKER_OFFSET;

const getFilteredUrlFromParams = (params, baseUrl) => {
  return URL.getUrlFromVariables(baseUrl, params);
};

const handleUpdateParam = ({baseUrl, navigator, params, paramKey, paramValue}) => {
  navigator.push(getFilteredUrlFromParams({...params, [paramKey]: paramValue || ''}, baseUrl));
};

const getBlocks = (month) => {
  const [year, monthStr] = month.split('-');
  const daysInMonth = new Date(year, monthStr, 0).getDate();
  return _.map(_.range(1, daysInMonth + 1), (day) => {
    const dayStr = _.padStart(day, 2, '0');
    return `${year}-${monthStr}-${dayStr}`;
  });
};

const getCurrentPositionYBlockKind = ({positionY, blockToPositionY, isAtBottom}) => {
  const blockKindAndPositionPairs = Object.entries(blockToPositionY);

  if (blockKindAndPositionPairs.length > 0) {
    blockKindAndPositionPairs.sort((blockA, blockB) => blockA[1] - blockB[1]);

    // If at top, return first block
    if (positionY === 0) {
      const [firstBlockKind] = blockKindAndPositionPairs[0];
      return firstBlockKind;
    }

    // If at bottom, return last block
    if (isAtBottom) {
      const [lastBlockKind] = blockKindAndPositionPairs[blockKindAndPositionPairs.length - 1];
      return lastBlockKind;
    }

    // A possible block is any block that starts prior to the current positionY
    const possibleBlocks = blockKindAndPositionPairs.filter(
      ([blockKind, blockPosition]) => blockPosition <= getAdjustedPositionY(positionY),
    );
    if (possibleBlocks.length > 0) {
      const currentPositionYBlock = possibleBlocks[possibleBlocks.length - 1];
      const [blockKind] = currentPositionYBlock;
      return blockKind;
    }
  }

  return null;
};

const MoveDateIcon = ({date}) => {
  const selected = Datetime.toMutationDate(Datetime.today) === date;
  const dateWithWeekdayName = Datetime.toDisplayDateWithWeekdayName(new Date(date));
  const [dayOfWeek, monthDay] = dateWithWeekdayName.split(', ');
  const [, day] = monthDay.split(' ');

  return (
    <MoveDateContainer>
      <CustomWeekText selected={selected}>{dayOfWeek}</CustomWeekText>
      <MoveDateIconContainer selected={selected}>
        <CustomDayText selected={selected}>{day}</CustomDayText>
      </MoveDateIconContainer>
    </MoveDateContainer>
  );
};

const getStatusIcon = ({status}) => {
  switch (status) {
    case JobUserStatus.CONFIRMED:
      return <Icon source={Icon.CheckCircle} color={colors.green.status} size={16} />;
    case JobUserStatus.REMOVED:
      return <Icon source={Icon.TimesCircle} color={colors.red.warning} size={16} />;
    default:
      return <Icon source={Icon.ExclamationTriangle} color={colors.orange.status} size={16} />;
  }
};

const MoverJobDetailsBlock = ({allowMoverAcceptDeclineJob, moverJob, date}) => {
  const {navigator} = useNavigation();
  const {job, status} = moverJob;
  const [startLocation, endLocation] = job.locations;

  return (
    <Touchable
      onPress={() => {
        navigator.push('Job', {
          jobUuid: job.uuid,
          block: date,
          date,
        });
      }}
      style={{width: '100%'}}
    >
      <JobContainer>
        <Space height={12} />
        {job.project.isTest && (
          <React.Fragment>
            <Row>
              <TrainingBadge>
                <TrainingBadgeText>Training Job</TrainingBadgeText>
              </TrainingBadge>
              {allowMoverAcceptDeclineJob && (
                <React.Fragment>
                  <Space style={{flex: 1}} />
                  {getStatusIcon({status})}
                </React.Fragment>
              )}
            </Row>
            <Space height={8} />
          </React.Fragment>
        )}
        <Row>
          <LabelText>{Job.getFullName(job)}</LabelText>
          {!job.project.isTest && allowMoverAcceptDeclineJob && (
            <React.Fragment>
              <Space style={{flex: 1}} />
              {getStatusIcon({status})}
            </React.Fragment>
          )}
        </Row>
        <Space height={4} />
        <JobDetailText>{Job.getArrivalWindow(job)}</JobDetailText>
        {job.project.client.name !== job.project.client.primaryContact.fullName && (
          <>
            <JobDetailText>{job.project.client.name}</JobDetailText>
          </>
        )}
        <JobDetailText>{job.project.client.primaryContact.fullName}</JobDetailText>
        <Space height={8} />
        <AddressText>
          <Icon source={Icon.MapPin} color={colors.blue.interactive} size={12} style={{width: 7}} />
          {`  ${Location.getDisplayCityStateZip(startLocation)}`}{' '}
          {endLocation && `- ${Location.getDisplayAddressCityStateZip(endLocation)}`}
        </AddressText>
        <Space height={12} />
      </JobContainer>
    </Touchable>
  );
};

const EmptyMoveJobDetailsBlock = () => {
  return (
    <JobContainer>
      <Space height={16} />
      <DescriptionText>No jobs assigned for this day.</DescriptionText>
      <Space height={16} />
    </JobContainer>
  );
};

const ListMoverJobDetails = ({
  allowMoverAcceptDeclineJob,
  moverCalendarDays,
  setBlockToPositionY,
}) => {
  return (
    <React.Fragment>
      {_.map(moverCalendarDays, (moverCalendarDay, index) => {
        return (
          <GetPositionWrapper
            index={index}
            key={`wrapper_${moverCalendarDay.date}_${index}`}
            onLayout={({nativeEvent}) => {
              setBlockToPositionY((previousBlockToPositionY) => ({
                ...previousBlockToPositionY,
                [moverCalendarDay.date]: nativeEvent.layout.y - POSITION_Y_MARKER_OFFSET,
              }));
            }}
          >
            <Row>
              <MoveDateIcon
                key={`${moverCalendarDay.date}_${index}`}
                date={moverCalendarDay.date}
              />
              <Space width={8} />
              <JobsListColumn>
                {moverCalendarDay.totalJobCount === 0 && (
                  <EmptyMoveJobDetailsBlock key={`${moverCalendarDay.date}_jobs_empty`} />
                )}
                {moverCalendarDay.totalJobCount > 0 &&
                  _.map(moverCalendarDay.moverJobs, (moverJob, index) => {
                    return (
                      <React.Fragment>
                        <MoverJobDetailsBlock
                          key={`${moverCalendarDay.date}_jobs_${index}`}
                          allowMoverAcceptDeclineJob={allowMoverAcceptDeclineJob}
                          moverJob={moverJob}
                          date={moverCalendarDay.date}
                        />
                        {index < moverCalendarDay.moverJobs.length - 1 && <Space height={12} />}
                      </React.Fragment>
                    );
                  })}
              </JobsListColumn>
            </Row>
            <Space height={12} />
          </GetPositionWrapper>
        );
      })}
    </React.Fragment>
  );
};

const ListMoverJobs = ({organization, selectedDate, isCollapsed}) => {
  const {navigator, params} = useNavigationDOM();
  const scrollView = useScrollView();

  const viewingMonth = Datetime.toMutationMonth(selectedDate);
  const [blockToPositionY, setBlockToPositionY] = useState({});
  const [positionY, setPositionY] = useState(0);
  const [isAtBottom, setIsAtBottom] = useState(false);
  const blocks = getBlocks(viewingMonth);

  const blockPositionsSum = _.sum(Object.values(blockToPositionY));

  // Watch to reset variables when changing months
  useEffect(() => {
    setBlockToPositionY({});
    setPositionY(0);
    setIsAtBottom(false);
  }, [viewingMonth]);

  // Watch to possibly update the block param on initial render
  useMountEffect(() => {
    if (!params.block) {
      handleUpdateParam({
        baseUrl: '/calendar',
        navigator,
        params,
        paramKey: 'block',
        paramValue: selectedDate,
      });
    }
  }, [params.block]);

  // Watch to possibly scroll to a block when the block param or a block position changes
  useEffect(() => {
    const loadedBlocksCount = _.size(blockToPositionY);

    if (loadedBlocksCount === blocks.length) {
      const currentPositionYBlock = getCurrentPositionYBlockKind({positionY, blockToPositionY});
      if (currentPositionYBlock && currentPositionYBlock !== params.block) {
        const y = blockToPositionY[params.block];
        // Check if the block exists in blockToPositionY before scrolling to it
        if (y !== undefined) {
          scrollView.handleScrollTo({y, animated: true});
        }
      }
    }
  }, [params.block, blockPositionsSum]);

  // Watch to possibly update the block param when the user is scrolling
  useEffect(() => {
    const currentPositionYBlock = getCurrentPositionYBlockKind({
      positionY,
      blockToPositionY,
      isAtBottom,
    });
    if (currentPositionYBlock && currentPositionYBlock !== params.block) {
      if (isAtBottom) {
        handleUpdateParam({
          baseUrl: '/calendar',
          navigator,
          params,
          paramKey: 'block',
          paramValue: blocks[blocks.length - 1],
        });
      } else {
        handleUpdateParam({
          baseUrl: '/calendar',
          navigator,
          params,
          paramKey: 'block',
          paramValue: currentPositionYBlock,
        });
      }
    }
  }, [positionY]);

  const {loading, data} = useQuery(ListMoverJobs.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      month: viewingMonth,
      slugs: [organization.slug],
    },
  });

  if (loading || !data) return <PageLoadingIndicator />;

  return (
    <ScrollView
      ref={scrollView.ref}
      onScroll={({nativeEvent}) => {
        const {layoutMeasurement, contentOffset, contentSize} = nativeEvent;
        const isScrollAtBottom = layoutMeasurement.height + contentOffset.y >= contentSize.height;
        setIsAtBottom(isScrollAtBottom);
        setPositionY(contentOffset.y);
      }}
    >
      <Container pointerEvents={!isCollapsed ? 'none' : 'auto'}>
        <ListMoverJobDetails
          allowMoverAcceptDeclineJob={data.viewer.organization.settings.allowMoverAcceptDeclineJob}
          moverCalendarDays={data.viewer.moverCalendar.moverCalendarDays}
          selectedDate={selectedDate}
          setBlockToPositionY={setBlockToPositionY}
        />
        <Space height={84} />
      </Container>
    </ScrollView>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ListMoverJobs.query = gql`
  ${Job.getFullName.fragment}
  ${Location.getDisplayCityStateZip.fragment}
  query ListMoverJobs($month: String!, $slugs: [String]!) {
    ${gql.query}
    viewer {
      id
      organization {
        id
        settings {
          id
          allowMoverAcceptDeclineJob
        }
      }
      moverCalendar(month: $month, slugs: $slugs) {
        moverCalendarDays {
          date
          moverJobs {
            job {
              id
              uuid
              startTime1
              startTime2
              fullName
              locations {
                id
                ...Location_getDisplayCityStateZip
              }
              project {
                id
                isTest
                client {
                  id
                  name
                  primaryContact {
                    id
                    fullName
                  }
                }
              }
              ...Job_getFullName
            }
            status
          }
          totalJobCount
        }
      }
    }
  }
`;

ListMoverJobs.fragment = gql`
  fragment ListMoverJobs on Organization {
    id
    slug
  }
`;

export default ListMoverJobs;
