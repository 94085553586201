const _new = ({organizationId, creatorId}) => ({
  filename: null,
  mimetype: null,
  organizationId,
  creatorId,
});

const toForm = ({filename, mimetype, organizationId, creatorId}) => ({
  filename,
  mimetype,
  organizationId,
  creatorId,
});

const toMutation = ({filename, mimetype, organizationId, creatorId}) => ({
  filename,
  mimetype,
  organizationId,
  creatorId,
});

const RequestUploadFileForm = {
  new: _new,
  toForm,
  toMutation,
};

export default RequestUploadFileForm;
