// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Drawer, Icon, Space, Styled, Popover} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useRef, useState, useEffect, usePopover} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Checkbox from '@shared/design/components/Checkbox';
import SearchBar from '@shared/design/components/SearchBar';
import Table from '@shared/design/components/Table';

const MAX_TABLE_HEIGHT_MARGIN = 450;
const ROW_ITEM_HEIGHT = 46;

const View = Styled.View`
`;

const DrawerContainer = Styled.View`
  flex: 1;
  padding-horizontal: 16px;
  background-color: ${colors.gray.background}
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const Row = Styled.View`
  align-items: center;
  flex-direction: row;
`;

const ItemsContainer = Styled.View`
  flex: 1;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-top-width: 1px;
  border-color: ${colors.gray.border};
`;

const FilterPopoverContainer = Styled.View`
  max-height: 300px;
  background-color: ${colors.white};
  width: 272px;
  border-radius: 4px;
  padding: 12px 0px;
  border: 1px solid ${colors.gray.border};
  overflow-y: auto;
`;

const DrawerTitle = Styled.Text`
  ${Typography.PageHeading}
`;

const ItemName = Styled.Text`
  ${Typography.Micro}
  color: ${colors.gray.primary};
`;

const ItemDetails = Styled.Text`
  ${Typography.Micro}
`;

const toggleFilteredCategory = ({
  selectedCategoryId,
  filteredCategoryIds,
  setFilteredCategoryIds,
}) => {
  setFilteredCategoryIds(_.xor([selectedCategoryId], filteredCategoryIds));
  return;
};

const getVirtualizedHeights = ({itemHeight, window}) => {
  const maxHeight = window.screen.availHeight - MAX_TABLE_HEIGHT_MARGIN;
  return {
    virtualizedItemHeight: itemHeight,
    virtualizedTableHeight: maxHeight,
  };
};

const getColumnDefinitions = () => {
  const columns = [
    {
      flex: 2,
      cellContent: ({item: itemType}) => <ItemName>{itemType.name}</ItemName>,
    },
    {
      flex: 1,
      cellContent: ({item: itemType}) => {
        return (
          <ItemDetails>
            {itemType.volume} cu ft, {itemType.weight} lbs
          </ItemDetails>
        );
      },
    },
  ];
  return columns;
};

const getItemTypeMatchesSearch = ({searchTerm, itemType}) => {
  return (
    (searchTerm && itemType.name.toLowerCase().includes(searchTerm.toLowerCase())) || !searchTerm
  );
};

const getFilteredItemTypes = ({searchTerm, itemTypes, filteredCategoryIds}) => {
  return _.filter(itemTypes, (itemType) => {
    const itemTypeCategoryIds = _.map(itemType.itemTypeCategories, (itemTypeCategory) =>
      _.toString(itemTypeCategory.categoryId),
    );
    const categoryFiltersMatchItemCategory = _.some(filteredCategoryIds, (categoryId) =>
      _.includes(itemTypeCategoryIds, categoryId),
    );
    return (
      (_.isEmpty(filteredCategoryIds) || categoryFiltersMatchItemCategory) &&
      getItemTypeMatchesSearch({searchTerm, itemType})
    );
  });
};

const FilterOption = ({category, setFilteredCategoryIds, filteredCategoryIds}) => {
  const isSelected = _.includes(filteredCategoryIds, category.id);
  return (
    <Checkbox
      isChecked={isSelected}
      label={category.name}
      style={{flex: 1, justifyContent: 'flex-start', margin: 12}}
      handleToggle={() =>
        toggleFilteredCategory({
          selectedCategoryId: category.id,
          filteredCategoryIds,
          setFilteredCategoryIds,
        })
      }
    />
  );
};

const FilterPopover = ({popover, categories, setFilteredCategoryIds, filteredCategoryIds}) => {
  return (
    <Popover
      placement={Popover.Positions.BottomStart}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
    >
      <FilterPopoverContainer>
        {categories.map((category) => {
          return (
            <FilterOption
              key={category.id}
              category={category}
              setFilteredCategoryIds={setFilteredCategoryIds}
              filteredCategoryIds={filteredCategoryIds}
            />
          );
        })}
      </FilterPopoverContainer>
    </Popover>
  );
};

const SelectItemTypeDrawerContent = ({
  inventoryLibrary,
  onItemTypeSelect,
  handleClose,
  primaryCategoryId,
}) => {
  const ref = useRef();
  const [filteredItemTypes, setFilteredItemTypes] = useState([]);
  const [filteredCategoryIds, setFilteredCategoryIds] = useState(
    primaryCategoryId ? [_.toString(primaryCategoryId)] : [],
  );
  const [searchTerm, setSearchTerm] = useState('');
  const popover = usePopover();

  useEffect(() => {
    setFilteredItemTypes(
      getFilteredItemTypes({
        searchTerm,
        filteredCategoryIds,
        itemTypes: inventoryLibrary.itemTypes,
      }),
    );
  }, [searchTerm, filteredCategoryIds, inventoryLibrary]);

  const {virtualizedItemHeight, virtualizedTableHeight} = getVirtualizedHeights({
    itemHeight: ROW_ITEM_HEIGHT,
    window,
  });

  return (
    <React.Fragment>
      <Row ref={ref}>
        <SearchBar
          placeholder={'Search items'}
          iconColor={colors.gray.tertiary}
          containerStyle={{flex: 1}}
          style={{width: '100%'}}
          onChangeText={(value) => {
            setSearchTerm(value);
          }}
          valueOverride={searchTerm}
          isClearable
          isResponsive
        />
        <Space width={8} />
        <Popover.Content innerRef={popover.ref}>
          <SecondaryButton
            onPress={() => {
              ref.current.focus();
              popover.handleToggle();
            }}
            text={`(${filteredCategoryIds.length})`}
            iconLeft={Icon.Filter}
            style={{minWidth: 65}}
            isResponsive
          />
        </Popover.Content>
      </Row>
      <Space height={16} />
      <ItemsContainer>
        <Table.Virtualized
          columnDefinitions={getColumnDefinitions()}
          isEnabledHeader={false}
          emptyStateText={'No items to display'}
          items={filteredItemTypes}
          virtualizedItemHeight={virtualizedItemHeight}
          virtualizedTableHeight={virtualizedTableHeight}
          itemKey={'id'}
          isClickable
          onRowPress={(itemType) => {
            onItemTypeSelect(itemType);
            handleClose();
          }}
        />
      </ItemsContainer>
      <FilterPopover
        popover={popover}
        categories={inventoryLibrary.categories}
        setFilteredCategoryIds={setFilteredCategoryIds}
        filteredCategoryIds={filteredCategoryIds}
      />
    </React.Fragment>
  );
};

const SelectItemTypeDrawer = ({
  inventoryLibrary,
  handleItemTypeSelect,
  isOpen,
  handleClose,
  primaryCategoryId,
}) => {
  return (
    <Drawer
      isOpen={isOpen}
      onClose={handleClose}
      position={'BOTTOM'}
      contentContainerStyle={{top: '15svh', height: '85svh'}}
      // TODO(cooper): shouldCloseOnClickOutside must be disabled on mobile views due to an intermittent issue
      // where the popover opens and immediately closes. We should look into why this happens and how to get
      // around it. See this GitHub issue for more info
      // https://github.com/mui/material-ui/issues/3335
      shouldCloseOnClickOutside={false}
    >
      <DrawerContainer>
        <Space height={16} />
        <Row>
          <DrawerTitle>Items</DrawerTitle>
          <Space style={{flex: 1}} />
          <TertiaryButton onPress={handleClose} text={'Close'} />
        </Row>
        <View style={{flex: 1}}>
          <Space height={16} />
          <SelectItemTypeDrawerContent
            inventoryLibrary={inventoryLibrary}
            onItemTypeSelect={handleItemTypeSelect}
            handleClose={handleClose}
            primaryCategoryId={primaryCategoryId}
          />
        </View>
      </DrawerContainer>
    </Drawer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SelectItemTypeDrawer.fragment = gql`
  fragment SelectItemTypeDrawer on InventoryLibrary {
    id
    itemTypes {
      id
      name
      weight
      volume
      price
      kind
      isDerivedWeight
      itemTypeCategories {
        id
        categoryId
        category {
          id
          name
        }
      }
    }
    categories {
      id
      name
    }
  }
`;

export default SelectItemTypeDrawer;
