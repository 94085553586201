// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useInternet, useEffect, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

const LastSavedText = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.tertiary};
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const LastSavedInfo = ({lastSyncedAt}) => {
  const {isConnected} = useInternet();
  const [lastSyncedText, setLastSyncedText] = useState(Datetime.timeAgo(lastSyncedAt));

  // This updates the last synced text once per minute
  useEffect(() => {
    const interval = setInterval(() => {
      setLastSyncedText(Datetime.timeAgo(lastSyncedAt));
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, [lastSyncedAt]);

  const textColor = isConnected ? colors.gray.secondary : colors.red.warning;
  return (
    <Row>
      {!isConnected && (
        <React.Fragment>
          <Icon
            source={Icon.ExclamationTriangle}
            color={colors.red.warning}
            size={Icon.Sizes.ExtraSmall}
          />
          <Space width={8} />
          <LastSavedText style={{color: textColor}}>You are offline. </LastSavedText>
        </React.Fragment>
      )}

      <LastSavedText style={{color: textColor}}>Last Saved: {lastSyncedText}</LastSavedText>
    </Row>
  );
};

export default LastSavedInfo;
