const _new = () => ({
  phoneNumber: '',
});

const toForm = ({phoneNumber}) => ({
  phoneNumber,
});

const toMutation = ({phoneNumber}) => ({
  phoneNumber,
});

const GenerateMoverAppLoginCodeForm = {
  new: _new,
  toForm,
  toMutation,
};

export default GenerateMoverAppLoginCodeForm;
