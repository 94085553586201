// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import MoverAppSelectAccountForm from '@shared/modules/User/forms/MoverAppSelectAccountForm';

const useMoverAppSelectAccountMutation = ({moverAppSelectAccountForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      moverAppSelectAccountForm: MoverAppSelectAccountForm.toForm(moverAppSelectAccountForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useMoverAppSelectAccountMutation.mutation,
    variables: {
      moverAppSelectAccountForm: MoverAppSelectAccountForm.toMutation(
        form.values.moverAppSelectAccountForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useMoverAppSelectAccountMutation.mutation = gql`
  mutation useMoverAppSelectAccountMutation($moverAppSelectAccountForm: MoverAppSelectAccountForm) {
    response: moverAppSelectAccount(moverAppSelectAccountForm: $moverAppSelectAccountForm) {
      ${gql.errors}
      token
      user {
        id
      }
    }
  }
`;

export default useMoverAppSelectAccountMutation;
