// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, SingleDatePicker, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Datetime, URL} from '@supermove/utils';

// App
import QuaternaryButton from '@shared/design/components/Button/QuaternaryButton';

const CalendarHeader = Styled.Text`
  ${Typography.Heading2}
`;

const CollapsibleTableContainer = Styled.View`
  background-color: ${colors.white};
  border-color: ${colors.gray.border};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-width: 1px;
  boxShadow: 0px 3px 6px ${colors.gray.border};
`;

const CollapsedRow = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-horizontal: 12px;
  padding-vertical: 8px;
`;

const CustomCountText = Styled.Text`
    ${Typography.Body}
    line-height: 8px;
    letter-spacing: 2px;
    color: ${({color}) => color || colors.blue.interactive};
`;

const handleUpdateParam = ({baseUrl, navigator, params}) => {
  navigator.push(URL.getUrlFromVariables(baseUrl, params));
};

const DayDecoratorComponent = ({totalJobCountMap, ...props}) => {
  const totalCount = totalJobCountMap[props.date];
  const customCountText = '•'.repeat(Math.min(totalCount, 3));

  return (
    <SingleDatePicker.DayDecoratorRow {...props} marginBottom={6}>
      <CustomCountText>
        {customCountText}
        {totalCount > 3 && !props.isSelected && !props.isToday && (
          <CustomCountText style={{fontSize: '12px'}} color={colors.gray.primary}>
            +
          </CustomCountText>
        )}
      </CustomCountText>
    </SingleDatePicker.DayDecoratorRow>
  );
};

const CalendarDayPicker = ({
  organization,
  selectedDate,
  setSelectedDate,
  isCollapsed,
  setIsCollapsed,
}) => {
  const {navigator, params} = useNavigationDOM();
  const [selectedCalendarDate, setSelectedCalendarDate] = useState(selectedDate);
  const [viewingMonth, setViewingMonth] = useState(Datetime.toMutationMonth(selectedCalendarDate));
  const daySize = _.round(window.innerWidth / 8); // To adjust for different mobile sized screens

  const handleViewingMonthChange = (date) => {
    setSelectedDate(date);
    setSelectedCalendarDate(date);
    handleUpdateParam({
      baseUrl: '/calendar',
      navigator,
      params: {
        ...params,
        block: date,
        date,
      },
    });
    setViewingMonth(Datetime.toMutationMonth(date));
  };

  const handleSetDateToToday = () => {
    const todayDate = Datetime.toDate(Datetime.today);
    handleDateChange(todayDate);
    setViewingMonth(Datetime.toMutationMonth(todayDate));
    setIsCollapsed(true);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedCalendarDate(date);
    setIsCollapsed(true);
    handleUpdateParam({
      baseUrl: '/calendar',
      navigator,
      params: {
        ...params,
        block: date,
        date,
      },
    });
  };

  const {loading, data} = useQuery(CalendarDayPicker.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      month: viewingMonth,
      slugs: [organization.slug],
    },
  });

  const totalJobCountMap = {};
  if (!loading) {
    data.viewer.moverCalendar.moverCalendarDays.forEach((moverCalendarDay) => {
      totalJobCountMap[moverCalendarDay.date] = moverCalendarDay.totalJobCount;
    });
  }

  return (
    <CollapsibleTableContainer style={{zIndex: 100}}>
      <CollapsedRow style={{zIndex: 100}}>
        <QuaternaryButton
          onPress={() => {
            setIsCollapsed(!isCollapsed);
          }}
        >
          <CalendarHeader>{Datetime.convertToDate(viewingMonth, 'MMMM YYYY')}</CalendarHeader>
          <Space width={12} />
          <Icon
            source={isCollapsed ? Icon.AngleUp : Icon.AngleDown}
            color={colors.gray.primary}
            size={18}
            style={{width: 12}}
          />
        </QuaternaryButton>
        <Space style={{flex: 1}} />
        <QuaternaryButton
          width={36}
          onPress={() => {
            handleViewingMonthChange(
              Datetime.toDate(
                Datetime.clone(selectedCalendarDate).subtract(1, 'month').startOf('month'),
              ),
            );
          }}
        >
          <Icon source={Icon.AngleLeft} color={colors.gray.primary} size={18} style={{width: 12}} />
        </QuaternaryButton>
        <Space width={8} />
        <QuaternaryButton
          width={36}
          onPress={() => {
            handleViewingMonthChange(
              Datetime.toDate(
                Datetime.clone(selectedCalendarDate).add(1, 'month').startOf('month'),
              ),
            );
          }}
        >
          <Icon
            source={Icon.AngleRight}
            color={colors.gray.primary}
            size={18}
            style={{width: 12}}
          />
        </QuaternaryButton>
        <Space width={8} />
        <QuaternaryButton width={36} onPress={handleSetDateToToday}>
          <Icon
            source={Icon.CalendarClock}
            color={colors.blue.interactive}
            size={18}
            style={{width: 20}}
          />
        </QuaternaryButton>
      </CollapsedRow>
      {!isCollapsed && (
        <CollapsibleTableContainer style={{marginTop: '-48px'}}>
          <SingleDatePicker
            key={viewingMonth}
            noBorder
            noNavButtons
            hideMonthText
            daySize={daySize}
            selectedDate={selectedCalendarDate}
            handleDateChange={handleDateChange}
            viewingMonth={viewingMonth}
            loading={loading}
            dayTextStyle={{
              fontWeight: '500',
              fontSize: 14,
              lineHeight: 20,
            }}
            DayDecoratorComponent={(props) => {
              return <DayDecoratorComponent {...props} totalJobCountMap={totalJobCountMap} />;
            }}
          />
        </CollapsibleTableContainer>
      )}
    </CollapsibleTableContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CalendarDayPicker.query = gql`
  query CalendarDayPicker($month: String!, $slugs: [String]!) {
    ${gql.query}
    viewer {
      id
      moverCalendar(month: $month, slugs: $slugs) {
        moverCalendarDays {
          date
          totalJobCount
        }
      }
    }
  }
`;

CalendarDayPicker.fragment = gql`
  fragment CalendarDayPicker on Organization {
    id
    slug
  }
`;

export default CalendarDayPicker;
