// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import ActionModal from '@shared/design/components/Modal/SmallModal/ActionModal';

const DeleteModal = ({
  title,
  subtitle,
  deleteButtonText,
  cancelButtonText,
  isOpen,
  handleClose,
  handleDelete,
  isSubmitting,
  isDisabled,
  icon,
}) => {
  return (
    <ActionModal
      icon={icon}
      color={colors.red.warning}
      title={title}
      message={subtitle}
      handlePressOutside={handleClose}
      handlePrimaryAction={handleDelete}
      primaryActionText={deleteButtonText}
      handleSecondaryAction={handleClose}
      secondaryActionText={cancelButtonText}
      isSubmitting={isSubmitting}
      isDisabled={isDisabled}
      isOpen={isOpen}
    />
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
DeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  deleteButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  isSubmitting: PropTypes.bool,
  icon: Icon.SourcePropType,
};

DeleteModal.defaultProps = {
  deleteButtonText: 'Delete',
  cancelButtonText: 'Cancel',
  isSubmitting: false,
  icon: Icon.Trash,
};

export default DeleteModal;
