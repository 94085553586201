const CREATED = 'CREATED';
const VIEWED = 'VIEWED';
const CONFIRMED = 'CONFIRMED';
const REMOVED = 'REMOVED';

const JobUserStatus = {
  CREATED,
  VIEWED,
  CONFIRMED,
  REMOVED,
};

export default JobUserStatus;
