// Supermove
import {Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';

export const getSidebarItems = ({viewer}) => {
  return [
    {
      key: 'calendar',
      to: 'Calendar',
      name: 'Calendar',
      source: Icon.Calendar,
    },
  ];
};

getSidebarItems.fragment = gql`
  fragment getSidebarItems on User {
    id
  }
`;
