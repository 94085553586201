// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useMountEffect, useNavigation, useQuery} from '@supermove/hooks';
import {Storage} from '@supermove/sdk';

// App
import UserAnalyticsTracker from 'modules/App/components/UserAnalyticsTracker';

const AuthenticatedWrapperContent = ({viewer, refetch, children}) => {
  const {navigator, params} = useNavigation();

  const handleAuth = async () => {
    const {token, ...rest} = params;
    if (token) {
      // Handle case where an auth token was passed in the url, we use this when the mover app
      // is embedded in an iFrame or a webview
      await Storage.setItem('token', token);
      navigator.setParams(rest);
      refetch();
    } else if (!viewer) {
      // Otherwise, if the viewer is null, we redirect.
      navigator.navigate('GenerateLoginCodePage');
    }
  };

  useMountEffect(() => {
    handleAuth();
  });

  return (
    <React.Fragment>
      <UserAnalyticsTracker viewer={viewer} />
      {children}
    </React.Fragment>
  );
};

const AuthenticatedWrapper = ({children}) => {
  const {loading, data, refetch} = useQuery(AuthenticatedWrapper.query, {
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return null;
  }

  return <AuthenticatedWrapperContent viewer={data.viewer} refetch={refetch} children={children} />;
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AuthenticatedWrapper.query = gql`
  ${UserAnalyticsTracker.fragment}

  query AuthenticatedWrapper {
    ${gql.query}
    viewer {
      id
      ...UserAnalyticsTracker
    }
  }
`;

export default AuthenticatedWrapper;
