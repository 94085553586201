// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import Callout from '@shared/design/components/Callout';

const ErrorCallout = ({text, width, children}) => {
  return (
    <Callout
      text={text}
      width={width}
      textColor={colors.red.warning}
      backgroundColor={colors.red.accent}
      borderColor={colors.red.warning}
      icon={Icon.ExclamationTriangle}
      iconColor={colors.red.warning}
    >
      {children}
    </Callout>
  );
};

ErrorCallout.Text = Callout.Text;
ErrorCallout.LinkText = Callout.LinkText;
ErrorCallout.Link = Callout.Link;

// --------------------------------------------------
// Props
// --------------------------------------------------
ErrorCallout.propTypes = {
  text: PropTypes.string,
  width: PropTypes.number,
};

ErrorCallout.defaultProps = {
  text: '',
  width: null,
};

export default ErrorCallout;
