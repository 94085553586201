// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Drawer, Space, ScrollView, Styled, ToastContainer} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useRef, useState, useEffect, useModal, useToast} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {uuid} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Checkbox from '@shared/design/components/Checkbox';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import SearchBar from '@shared/design/components/SearchBar';
import ErrorToast from '@shared/design/components/Toast/ErrorToast';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import ItemTagItemKind from '@shared/modules/Inventory/enums/ItemTagItemKind';
import ItemTagKind from '@shared/modules/Inventory/enums/ItemTagKind';
import ItemTagItemForm from '@shared/modules/Inventory/forms/ItemTagItemForm';

const View = Styled.View`
`;

const DrawerContainer = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background}
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const ContentContainer = Styled.View`
  flex: 1;
  padding-horizontal: 16px;
`;

const ExceptionPreviewContainer = Styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  padding-vertical: 4px;
  padding-horizontal: 8px;
  background-color: ${colors.gray.background}
  border-color: ${colors.gray.border}
  border-width: 1px;
  border-radius: 8px;
`;

const ExceptionBadge = Styled.View`
  margin-vertical: 4px;
  padding-vertical: 4px;
  padding-horizontal: 12px;
  margin-right: 8px;
  background-color: ${colors.blue.accent}
  border-radius: 4px;
`;

const ExceptionBadgeText = Styled.Text`
  ${Typography.Mobile.Label}
  color: ${colors.blue.interactive}
`;

const Row = Styled.View`
  align-items: center;
  flex-direction: row;
`;

const DrawerTitle = Styled.Text`
  ${Typography.PageHeading}
`;

const SubHeadingText = Styled.Text`
  ${Typography.Heading2}
`;

const MobileBodyText = Styled.Text`
  ${Typography.Mobile.Body}
`;

const BottomButtonContainer = Styled.View`
  background-color: ${colors.gray.background}
  padding: 16px;
  border-top-width: 1px;
  border-color: ${colors.gray.border}
`;

const CheckboxContainer = Styled.ButtonV2`
  flex: 1;
`;

const ExceptionWarningModal = ({exceptionWarningModal, handleCloseDrawer}) => {
  return (
    <SmallModal isOpen={exceptionWarningModal.isOpen} style={{alignItems: 'center'}}>
      <SmallModal.HeaderIcon source={Icon.InfoCircle} color={colors.orange.status} />
      <Space height={12} />
      <SmallModal.HeaderText style={{color: colors.orange.status}}>
        Close exception?
      </SmallModal.HeaderText>
      <Space height={12} />
      <MobileBodyText>
        Closing this page will not save this exception. To continue adding this exception, stay on
        this page.
      </MobileBodyText>
      <Space height={12} />
      <SmallModal.Footer>
        <SmallModal.Button onPress={exceptionWarningModal.handleClose}>Cancel</SmallModal.Button>
        <Space width={8} />
        <SmallModal.Button color={colors.orange.status} onPress={handleCloseDrawer}>
          Confirm & Close
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

const ExceptionLocationsEditor = ({
  selectedItemTags,
  handleSelectItemTag,
  inventoryLibrary,
  handleNextPress,
}) => {
  const ref = useRef();
  const [filteredExceptionLocations, setFilteredExceptionLocations] = useState(
    inventoryLibrary.exceptionLocations,
  );
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    setFilteredExceptionLocations(
      inventoryLibrary.exceptionLocations.filter(({name}) =>
        _.includes(name.toLowerCase(), searchTerm.toLowerCase()),
      ),
    );
  }, [searchTerm, inventoryLibrary]);

  return (
    <React.Fragment>
      <ContentContainer>
        <Row>
          <SubHeadingText>Exception Locations</SubHeadingText>
          <Space style={{flex: 1}} />
          <MobileBodyText>1/2</MobileBodyText>
        </Row>
        <Space height={8} />
        <Row ref={ref}>
          <SearchBar
            placeholder={'Search exception locations'}
            iconColor={colors.gray.tertiary}
            containerStyle={{flex: 1}}
            style={{width: '100%'}}
            onChangeText={(value) => {
              setSearchTerm(value);
            }}
            valueOverride={searchTerm}
            isResponsive
            isClearable
          />
        </Row>
        <Space height={16} />
        <View style={{flex: 1}}>
          <ScrollView>
            {filteredExceptionLocations.map((itemTag, index) => {
              const isSelected = _.includes(selectedItemTags, itemTag.id);
              return (
                <Row key={index} style={{paddingHorizontal: 16, paddingVertical: 12}}>
                  <CheckboxContainer onPress={() => handleSelectItemTag(itemTag.id)}>
                    <Checkbox
                      isChecked={isSelected}
                      isMobile
                      label={itemTag.name}
                      handleToggle={() => handleSelectItemTag(itemTag.id)}
                    />
                  </CheckboxContainer>
                </Row>
              );
            })}
            <Space height={16} />
          </ScrollView>
        </View>
      </ContentContainer>
      <BottomButtonContainer>
        <Button
          style={{height: '40px'}}
          isWidthOfContainer
          text={'Next: Select Exception Types'}
          onPress={handleNextPress}
        />
      </BottomButtonContainer>
    </React.Fragment>
  );
};

const ExceptionTypesEditor = ({
  selectedItemTags,
  handleSelectItemTag,
  inventoryLibrary,
  handleBackPress,
  handleSave,
  saveButtonText,
}) => {
  const ref = useRef();
  const [filteredExceptionTypes, setFilteredExceptionTypes] = useState(
    inventoryLibrary.exceptionTypes,
  );
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    setFilteredExceptionTypes(
      inventoryLibrary.exceptionTypes.filter(({name}) =>
        _.includes(name.toLowerCase(), searchTerm.toLowerCase()),
      ),
    );
  }, [searchTerm, inventoryLibrary]);

  return (
    <React.Fragment>
      <ContentContainer>
        <Row>
          <SubHeadingText>Exception Types</SubHeadingText>
          <Space style={{flex: 1}} />
          <MobileBodyText>2/2</MobileBodyText>
        </Row>
        <Space height={8} />
        <Row ref={ref}>
          <SearchBar
            placeholder={'Search exception types'}
            iconColor={colors.gray.tertiary}
            containerStyle={{flex: 1}}
            style={{width: '100%'}}
            onChangeText={(value) => {
              setSearchTerm(value);
            }}
            valueOverride={searchTerm}
            isResponsive
            isClearable
          />
        </Row>
        <Space height={16} />
        <View style={{flex: 1}}>
          <ScrollView>
            {filteredExceptionTypes.map((itemTag, index) => {
              const isSelected = _.includes(selectedItemTags, itemTag.id);
              return (
                <Row key={index} style={{paddingHorizontal: 16, paddingVertical: 12}}>
                  <CheckboxContainer onPress={() => handleSelectItemTag(itemTag.id)}>
                    <Checkbox
                      isChecked={isSelected}
                      isMobile
                      label={itemTag.name}
                      handleToggle={() => handleSelectItemTag(itemTag.id)}
                    />
                  </CheckboxContainer>
                </Row>
              );
            })}
            <Space height={16} />
          </ScrollView>
        </View>
      </ContentContainer>
      <BottomButtonContainer>
        <Row>
          <View style={{flex: 1}}>
            <SecondaryButton
              iconLeft={Icon.ArrowLeft}
              isWidthOfContainer
              style={{height: '40px'}}
              text={'Back'}
              onPress={handleBackPress}
            />
          </View>
          <Space width={16} />
          <View style={{flex: 2}}>
            <Button
              isWidthOfContainer
              style={{height: '40px'}}
              text={saveButtonText}
              onPress={handleSave}
            />
          </View>
        </Row>
      </BottomButtonContainer>
    </React.Fragment>
  );
};

const ExceptionPreview = ({selectedItemTags, inventoryLibrary}) => {
  const selectedExceptionLocations = ItemTagItemForm.getSelectedItemTagsForItemTagKind(
    inventoryLibrary,
    {selectedItemTagIds: selectedItemTags, itemTagKind: ItemTagKind.EXCEPTION_LOCATION},
  );
  const selectedExceptionTypes = ItemTagItemForm.getSelectedItemTagsForItemTagKind(
    inventoryLibrary,
    {selectedItemTagIds: selectedItemTags, itemTagKind: ItemTagKind.EXCEPTION_TYPE},
  );

  return (
    <ExceptionPreviewContainer>
      {!selectedItemTags.length && (
        <MobileBodyText style={{color: colors.gray.tertiary, padding: '8px'}}>
          Make a selection to preview an exception
        </MobileBodyText>
      )}
      {selectedExceptionLocations.map((exceptionLocation, index) => (
        <React.Fragment key={index}>
          <ExceptionBadge>
            <ExceptionBadgeText>{exceptionLocation.name}</ExceptionBadgeText>
          </ExceptionBadge>
        </React.Fragment>
      ))}
      {selectedExceptionLocations.length && selectedExceptionTypes.length ? (
        <React.Fragment>
          <View style={{paddingTop: '8px'}}>
            <MobileBodyText>-</MobileBodyText>
          </View>
          <Space width={8} />
        </React.Fragment>
      ) : null}
      {selectedExceptionTypes.map((exceptionType, index) => (
        <React.Fragment key={index}>
          <ExceptionBadge>
            <ExceptionBadgeText>{exceptionType.name}</ExceptionBadgeText>
          </ExceptionBadge>
        </React.Fragment>
      ))}
    </ExceptionPreviewContainer>
  );
};

const EXCEPTION_LOCATION_STEP = 'EXCEPTION_LOCATION_STEP';
const EXCEPTION_TYPE_STEP = 'EXCEPTION_TYPE_STEP';

const ExceptionsDrawer = ({
  existingItemTagItem,
  inventoryLibrary,
  isOpen,
  handleClose,
  handleCreateException,
  handleEditException,
}) => {
  const [step, setStep] = useState(EXCEPTION_LOCATION_STEP);
  const [selectedItemTags, setSelectedItemTags] = useState(
    existingItemTagItem ? existingItemTagItem.itemTagIds : [],
  );
  const handleSelectItemTag = (itemTagId) => {
    const newSelectedItemTags = _.xor(selectedItemTags, [itemTagId]);
    setSelectedItemTags(newSelectedItemTags);
  };

  const successToast = useToast({
    ToastComponent: SuccessToast,
    message: `${existingItemTagItem ? 'Exception edited' : 'Exception added'}`,
    isClosable: true,
    duration: 1500,
  });
  const errorToast = useToast({
    ToastComponent: ErrorToast,
    message: 'Exception cannot be blank',
    isClosable: true,
    duration: 1500,
  });

  const exceptionWarningModal = useModal({name: 'Exception Warning Modal'});

  return (
    <Drawer
      isOpen={isOpen}
      onClose={handleClose}
      position={'BOTTOM'}
      contentContainerStyle={{top: '15svh', height: '85svh'}}
      // TODO(cooper): shouldCloseOnClickOutside must be disabled on mobile views due to an intermittent issue
      // where the popover opens and immediately closes. We should look into why this happens and how to get
      // around it. See this GitHub issue for more info
      // https://github.com/mui/material-ui/issues/3335
      shouldCloseOnClickOutside={false}
    >
      <DrawerContainer>
        <Space height={16} />
        <Row style={{paddingHorizontal: 16}}>
          <DrawerTitle>Add Exception</DrawerTitle>
          <Space style={{flex: 1}} />
          <TertiaryButton
            onPress={() => {
              if (selectedItemTags.length && !existingItemTagItem) {
                exceptionWarningModal.handleOpen();
              } else {
                handleClose();
              }
            }}
            text={'Close'}
          />
        </Row>
        <View style={{flex: 1}}>
          <View style={{paddingHorizontal: 16}}>
            <Space height={16} />
            <SubHeadingText>Exception Preview</SubHeadingText>
            <Space height={8} />
            <ExceptionPreview
              selectedItemTags={selectedItemTags}
              inventoryLibrary={inventoryLibrary}
            />
            <Space height={16} />
          </View>
          {step === EXCEPTION_LOCATION_STEP && (
            <ExceptionLocationsEditor
              selectedItemTags={selectedItemTags}
              handleSelectItemTag={handleSelectItemTag}
              inventoryLibrary={inventoryLibrary}
              handleNextPress={() => setStep(EXCEPTION_TYPE_STEP)}
            />
          )}
          {step === EXCEPTION_TYPE_STEP && (
            <ExceptionTypesEditor
              selectedItemTags={selectedItemTags}
              handleSelectItemTag={handleSelectItemTag}
              inventoryLibrary={inventoryLibrary}
              handleBackPress={() => setStep(EXCEPTION_LOCATION_STEP)}
              saveButtonText={existingItemTagItem ? 'Save' : 'Save & Add Next'}
              handleSave={() => {
                if (!selectedItemTags.length) {
                  errorToast.handleToast();
                } else if (existingItemTagItem) {
                  handleEditException({
                    ...existingItemTagItem,
                    itemTagIds: selectedItemTags,
                  });
                  handleClose();
                  successToast.handleToast();
                } else {
                  handleCreateException({
                    itemTagIds: selectedItemTags,
                    kind: ItemTagItemKind.EXCEPTION,
                    // NOTE(cooper): We need to create the uuid here so that we can reference it even before the parent item gets saved
                    uuid: uuid(),
                  });
                  setSelectedItemTags([]);
                  setStep(EXCEPTION_LOCATION_STEP);
                  successToast.handleToast();
                }
              }}
            />
          )}
        </View>
        <ExceptionWarningModal
          exceptionWarningModal={exceptionWarningModal}
          handleCloseDrawer={handleClose}
        />
        <ToastContainer position={ToastContainer.Position.TOP_CENTER} />
      </DrawerContainer>
    </Drawer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ExceptionsDrawer.fragment = gql`
  ${ItemTagItemForm.getSelectedItemTagsForItemTagKind.fragment}

  fragment ExceptionsDrawer on InventoryLibrary {
    id
    exceptionTypes: itemTagsByKind(itemTagKind: "${ItemTagKind.EXCEPTION_TYPE}") {
      id
      kind
      name
    }
    exceptionLocations: itemTagsByKind(itemTagKind: "${ItemTagKind.EXCEPTION_LOCATION}") {
      id
      kind
      name
    }
    ...ItemTagItemForm_getSelectedItemTagsForItemTagKind
  }
`;

export default ExceptionsDrawer;
