// Supermove
import {gql} from '@supermove/graphql';
import {Config} from '@supermove/sdk';
import {Document, withFragment} from '@supermove/utils';

// App
import InventoryLibraryObjectKind from '@shared/modules/Inventory/enums/InventoryLibraryObjectKind';

const getSlugFromKind = ({kind, isTemplate}) => {
  switch (kind) {
    case InventoryLibraryObjectKind.CATEGORIES:
      return 'categories';
    case InventoryLibraryObjectKind.ROOMS:
      return 'room_types';
    case InventoryLibraryObjectKind.CARTONS:
      return isTemplate ? 'item_types' : 'carton_item_types';
    case InventoryLibraryObjectKind.ITEMS:
      return isTemplate ? 'item_types' : 'item_item_types';
    case InventoryLibraryObjectKind.ITEM_TAGS:
      return 'item_tags';
    case InventoryLibraryObjectKind.EXCEPTION_TYPES:
      return 'exception_types';
    case InventoryLibraryObjectKind.EXCEPTION_LOCATIONS:
      return 'exception_locations';
    default:
      return '';
  }
};

const getFileDownloadUrl = ({inventoryLibraryUuid, slug, isTemplate}) => {
  const baseUrl = `${Config.getAPIHost()}/v1/files`;
  if (isTemplate) {
    return `${baseUrl}/inventory_libraries/templates/${slug}`;
  } else {
    return `${baseUrl}/inventory_libraries/${inventoryLibraryUuid}/${slug}`;
  }
};

const handleFileDownload = withFragment(
  ({inventoryLibrary, kind, isTemplate}) => async () => {
    const url = getFileDownloadUrl({
      inventoryLibraryUuid: inventoryLibrary.uuid,
      slug: getSlugFromKind({kind, isTemplate}),
      isTemplate,
    });
    const response = await fetch(url, {
      headers: {
        'Cache-Control': 'no-cache',
        'Authorization': `Bearer ${inventoryLibrary.organization.apiToken}`,
      },
    });
    const blob = await response.blob();
    Document.clickFile({file: blob, filename: 'filename.csv'});
  },
  gql`
    fragment InventoryLibrary_handleFileDownload on InventoryLibrary {
      id
      uuid
      organization {
        id
        apiToken
      }
    }
  `,
);

const InventoryLibrary = {
  handleFileDownload,
};

export default InventoryLibrary;
