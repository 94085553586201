// Libraries
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// Components
import Line from 'modules/App/components/Line';
import MobileNavigationSidebarItem from 'modules/App/components/MobileNavigationSidebarItem';
import SignOutModal from 'modules/App/components/SignOutModal';

const Container = Styled.View`
  flex: 1;
  width: 100%;
  border-right-width: 0px;
  border-color: ${colors.gray.border};
`;

const MobileFooterContainer = Styled.View`
  padding-horizontal: 8px;
`;

const SidebarMobileFooter = ({viewer}) => {
  const mobileUserIconModal = useModal({name: 'Mobile User Icon Modal'});

  return (
    <MobileFooterContainer>
      <Space height={8} />
      <MobileNavigationSidebarItem
        onPress={mobileUserIconModal.handleOpen}
        name={viewer.fullName}
        source={Icon.User}
        notificationCount={0}
        isSelected={false}
      />
      <Space height={8} />
      <SignOutModal
        viewer={viewer}
        isOpen={mobileUserIconModal.isOpen}
        handleClose={mobileUserIconModal.handleClose}
      />
    </MobileFooterContainer>
  );
};

const MobileNavigationSidebar = ({viewer, sidebarItems, handleClose, selected}) => {
  return (
    <Container>
      <React.Fragment>
        <ScrollView contentContainerStyle={{padding: 8}}>
          {sidebarItems.map(({key, to, name, source}, index) => {
            const isSelected = key === selected;
            return (
              <React.Fragment key={key}>
                {index > 0 && <Space height={4} />}
                <MobileNavigationSidebarItem
                  key={key}
                  isSelected={isSelected}
                  to={to}
                  name={name}
                  source={source}
                  handleClose={handleClose}
                />
              </React.Fragment>
            );
          })}
        </ScrollView>
        <Line />
        <SidebarMobileFooter viewer={viewer} />
      </React.Fragment>
    </Container>
  );
};

MobileNavigationSidebar.fragment = gql`
  ${SignOutModal.fragment}

  fragment MobileNavigationSidebar on User {
    id
    fullName
    ...SignOutModal
  }
`;

export default MobileNavigationSidebar;
