// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Drawer as BaseDrawer, Icon, ScrollView, Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';

const WIDTH = {
  DEFAULT: 400,
  MEDIUM: 620,
};

const Container = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
  width: ${({responsive, width}) => (!responsive.desktop ? '100%' : `${width}px`)};
`;

const BodyContainer = Styled.View`
  flex: 1;
`;

const HeaderContainer = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.white};
  padding-horizontal: 24px;
  padding-top: 24px;
  padding-bottom: 16px;
  border-color: ${colors.gray.border};
  border-bottom-width: 1px;
`;

const FooterContainer = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
  padding-horizontal: 24px;
  padding-top: 16px;
  padding-bottom: 24px;
  background-color: ${colors.white};
`;

const HeaderText = Styled.Text`
  ${Typography.Heading1}
  color: ${colors.gray.primary};
`;

const HeaderTextMobile = Styled.Text`
  ${Typography.Mobile.Heading2}
  color: ${colors.gray.primary};
`;

const Text = Styled.Text`
  ${Typography.Responsive.Body}
`;

const View = Styled.View`
`;

const PreventPropagationContainer = ({children, style}) => {
  return (
    <View
      onStartShouldSetResponder={(event) => true}
      onTouchEnd={(e) => {
        e.stopPropagation();
      }}
      style={style}
    >
      {children}
    </View>
  );
};

const Header = ({headerText, isClosable, handleClose, isResponsive, children}) => {
  const responsive = useResponsive();

  if (isResponsive && !responsive.desktop) {
    return <Drawer.HeaderMobile headerText={headerText} handleClose={handleClose} />;
  }

  return (
    <HeaderContainer>
      <HeaderText>{headerText}</HeaderText>
      {children}
      {isClosable && (
        <TertiaryButton onPress={handleClose} style={{height: 24, width: 24}} isHitSlop>
          <Icon source={Icon.Times} size={20} color={colors.gray.primary} />
        </TertiaryButton>
      )}
    </HeaderContainer>
  );
};

const HeaderMobile = ({headerText, handleClose}) => {
  return (
    <HeaderContainer>
      <Space width={24} />
      <Space style={{flex: 1}} />
      <HeaderTextMobile>{headerText}</HeaderTextMobile>
      <Space style={{flex: 1}} />
      <TertiaryButton onPress={handleClose} style={{height: 24, width: 24}} isHitSlop>
        <Icon source={Icon.Xmark} size={20} color={colors.gray.primary} />
      </TertiaryButton>
    </HeaderContainer>
  );
};

const Body = ({children, bodyScrollStyle, scrollViewRef}) => {
  const responsive = useResponsive();

  return (
    <BodyContainer>
      <ScrollView
        ref={scrollViewRef}
        contentContainerStyle={{
          paddingHorizontal: responsive.desktop ? 24 : 16,
          paddingVertical: 16,
          ...bodyScrollStyle,
        }}
      >
        {children}
      </ScrollView>
    </BodyContainer>
  );
};

const Footer = ({
  isDisabled,
  isSubmitting,
  primaryAction,
  secondaryAction,
  primaryActionText,
  secondaryActionText,
  isResponsive,
}) => {
  const responsive = useResponsive();

  if (isResponsive && !responsive.desktop) {
    return (
      <Drawer.FooterMobile
        isDisabled={isDisabled}
        isSubmitting={isSubmitting}
        primaryAction={primaryAction}
        primaryActionText={primaryActionText}
      />
    );
  }

  return (
    <FooterContainer>
      <Button
        textColor={colors.gray.secondary}
        onPress={secondaryAction}
        isDisabled={isSubmitting}
        text={secondaryActionText}
        style={{backgroundColor: colors.white, minWidth: 120}}
      />
      <Space width={16} />
      <Button
        onPress={primaryAction}
        isDisabled={isDisabled}
        isSubmitting={isSubmitting}
        text={primaryActionText}
        iconLeft={Icon.Check}
        style={{minWidth: 120}}
      />
    </FooterContainer>
  );
};

const FooterMobile = ({isDisabled, isSubmitting, primaryAction, primaryActionText}) => {
  return (
    <FooterContainer style={{paddingHorizontal: 16, paddingBottom: 16}}>
      <Button
        onPress={primaryAction}
        isDisabled={isDisabled}
        isSubmitting={isSubmitting}
        text={primaryActionText}
        iconLeft={Icon.Check}
        isWidthOfContainer
        isResponsive
        style={{flex: 1}}
      />
    </FooterContainer>
  );
};

const Drawer = ({
  isOpen,
  handleClose,
  width,
  shouldCloseOnClickOutside,
  isLeft,
  isAnimated,
  children,
}) => {
  const responsive = useResponsive();
  return (
    <BaseDrawer
      isOpen={isOpen}
      onClose={handleClose}
      shouldCloseOnClickOutside={shouldCloseOnClickOutside}
      position={isLeft ? BaseDrawer.POSITIONS.LEFT : BaseDrawer.POSITIONS.RIGHT}
      isAnimated={isAnimated}
    >
      <Container
        responsive={responsive}
        width={width}
        style={{
          transitionProperty: 'width',
          transitionDuration: '0.3s',
          transitionTimingFunction: 'ease',
        }}
      >
        {children}
      </Container>
    </BaseDrawer>
  );
};

Drawer.HeaderText = HeaderText;
Drawer.Text = Text;
Drawer.Header = Header;
Drawer.Body = Body;
Drawer.Footer = Footer;
Drawer.HeaderMobile = HeaderMobile;
Drawer.FooterMobile = FooterMobile;
Drawer.PreventPropagationContainer = PreventPropagationContainer;
Drawer.WIDTH = WIDTH;

// --------------------------------------------------
// Props
// --------------------------------------------------
Header.propTypes = {
  headerText: PropTypes.string,
  isClosable: PropTypes.bool,
  handleClose: PropTypes.func,
};

Header.defaultProps = {
  headerText: '',
  isClosable: false,
  handleClose: () => {},
};

Body.propTypes = {
  bodyScrollStyle: PropTypes.object,
};

Body.defaultProps = {
  bodyScrollStyle: {},
};

Footer.propTypes = {
  primaryActionText: PropTypes.string,
  secondaryActionText: PropTypes.string,
  isSubmitting: PropTypes.bool,
  primaryAction: PropTypes.func.isRequired,
  secondaryAction: PropTypes.func.isRequired,
};

Footer.defaultProps = {
  primaryActionText: 'Submit',
  secondaryActionText: 'Cancel',
  isSubmitting: false,
};

Drawer.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  width: PropTypes.number,
  shouldCloseOnClickOutside: PropTypes.bool,
  isLeft: PropTypes.bool,
  isAnimated: PropTypes.bool,
};

Drawer.defaultProps = {
  isOpen: false,
  handleClose: () => {},
  width: null,
  shouldCloseOnClickOutside: true,
  isLeft: false,
  isAnimated: true,
};

export default Drawer;
