/* eslint-disable no-case-declarations */

// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Drawer, Space, Styled, ScrollView, FileDragInput} from '@supermove/components';
import {
  useState,
  useInternet,
  useReducer,
  useMountEffect,
  useContext,
  useModal,
} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {uuid} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Callout from '@shared/design/components/Callout';
import FieldInput from '@shared/design/components/Field/FieldInput';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import UploadFileForm from '@shared/modules/File/forms/UploadFileForm';
import useUploadFileForm from '@shared/modules/File/hooks/useUploadFileForm';
import DriverInventoryContext from 'modules/DriverInventory/context/DriverInventoryContext';

const View = Styled.View`
`;

const Label = Styled.Text`
  ${Typography.Label}
`;

const DrawerContainer = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background}
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const Row = Styled.View`
  align-items: center;
  flex-direction: row;
`;

const DrawerTitle = Styled.Text`
  ${Typography.PageHeading}
`;

const LoadingIndicator = Styled.Loading`
`;

const BottomButtonContainer = Styled.View`
  background-color: ${colors.gray.background}
  padding: 16px;
  border-top-width: 1px;
  border-color: ${colors.gray.border}
`;

const Thumbnail = Styled.View`
  height: 54px;
  background-image: ${({fileUrl}) => `url(${fileUrl})`};
  background-size: contain;
  background-position: center center;  
  background-repeat: no-repeat;
  background-origin: content-box;
`;

const MobileBodyText = Styled.Text`
  ${Typography.Mobile.Body}
`;

const UploadPhotosWarningModal = ({uploadPhotosWarningModal, handleCloseDrawer}) => {
  return (
    <SmallModal isOpen={uploadPhotosWarningModal.isOpen} style={{alignItems: 'center'}}>
      <SmallModal.HeaderIcon source={Icon.InfoCircle} color={colors.orange.status} />
      <Space height={12} />
      <SmallModal.HeaderText style={{color: colors.orange.status}}>Close?</SmallModal.HeaderText>
      <Space height={12} />
      <MobileBodyText>
        You have unsaved photos that will be lost if you leave this page. Are you sure you want to
        close?
      </MobileBodyText>
      <Space height={12} />
      <SmallModal.Footer>
        <SmallModal.Button onPress={uploadPhotosWarningModal.handleClose}>Cancel</SmallModal.Button>
        <Space width={8} />
        <SmallModal.Button color={colors.orange.status} onPress={handleCloseDrawer}>
          Confirm & Close
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

const UploadPhotosButton = ({isDisabled, setFiles}) => {
  return (
    <View style={{maxHeight: '40px'}}>
      <FileDragInput
        style={{flex: 1}}
        disableDrag
        disableClick={isDisabled}
        accept={'image/*'}
        onFilesChange={(files) => setFiles(files.map((file) => ({rawFile: file, uuid: uuid()})))}
      >
        {() => (
          <SecondaryButton
            isDisabled={isDisabled}
            style={{flex: 1}}
            isWidthOfContainer
            text={'Add Photos'}
          />
        )}
      </FileDragInput>
    </View>
  );
};

// NOTE(cooper): This is where the actual file upload takes place
const PhotoUpload = ({file, organizationId, viewerId, onUploadComplete}) => {
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadFailure, setUploadFailure] = useState(false);

  const {form: uploadFileForm, handleSubmit} = useUploadFileForm({
    uploadFileForm: UploadFileForm.new({
      organizationId,
      creatorId: viewerId,
    }),
    onSuccess: ({file}) => {
      setUploadSuccess(true);
      setUploadFailure(false);
      onUploadComplete(file);
    },
    onError: (errors) => {
      console.log(errors);
      setUploadFailure(true);
      setUploadSuccess(false);
    },
  });

  // Kick off the upload when this component mounts
  useMountEffect(() => {
    // Inject these values into the form given the selected file
    uploadFileForm.setFieldValue(`uploadFileForm.requestUploadFileForm.mimetype`, file.type);
    uploadFileForm.setFieldValue(`uploadFileForm.requestUploadFileForm.filename`, file.name);
    uploadFileForm.setFieldValue(`uploadFileForm.file`, file);
    setTimeout(() => handleSubmit(), 0);
  });

  const getUploadStatusIndicator = () => {
    if (uploadSuccess) {
      return <Icon source={Icon.Check} size={16} color={colors.green.status} />;
    } else if (uploadFailure) {
      return <Icon source={Icon.ExclamationTriangle} size={16} color={colors.red.warning} />;
    } else {
      return <LoadingIndicator size={16} />;
    }
  };

  return <Label>{getUploadStatusIndicator()}</Label>;
};

const PhotoDetailsEditor = ({file, organizationId, viewerId, updateFile}) => {
  const deletePhotoModal = useModal({name: 'Delete Photo Modal', enableTracking: true});

  return (
    <React.Fragment>
      <Row>
        {file.fileId ? (
          <View style={{width: 54}}>
            <Thumbnail fileUrl={file.downloadUrl} />
          </View>
        ) : (
          <View style={{width: 54, justifyContent: 'center', alignItems: 'center'}}>
            <PhotoUpload
              file={file.rawFile}
              organizationId={organizationId}
              viewerId={viewerId}
              onUploadComplete={(uploadedFile) => {
                updateFile({
                  fileId: uploadedFile.id,
                  uuid: file.uuid,
                  filename: uploadedFile.filename,
                  downloadUrl: uploadedFile.downloadUrl,
                  description: '',
                  isDeleted: false,
                  rawFile: file.rawFile,
                });
              }}
            />
          </View>
        )}
        <Space width={8} />
        <FieldInput
          index={0}
          style={{flex: 1}}
          size={FieldInput.SIZE.MEDIUM}
          input={{
            value: file.description,
            placeholder: 'Add notes',
            disabled: !file.fileId,
            style: {flex: 1},
            onChangeText: (text) => {
              updateFile({
                ...file,
                description: text,
              });
            },
          }}
        />
        <Space width={8} />
        <TertiaryButton onPress={deletePhotoModal.handleOpen} style={{width: 50, height: 40}}>
          <Icon source={Icon.Trash} size={16} color={colors.red.warning} />
        </TertiaryButton>
      </Row>
      <DeleteModal
        isOpen={deletePhotoModal.isOpen}
        title={'Delete photo?'}
        subtitle={'This action cannot be undone.'}
        handleClose={deletePhotoModal.handleClose}
        handleDelete={() => {
          updateFile({
            ...file,
            isDeleted: true,
          });
          deletePhotoModal.handleClose();
        }}
      />
    </React.Fragment>
  );
};

const getInitialState = ({existingItemAttachments}) => {
  if (existingItemAttachments) {
    return {
      files: existingItemAttachments,
      errors: {},
    };
  }

  return {
    files: [],
    errors: {},
  };
};

const ADD_FILES = 'ADD_FILES';
const UPDATE_FILE = 'UPDATE_FILE';

const reducer = (state, action) => {
  switch (action.type) {
    case ADD_FILES:
      return {...state, files: [...state.files, ...action.payload]};
    case UPDATE_FILE:
      // Update the file at the index given the uuid (which will not change)
      const indexToUpdate = _.findIndex(state.files, {uuid: action.payload.uuid});
      const newFiles = state.files.map((file, index) => {
        if (index === indexToUpdate) {
          return action.payload;
        }
        return file;
      });
      return {...state, files: newFiles};
    default:
      return state;
  }
};

const UploadPhotosDrawer = ({
  existingItemAttachments,
  organizationId,
  isOpen,
  handleClose,
  handleSave,
}) => {
  const {isConnected} = useInternet();
  const {viewer} = useContext(DriverInventoryContext);
  const [state, dispatch] = useReducer(
    (state, action) => reducer(state, action),
    getInitialState({existingItemAttachments}),
  );

  const uploadPhotosWarningModal = useModal({name: 'Exception Warning Modal'});

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  return (
    <Drawer
      isOpen={isOpen}
      onClose={handleClose}
      position={'BOTTOM'}
      contentContainerStyle={{top: '15svh', height: '85svh'}}
      shouldCloseOnClickOutside={false}
    >
      <DrawerContainer>
        <Space height={16} />
        <Row style={{paddingHorizontal: 16}}>
          <DrawerTitle>Add Photos</DrawerTitle>
          <Space style={{flex: 1}} />
          <TertiaryButton
            onPress={() => {
              if (hasUnsavedChanges) {
                uploadPhotosWarningModal.handleOpen();
              } else {
                setHasUnsavedChanges(false);
                handleClose();
              }
            }}
            text={'Close'}
          />
        </Row>
        <Space height={16} />
        <View style={{flex: 1, paddingHorizontal: 16}}>
          <UploadPhotosButton
            isDisabled={!isConnected}
            setFiles={(files) => {
              setHasUnsavedChanges(true);
              dispatch({type: ADD_FILES, payload: files});
            }}
          />
          <Space height={16} />
          {!isConnected && (
            <React.Fragment>
              <Callout
                text={
                  'You must have internet connection to upload photos. You can take the photo directly on your device and upload later once you have internet connection.'
                }
              />
              <Space height={16} />
            </React.Fragment>
          )}
          <ScrollView style={{flex: 1}}>
            {state.files.map((file) => {
              if (file.isDeleted) {
                return null;
              }

              return (
                <React.Fragment>
                  <PhotoDetailsEditor
                    file={file}
                    organizationId={organizationId}
                    viewerId={viewer.id}
                    updateFile={(file) => {
                      setHasUnsavedChanges(true);
                      dispatch({
                        type: UPDATE_FILE,
                        payload: file,
                      });
                    }}
                  />
                  <Space height={16} />
                </React.Fragment>
              );
            })}
          </ScrollView>
        </View>
        <BottomButtonContainer>
          <Button
            style={{height: '40px'}}
            isWidthOfContainer
            text={'Save'}
            onPress={() => handleSave(state.files)}
          />
        </BottomButtonContainer>
        <UploadPhotosWarningModal
          uploadPhotosWarningModal={uploadPhotosWarningModal}
          handleCloseDrawer={() => {
            setHasUnsavedChanges(false);
            handleClose();
          }}
        />
      </DrawerContainer>
    </Drawer>
  );
};

export default UploadPhotosDrawer;
