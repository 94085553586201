// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {ScrollView, Space, Styled} from '@supermove/components';
import {useEffect, useState} from '@supermove/hooks';
import {colors} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import ErrorCallout from '@shared/design/components/Callout/ErrorCallout';
import DropdownSheetOptionsList from '@shared/design/components/DropdownInput/components/DropdownSheetOptionsList';
import DropdownSheetSectionedList from '@shared/design/components/DropdownInput/components/DropdownSheetSectionedList';
import SearchBar from '@shared/design/components/SearchBar';
import Sheet from '@shared/design/components/Sheet';

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.gray.border};
`;

const FooterContainer = Styled.View`
  padding: 16px;
`;

const formatActionsForDropdownSheet = ({actions, handleClose}) => {
  return actions.map(({text, label, onPress, actions, isLoadedAction, ...action}) => {
    return {
      ...action,
      label: label || text,
      value: text,
      handleAction: () => {
        onPress();
        !isLoadedAction && handleClose();
      },
      options: actions?.map(({text, onPress, isLoadedAction, ...action}) => {
        return {
          ...action,
          label: text,
          value: text,
          handleAction: () => {
            onPress();
            !isLoadedAction && handleClose();
          },
        };
      }),
    };
  });
};

const getOptionMatchesSearch = ({option, query, showDescriptionInOption, additionalSearchKeys}) => {
  // Return all options if query is empty
  if (!query) {
    return true;
  }
  const searchValues = [
    ...List.insertIf(typeof option.label === 'string', option.label),
    ...List.insertIf(option.menuLabel, option.menuLabel),
    ...List.insertIf(showDescriptionInOption, option.description),
    ...(additionalSearchKeys ? additionalSearchKeys.map((key) => _.get(option, key)) : []),
  ];
  const searchString = searchValues.join(' ');
  return searchString.toLowerCase().includes(query.toLowerCase());
};

const getFilteredSectionedOptions = ({
  options,
  query,
  showDescriptionInOption,
  additionalSearchKeys,
}) => {
  const sectionsWithFilteredOptions = options.reduce((result, section) => {
    const filteredOptions = section.options.filter(
      (option) =>
        !option.isHidden &&
        getOptionMatchesSearch({
          option,
          query,
          showDescriptionInOption,
          additionalSearchKeys,
        }),
    );
    return [...result, {...section, options: filteredOptions}];
  }, []);

  return sectionsWithFilteredOptions.filter((section) => _.some(section.options));
};

const getFilteredOptions = ({options, query, showDescriptionInOption, additionalSearchKeys}) => {
  const isSectionedList = options[0]?.options;
  if (isSectionedList) {
    return getFilteredSectionedOptions({
      options,
      query,
      showDescriptionInOption,
      additionalSearchKeys,
    });
  }

  return options.filter(
    (option) =>
      !option.isHidden &&
      getOptionMatchesSearch({
        option,
        query,
        showDescriptionInOption,
        additionalSearchKeys,
      }),
  );
};

const DropdownSheet = ({
  isOpen,
  headerText,
  options,
  name,
  value,
  onChangeValue,
  setFieldValue,
  onInputChange,
  isLoading,
  isMultiSelect,
  isSearchable,
  showDescriptionInOption,
  additionalSearchKeys,
  handleClose,
  HeaderRightComponent,
  handleSubmit,
  handleSubmitText,
  isSubmitting,
  isDisabled,
  errorMessage,
  NoResultsComponent,
  noResultsComponentProps,
}) => {
  const isSectioned = !!options[0]?.options;
  const [query, setQuery] = useState('');
  const filteredOptions = getFilteredOptions({
    options,
    query,
    showDescriptionInOption,
    additionalSearchKeys,
  });

  useEffect(() => {
    setQuery('');
  }, [isOpen, setQuery]);

  return (
    <Sheet
      isOpen={isOpen}
      handleClose={handleClose}
      headerText={headerText}
      isFixedHeight={isSearchable}
      HeaderRightComponent={HeaderRightComponent}
    >
      <ScrollView contentContainerStyle={{paddingHorizontal: 16}}>
        <Space height={8} />
        {isSearchable && (
          <React.Fragment>
            <SearchBar
              isResponsive
              isClearable
              style={{flex: 1}}
              isLoading={isLoading}
              onChangeText={(text) => {
                setQuery(text);
                // {action: 'input-change'} is what our DropdownInput component passes
                // in to the onInputChange to indicate that the search input has changed.
                onInputChange(text, {action: 'input-change'});
              }}
            />
            <Space height={16} />
          </React.Fragment>
        )}
        {isSectioned ? (
          <DropdownSheetSectionedList
            options={filteredOptions}
            name={name}
            value={value}
            showDescriptionInOption={showDescriptionInOption}
            setFieldValue={setFieldValue}
            onChangeValue={onChangeValue}
            handleClose={handleClose}
            isMultiSelect={isMultiSelect}
            query={query}
            NoResultsComponent={NoResultsComponent}
            noResultsComponentProps={noResultsComponentProps}
          />
        ) : (
          <DropdownSheetOptionsList
            options={filteredOptions}
            name={name}
            value={value}
            showDescriptionInOption={showDescriptionInOption}
            setFieldValue={setFieldValue}
            onChangeValue={onChangeValue}
            handleClose={handleClose}
            isMultiSelect={isMultiSelect}
            query={query}
            NoResultsComponent={NoResultsComponent}
            noResultsComponentProps={noResultsComponentProps}
          />
        )}
        <Space height={16} />
      </ScrollView>
      {errorMessage && (
        <FooterContainer>
          <ErrorCallout text={errorMessage} />
        </FooterContainer>
      )}
      {handleSubmit && (
        <React.Fragment>
          <Line />
          <FooterContainer>
            <Button
              onPress={handleSubmit}
              text={handleSubmitText}
              isSubmitting={isSubmitting}
              isDisabled={isDisabled}
              isResponsive
              isWidthOfContainer
            />
          </FooterContainer>
        </React.Fragment>
      )}
    </Sheet>
  );
};

DropdownSheet.formatActionsForDropdownSheet = formatActionsForDropdownSheet;
DropdownSheet.DoneButton = Sheet.DoneButton;

// --------------------------------------------------
// Props
// --------------------------------------------------
DropdownSheet.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      handleAction: PropTypes.func,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
          handleAction: PropTypes.func,
        }),
      ),
    }),
  ),
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  ]),
  onChangeValue: PropTypes.func,
  setFieldValue: PropTypes.func,
  onInputChange: PropTypes.func,
  isLoading: PropTypes.bool,
  isMultiSelect: PropTypes.bool,
  isSearchable: PropTypes.bool,
  showDescriptionInOption: PropTypes.bool,
  additionalSearchKeys: PropTypes.arrayOf(PropTypes.string),
  handleClose: PropTypes.func.isRequired,
  HeaderRightComponent: PropTypes.func,
  NoResultsComponent: PropTypes.func,
  noResultsComponentProps: PropTypes.object,
};

DropdownSheet.defaultProps = {
  options: [],
  name: '',
  value: '',
  onChangeValue: () => {},
  setFieldValue: () => {},
  onInputChange: () => {},
  isLoading: false,
  isMultiSelect: false,
  isSearchable: true,
  showDescriptionInOption: false,
  additionalSearchKeys: [],
  HeaderRightComponent: null,
  NoResultsComponent: null,
  noResultsComponentProps: {},
};

export default DropdownSheet;
