const _new = ({
  projectId,
  jobId,
  roomId,
  inventoryId,
  attachmentCategoryKinds,
  description,
  isVisibleToCrew,
}) => ({
  fileId: null,
  jobId,
  projectId,
  roomId,
  inventoryId,
  attachmentCategoryKinds,
  description,
  isVisibleToCrew: isVisibleToCrew || false,
});

const toForm = ({
  fileId,
  jobId,
  projectId,
  roomId,
  inventoryId,
  attachmentCategoryKinds,
  description,
  isVisibleToCrew,
}) => ({
  fileId,
  jobId,
  projectId,
  roomId,
  inventoryId,
  attachmentCategoryKinds,
  description,
  isVisibleToCrew,
});

const toMutation = ({
  fileId,
  jobId,
  projectId,
  roomId,
  inventoryId,
  attachmentCategoryKinds,
  description,
  isVisibleToCrew,
}) => ({
  fileId,
  jobId,
  projectId,
  roomId,
  inventoryId,
  attachmentCategoryKinds,
  description,
  isVisibleToCrew,
});

const CreateAttachmentForm = {
  new: _new,
  toForm,
  toMutation,
};

export default CreateAttachmentForm;
