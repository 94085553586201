// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useState} from '@supermove/hooks';
import FieldValueExpandable from '@supermove/manager/src/modules/Project/V2/Show/Blocks/components/FieldValueExpandable';
import {colors, Typography} from '@supermove/styles';
import {Phone} from '@supermove/utils';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import FieldValue from '@shared/design/components/Field/FieldValue';

const JobContainer = Styled.View`
  width: 100%;
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 4px;
  background-color: ${colors.white};
`;

const Container = Styled.View`
  padding: 16px;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.gray.border};
`;

const Text = Styled.Text`
  ${Typography.Mobile.Body}
`;

const Label = Styled.Text`
  ${Typography.Mobile.Label}
`;

const Subheading = Styled.Text`
  ${Typography.Mobile.Subheading}
`;

const CREW_NOTES = 'additionalNotes';

const DispatchBlock = ({organization, job}) => {
  const [truncated, setTruncated] = useState({
    [CREW_NOTES]: true,
  });
  const [enabledToggle, setEnabledToggle] = useState({
    [CREW_NOTES]: false,
  });
  const crew = job.crews.find((crew) => crew.organization.id === organization.id);

  return (
    <JobContainer>
      <Container>
        <Subheading>Dispatch</Subheading>
      </Container>
      <Line />
      <Container>
        <Row>
          <Label>Trucks</Label>
          <Space width={8} />
          <SecondaryButton
            iconLeft={Icon.Truck}
            iconSize={10}
            text={`${crew.numberOfAssignedTrucks}/${crew.numberOfRequiredTrucks}`}
            isSmall
            textColor={colors.gray.secondary}
            style={{paddingHorizontal: 8, borderColor: colors.gray.border}}
            onPress={() => {}}
          />
        </Row>
        {_.map(crew.jobTrucks, (jobTruck) => {
          return (
            <React.Fragment>
              <Space height={4} />
              <Text>{`${jobTruck.truck.name} (${jobTruck.truck.size})`}</Text>
            </React.Fragment>
          );
        })}
        <Space height={12} />
        <Row>
          <Label>Crew</Label>
          <Space width={8} />
          <SecondaryButton
            iconLeft={Icon.User}
            iconSize={10}
            text={`${crew.jobUsers.length}/${crew.numberOfRequiredMovers}`}
            isSmall
            textColor={colors.gray.secondary}
            style={{paddingHorizontal: 8, borderColor: colors.gray.border}}
            onPress={() => {}}
          />
        </Row>
        {_.map(crew.jobUsers, (jobUser, index) => {
          return (
            <React.Fragment key={index}>
              <Space height={4} />
              <Row>
                <Text>{jobUser.user.fullName}</Text>
                <Space style={{flex: 1}} />
                <FieldValue
                  size={FieldValue.SIZE.MEDIUM}
                  value={Phone.display(jobUser.user.phoneNumber)}
                  empty={'None'}
                  link={Phone.createUrl(jobUser.user.phoneNumber)}
                />
              </Row>
              <Space height={4} />
              <Text style={{color: colors.gray.secondary}}>{jobUser.position}</Text>
            </React.Fragment>
          );
        })}
        <Space height={12} />
        <Label>Crew Notes</Label>
        <Space height={4} />
        <FieldValueExpandable
          size={FieldValue.SIZE.MEDIUM}
          data={job}
          property={CREW_NOTES}
          numberOfLines={2}
          isTruncated={truncated[CREW_NOTES]}
          isEnabledToggle={enabledToggle[CREW_NOTES]}
          setTruncated={setTruncated}
          setEnabledToggle={setEnabledToggle}
        />
      </Container>
    </JobContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DispatchBlock.fragment = gql`
  fragment DispatchBlock on Job {
    id
    additionalNotes
    crews {
      id
      numberOfAssignedTrucks
      numberOfRequiredTrucks
      numberOfRequiredMovers
      organization {
        id
      }
      jobTrucks {
        id
        truck {
          id
          name
          size
        }
      }
      jobUsers {
        id
        position
        user {
          id
          fullName
          phoneNumber
        }
      }
    }
  }
`;

DispatchBlock.organizationFragment = gql`
  fragment DispatchBlock_organizationFragment on Organization {
    id
  }
`;

export default DispatchBlock;
