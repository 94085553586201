// Libraries
import React from 'react';

// Supermove
import {Space, Styled, SupermoveLogo} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation, useQuery} from '@supermove/hooks';
import {Storage} from '@supermove/sdk';
import {Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import FieldInput from '@shared/design/components/Field/FieldInput';
import MoverAppLoginForm from '@shared/modules/User/forms/MoverAppLoginForm';
import useMoverAppLoginMutation from '@shared/modules/User/hooks/useMoverAppLoginMutation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';

const Content = Styled.View`
  flex: 1;
  width: 100%;
  max-width: 600px;
  padding-horizontal: 24px;
  flex-direction: column;
  justify-content: center;
`;

const BodyText = Styled.Text`
  ${Typography.Body}
`;

const MoverAppLoginPageContent = ({phoneNumber, accountsCount}) => {
  const {form, handleSubmit, submitting} = useMoverAppLoginMutation({
    moverAppLoginForm: MoverAppLoginForm.new({phoneNumber}),
    onSuccess: async ({token, user}) => {
      await Storage.setItem('token', token);
      await Storage.setItem('userId', user.id);

      // Hard refresh the page
      if (accountsCount > 1) {
        window.location.href = '/select-account';
      } else {
        window.location.href = '/calendar';
      }
    },
    onError: (errors) => {
      console.log(errors);
    },
  });

  return (
    <Content>
      <SupermoveLogo.Name color={SupermoveLogo.COLORS.BLACK} size={36} />
      <Space height={24} />
      <BodyText>Enter your the code that was sent to your phone.</BodyText>
      <Space height={24} />
      <FieldInput
        {...form}
        name={'moverAppLoginForm.phoneNumber'}
        label={'Phone Number'}
        size={FieldInput.SIZE.MEDIUM}
        input={{
          disabled: true,
          placeholder: 'Enter your phone number',
          keyboardType: 'numeric',
          setFieldValue: form.setFieldValue,
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        name={'moverAppLoginForm.code'}
        label={'Code'}
        size={FieldInput.SIZE.MEDIUM}
        input={{
          placeholder: 'Enter your login code',
          keyboardType: 'numeric',
          setFieldValue: form.setFieldValue,
        }}
      />
      <Space height={56} />
      <Button isWidthOfContainer text={'Log In'} onPress={handleSubmit} isSubmitting={submitting} />
      <Space height={80} />
    </Content>
  );
};

const MoverAppLoginPage = () => {
  const {params} = useNavigation();
  const {phoneNumber} = params;
  const {loading, data} = useQuery(MoverAppLoginPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {phoneNumber},
  });

  if (loading) return <PageLoadingIndicator />;

  return (
    <MoverAppLoginPageContent
      phoneNumber={phoneNumber}
      accountsCount={data.employeeAccountsCountForPhoneNumber}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
MoverAppLoginPage.query = gql`
  query MoverAppLoginPage($phoneNumber: String!) {
    ${gql.query}
    employeeAccountsCountForPhoneNumber(phoneNumber: $phoneNumber)
  }
`;

export default MoverAppLoginPage;
