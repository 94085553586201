// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Styled, Popover, Space, ScrollView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useDebouncedCallback,
  useDrawer,
  useEffect,
  usePopover,
  useModal,
  useState,
  useToast,
  useContext,
  useNavigation,
  useUrlFilters,
} from '@supermove/hooks';
import ResponsivePopover from '@supermove/manager/src/modules/App/components/ResponsivePopover';
import {Inventory} from '@supermove/models';
import {Typography, colors} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import SuccessCallout from '@shared/design/components/Callout/SuccessCallout';
import EmptyState from '@shared/design/components/EmptyState';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import SearchBar from '@shared/design/components/SearchBar';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import ItemTagItemKind from '@shared/modules/Inventory/enums/ItemTagItemKind';
import ItemFormV2 from '@shared/modules/Inventory/forms/ItemFormV2';
import RoomItemsForm from '@shared/modules/Inventory/forms/RoomItemsForm';
import FilterRoomItemsDrawer from 'modules/DriverInventory/Rooms/FilterRoomItemsDrawer';
import ItemsTable from 'modules/DriverInventory/components/ItemsTable';
import LastSavedInfo from 'modules/DriverInventory/components/LastSavedInfo';
import DriverInventoryContext from 'modules/DriverInventory/context/DriverInventoryContext';

const BorderedContainer = Styled.View`
  border-radius: 4px;
  border-color: ${colors.gray.border};
  border-width: 1px;
  background-color: ${colors.white};
`;

const Container = Styled.View`
  padding-top: 16px;
  height: 100%;
  background-color: ${colors.gray.background}
`;

const ContentContainer = Styled.View`
  flex: 1;
  padding-horizontal: 16px;
`;

const RoomDetails = Styled.View`
  padding-horizontal: 16px;
  flex-direction: row;
`;

const FieldValueContainer = Styled.View`
  flex: 1;
  padding-right: 4px;
  padding-left: ${(props) => props.paddingLeft};
  border-left-width: ${(props) => props.showBorder && '1px'};
  border-left-color: ${(props) => (props.showBorder ? colors.gray.disabled : `none`)}
`;

const ItemContainer = Styled.View`
  width: 44px;
  padding-right: 4px;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const TitleText = Styled.Text`
  ${Typography.PageHeading}
`;

const BodyText = Styled.Text`
  ${Typography.Body}
  color: ${(props) => props.color}
`;

const MenuItemText = Styled.Text`
  ${Typography.Body}
  padding-vertical: 12px;
  padding-horizontal: 12px;
  color: ${(props) => props.color};
`;

const MenuItemTouchable = Styled.Touchable``;

const ColorDot = Styled.View`
  height: 8px;
  width: 8px;
  border-radius: 4px;
  border-width: 1px;
  border-color: ${({borderColor, color}) => borderColor || color};
  background-color: ${({color}) => color};
`;

const BottomButtonContainer = Styled.View`
  background-color: ${colors.gray.background}
  padding: 16px;
  border-top-width: 1px;
  border-color: ${colors.gray.border}
`;

const EmptyStateContainer = Styled.View`
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 4px;
  background-color: ${colors.white};
`;

const ItalicText = Styled.Text`
  ${Typography.Micro}
  font-style: italic;
  color: ${colors.gray.secondary};
`;

const HeaderSubtitleText = Styled.Text`
  ${Typography.Micro}
`;

const InventoryMetricValueText = Styled.Text`
  ${Typography.Micro}
  color: ${colors.gray.primary};
`;

const ActionMenuItem = ({onPress, isDisabled, children}) => {
  return (
    <React.Fragment>
      <MenuItemTouchable onPress={onPress} disabled={isDisabled}>
        <MenuItemText color={isDisabled ? colors.gray.tertiary : colors.gray.primary}>
          {children}
        </MenuItemText>
      </MenuItemTouchable>
      <Space height={2} />
    </React.Fragment>
  );
};

const RoomActionsPopover = ({
  popover,
  room,
  urlFilters,
  navigator,
  deleteRoomModal,
  cannotDeleteRoomModal,
}) => {
  return (
    <Popover
      placement={Popover.Positions.BottomStart}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
      offset={[0, 4]}
    >
      <ResponsivePopover.StaticContainer width={200}>
        <Space height={8} />
        <ActionMenuItem
          onPress={() => {
            clearUrlFilters({urlFilters});
            navigator.push('EditDriverInventoryRoom', {
              roomUuid: room.uuid,
            });
          }}
        >
          Edit room
        </ActionMenuItem>
        <ActionMenuItem
          onPress={() => {
            popover.handleClose();
            if (room.itemForms.length) {
              cannotDeleteRoomModal.handleOpen();
            } else {
              deleteRoomModal.handleOpen();
            }
          }}
        >
          Delete room
        </ActionMenuItem>
        <Space height={8} />
      </ResponsivePopover.StaticContainer>
    </Popover>
  );
};

const RoomMetric = ({label, value, numberOfLines}) => {
  const height = numberOfLines > 1 ? numberOfLines * 9 : 4;
  return (
    <>
      <HeaderSubtitleText>{label}</HeaderSubtitleText>
      <Space height={height} />
      <InventoryMetricValueText numberOfLines={1}>{value}</InventoryMetricValueText>
      <Space height={height} />
    </>
  );
};

const RoomSummaryCard = ({roomItemsInfo, roomLotAndRangeInfo}) => {
  const numberOfLines = _.size(roomLotAndRangeInfo);

  return (
    <BorderedContainer>
      <Space height={16} />
      <RoomDetails>
        <ItemContainer>
          <RoomMetric
            label={'ITEMS'}
            value={roomItemsInfo.itemCount}
            numberOfLines={numberOfLines}
          />
        </ItemContainer>
        <FieldValueContainer>
          <RoomMetric
            label={'VOLUME'}
            value={`${roomItemsInfo.volume} cu ft`}
            numberOfLines={numberOfLines}
          />
        </FieldValueContainer>
        <FieldValueContainer>
          <RoomMetric
            label={'WEIGHT'}
            value={`${roomItemsInfo.weight} lbs`}
            numberOfLines={numberOfLines}
          />
        </FieldValueContainer>
        <FieldValueContainer showBorder paddingLeft={'20px'}>
          <HeaderSubtitleText>{'LOT'}</HeaderSubtitleText>
          {_.size(roomLotAndRangeInfo) === 0 && <ItalicText>None</ItalicText>}
          {roomLotAndRangeInfo.map((info, index) => (
            <React.Fragment key={index}>
              <Space height={4} />
              <Row>
                {info.color ? (
                  <>
                    <ColorDot
                      color={info.color}
                      borderColor={
                        _.toLower(info.color) === _.toLower(colors.white) && colors.gray.secondary
                      }
                    />
                    <Space width={4} />
                  </>
                ) : (
                  <Space width={12} />
                )}
                {info.lotNumber ? (
                  <InventoryMetricValueText numberOfLines={1}>
                    {info.lotNumber}
                  </InventoryMetricValueText>
                ) : (
                  <ItalicText>None</ItalicText>
                )}
              </Row>
            </React.Fragment>
          ))}
        </FieldValueContainer>
        <FieldValueContainer>
          <HeaderSubtitleText>{'RANGE'}</HeaderSubtitleText>
          {_.size(roomLotAndRangeInfo) === 0 && <ItalicText>None</ItalicText>}
          {roomLotAndRangeInfo.map((info, index) => (
            <React.Fragment key={index}>
              <Space height={4} />
              <Row key={index}>
                <InventoryMetricValueText numberOfLines={1}>{info.range}</InventoryMetricValueText>
              </Row>
            </React.Fragment>
          ))}
        </FieldValueContainer>
      </RoomDetails>
      <Space height={12} />
    </BorderedContainer>
  );
};

const RoomSection = ({
  form,
  roomItemsInfo,
  roomLotAndRangeInfo,
  room,
  roomActionsPopover,
  urlFilters,
  navigator,
  refetch,
}) => {
  const deleteRoomModal = useModal({name: 'Delete Driver Inventory Room Modal'});
  const cannotDeleteRoomModal = useModal({name: 'Cannot Delete Driver Inventory Room Modal'});
  const deleteSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: `${room.name} deleted.`,
    isClosable: true,
  });

  return (
    <>
      <Row style={{alignItems: 'flex-start'}}>
        <TitleText>{room.name}</TitleText>
        <Space style={{flex: 1}} />
        <Popover.Content innerRef={roomActionsPopover.ref}>
          <SecondaryButton
            iconLeft={Icon.EllipsisV}
            iconSize={14}
            onPress={roomActionsPopover.handleToggle}
          />
        </Popover.Content>
      </Row>
      <Space height={8} />
      <BodyText>{room.description}</BodyText>
      <Space height={16} />
      <RoomSummaryCard roomItemsInfo={roomItemsInfo} roomLotAndRangeInfo={roomLotAndRangeInfo} />
      <RoomActionsPopover
        popover={roomActionsPopover}
        room={room}
        urlFilters={urlFilters}
        navigator={navigator}
        deleteRoomModal={deleteRoomModal}
        cannotDeleteRoomModal={cannotDeleteRoomModal}
      />
      <DeleteModal
        isOpen={deleteRoomModal.isOpen}
        title={'Delete room?'}
        subtitle={'This action cannot be undone.'}
        handleClose={deleteRoomModal.handleClose}
        handleDelete={() => {
          const roomFormIndex = _.findIndex(form.values.inventoryRoomsForm.roomItemsForms, {
            uuid: room.uuid,
          });
          form.setFieldValue(`inventoryRoomsForm.roomItemsForms.${roomFormIndex}.isDeleted`, true);
          form.setFieldValue(`inventoryRoomsForm.roomItemsForms.${roomFormIndex}.isDirty`, true);
          deleteRoomModal.handleClose();
          deleteSuccessToast.handleToast();
          clearUrlFilters({urlFilters});
          navigator.push('ShowDriverInventory');
          refetch();
        }}
      />
      <SmallModal isOpen={cannotDeleteRoomModal.isOpen} style={{alignItems: 'center'}}>
        <SmallModal.HeaderIcon source={Icon.InfoCircle} color={colors.orange.status} />
        <Space height={12} />
        <SmallModal.HeaderText style={{color: colors.orange.status}}>
          Cannot delete room
        </SmallModal.HeaderText>
        <Space height={12} />
        <BodyText>
          This room cannot be deleted because it contains items. Reassign the items to a different
          room if you still want to delete it.
        </BodyText>
        <Space height={12} />
        <SmallModal.Button color={colors.orange.status} onPress={cannotDeleteRoomModal.handleClose}>
          Okay
        </SmallModal.Button>
      </SmallModal>
    </>
  );
};

const clearUrlFilters = ({urlFilters}) => {
  // Clear param filters before navigating to a different page
  urlFilters.handleUpdate({
    query: null,
    minItemNumber: null,
    maxItemNumber: null,
    lotNumbers: null,
    itemTags: null,
    exceptionTypes: null,
    showVoidedLabels: null,
    isOpen: null,
  });
};

const getItemTypeMatchesSearch = ({searchTerm, item}) => {
  const colorMatchSearch = () => {
    const colorOptions = Inventory.getColorOptions();
    const colorOption = colorOptions.find((option) => option.value === item.color);
    return colorOption ? colorOption.label.toLowerCase().includes(searchTerm.toLowerCase()) : false;
  };

  const itemNumberSearch = item.itemNumber.toLowerCase().includes(searchTerm.toLowerCase());
  const lotNumberSearch = item.lotNumber
    ? item.lotNumber.toLowerCase().includes(searchTerm.toLowerCase())
    : false;
  const nameSearch = item.name.toLowerCase().includes(searchTerm.toLowerCase());

  const hasSearchTerm =
    searchTerm && (itemNumberSearch || lotNumberSearch || colorMatchSearch() || nameSearch);

  return hasSearchTerm || !searchTerm;
};

const getFilteredItemTypes = ({filters, itemForms}) => {
  const searchTerm = filters.query || '';
  const {
    minItemNumber,
    maxItemNumber,
    lotNumbers,
    itemTags,
    exceptionTypes,
    showVoidedLabels,
  } = filters;
  const items = _.orderBy(
    itemForms,
    [
      (item) => !item.lotNumber && !item.color,
      'lotNumber',
      'color',
      (item) => _.toNumber(item.itemNumber),
    ],
    ['asc', 'asc', 'asc', 'asc'],
  );

  return _.filter(items, (item) => {
    return (
      ItemFormV2.getItemTypeMatchesMinItemNumber({minItemNumber, itemForm: item}) &&
      ItemFormV2.getItemTypeMatchesMaxItemNumber({maxItemNumber, itemForm: item}) &&
      ItemFormV2.getItemTypeMatchesLot({lotNumbers, itemForm: item}) &&
      ItemFormV2.getItemTypeMatchesTagOrException({
        tagsOrExceptions: itemTags,
        itemTagItemKind: ItemTagItemKind.ITEM_TAG,
        itemForm: item,
      }) &&
      ItemFormV2.getItemTypeMatchesTagOrException({
        tagsOrExceptions: exceptionTypes,
        itemTagItemKind: ItemTagItemKind.EXCEPTION,
        itemForm: item,
      }) &&
      ItemFormV2.getItemTypeShowVoidedLabel({showVoidedLabels, itemForm: item}) &&
      getItemTypeMatchesSearch({searchTerm, item})
    );
  });
};

const getGroupedItems = (items) => {
  return _.groupBy(items, (item) => {
    // NOTE(cassie): This replaces null with tilde, which is after all alphanumeric characters. This will put null groups last.
    const lotNumber = item.lotNumber ?? '~';
    const color = item.color ?? '~';
    return `${lotNumber}-${color}`;
  });
};

const SearchInput = ({urlFilters, params}) => {
  const handleUpdateQuery = useDebouncedCallback((text) => {
    urlFilters.handleUpdate({query: text});
  }, 500);
  return (
    <SearchBar
      placeholder={'Search inventory'}
      iconColor={colors.gray.tertiary}
      containerStyle={{flex: 1}}
      style={{width: '100%'}}
      onChangeText={handleUpdateQuery}
      defaultValue={params.query}
      isClearable
      isResponsive
    />
  );
};

const InventorySection = ({room, urlFilters, navigator, params}) => {
  const {itemForms} = room;
  const [filteredInventoryItems, setFilteredInventoryItems] = useState([]);
  const selectItemTypeDrawer = useDrawer({name: 'Select Item Drawer', enableTracking: true});
  const filterCount = urlFilters.getFilterCount({
    filterKeys: [
      'minItemNumber',
      'maxItemNumber',
      'lotNumbers',
      'itemTags',
      'exceptionTypes',
      'showVoidedLabels',
    ],
  });
  useEffect(() => {
    setFilteredInventoryItems(
      getGroupedItems(
        getFilteredItemTypes({
          filters: urlFilters.getFilters(),
          itemForms,
        }),
      ),
    );
  }, [urlFilters, itemForms]);
  const firstGroupedItemsKey = _.first(_.keys(filteredInventoryItems));
  const lastGroupedItemsKey = _.last(_.keys(filteredInventoryItems));

  return (
    <>
      <TitleText>Inventory</TitleText>
      <Space height={16} />
      <Row>
        <SearchInput urlFilters={urlFilters} params={params} />
        <Space width={8} />
        <SecondaryButton
          onPress={() => urlFilters.handleUpdate({isOpen: true})}
          text={`(${filterCount})`}
          iconLeft={Icon.Filter}
          style={{minWidth: 65}}
          isResponsive
        />
      </Row>
      <Space height={16} />
      {_.size(filteredInventoryItems) === 0 && (
        <EmptyStateContainer>
          <EmptyState title={'No inventory added yet.'} message={'To get started, add an item.'} />
          <Space height={16} />
        </EmptyStateContainer>
      )}
      {_.map(filteredInventoryItems, (groupItems, groupKey) => {
        return (
          <ItemsTable
            key={`collapsible-${groupKey}`}
            collapsibleTableStyle={{
              borderTopStartRadius: firstGroupedItemsKey === groupKey ? 4 : 0,
              borderTopEndRadius: firstGroupedItemsKey === groupKey ? 4 : 0,
              borderBottomStartRadius: lastGroupedItemsKey === groupKey ? 4 : 0,
              borderBottomEndRadius: lastGroupedItemsKey === groupKey ? 4 : 0,
              borderBottomWidth: lastGroupedItemsKey === groupKey ? 1 : 0,
            }}
            roomUuid={room.uuid}
            navigator={navigator}
            urlFilters={urlFilters}
            groupItems={groupItems}
          />
        );
      })}
      <FilterRoomItemsDrawer
        key={selectItemTypeDrawer.key}
        urlFilters={urlFilters}
        filterCount={filterCount}
        showRoomFilter={false}
        isOpen={urlFilters.get('isOpen') === 'true'}
        handleClose={() => urlFilters.handleUpdate({isOpen: null})}
      />
    </>
  );
};

const ShowDriverInventoryRoom = () => {
  const {form, inventory, refetch} = useContext(DriverInventoryContext);
  const {navigator, params} = useNavigation();

  const room = _.find(form.values.inventoryRoomsForm.roomItemsForms, {uuid: params.roomUuid});
  const roomItemsInfo = RoomItemsForm.getInfo(room);
  const roomLotAndRangeInfo = _.orderBy(
    RoomItemsForm.getLotAndRangeInfo(room),
    ['lotNumber', 'color'],
    ['asc', 'asc'],
  );
  const roomActionsPopover = usePopover({name: 'Driver Inventory Room Actions Popover'});
  const urlFilters = useUrlFilters({
    getRoute: () => '',
    filterKeys: [
      'query',
      'minItemNumber',
      'maxItemNumber',
      'lotNumbers',
      'itemTags',
      'exceptionTypes',
      'showVoidedLabels',
      'isOpen',
    ],
  });

  return (
    <Container>
      <ContentContainer>
        <TertiaryButton
          iconLeft={Icon.AngleLeft}
          iconSize={14}
          text={'Back to Driver Inventory'}
          onPress={() => {
            clearUrlFilters({urlFilters});
            navigator.navigate('ShowDriverInventory');
            refetch();
          }}
        />
        <Space height={16} />
        <ScrollView>
          {inventory.isFinalized && (
            <React.Fragment>
              <SuccessCallout
                text={
                  'Inventory is finalized. To add more rooms and items, you must first unfinalize the inventory.'
                }
              />
              <Space height={16} />
            </React.Fragment>
          )}
          <RoomSection
            form={form}
            roomItemsInfo={roomItemsInfo}
            roomLotAndRangeInfo={roomLotAndRangeInfo}
            room={room}
            urlFilters={urlFilters}
            roomActionsPopover={roomActionsPopover}
            navigator={navigator}
            refetch={refetch}
          />
          <Space height={8} />
          <LastSavedInfo lastSyncedAt={form.values.inventoryRoomsForm.lastSyncedAt} />
          <Space height={16} />
          <InventorySection
            room={room}
            urlFilters={urlFilters}
            navigator={navigator}
            params={params}
          />
          <Space height={16} />
        </ScrollView>
      </ContentContainer>
      <BottomButtonContainer>
        <Button
          isDisabled={inventory.isFinalized}
          style={{height: '40px'}}
          isWidthOfContainer
          text={'Add Item'}
          onPress={() => {
            clearUrlFilters({urlFilters});
            navigator.push('CreateDriverInventoryItem', {
              roomUuid: room.uuid,
            });
          }}
        />
      </BottomButtonContainer>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ShowDriverInventoryRoom.fragment = gql`
  fragment ShowDriverInventoryRoom on Inventory {
    id
    isFinalized
  }
`;

export default ShowDriverInventoryRoom;
