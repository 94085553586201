// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useToast} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Toast from '@shared/design/components/Toast';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const OptionButton = Styled.ButtonV2`
`;

const OptionLabel = Styled.Text`
  ${({vars}) => (vars.isSelected ? Typography.Mobile.Label : Typography.Mobile.Body)}
  color: ${({color}) => color};
`;

const OptionDescription = Styled.Text`
${({vars}) => (vars.isSelected ? Typography.Mobile.MicroLabel : Typography.Mobile.Micro)}
  color: ${({disabled, color}) => (disabled ? colors.gray.tertiary : color)};
`;

const ActivityIndicator = Styled.Loading`
`;

const getOptionLabelColor = ({isDisabled, isLoading, isSelected}) => {
  if (isDisabled || isLoading) {
    return colors.gray.tertiary;
  }
  if (isSelected) {
    return colors.blue.interactive;
  }
  return colors.gray.primary;
};

const DropdownSheetOption = ({
  option,
  style,
  showDescriptionInOption,
  isSelected,
  handleOption,
}) => {
  const {handleAction, isDisabled, label, description, color, isLoading, tooltip} = option;
  const {handleToast} = useToast({
    ToastComponent: Toast,
    message: tooltip,
  });

  return (
    <OptionButton
      onPress={() => {
        if (tooltip) {
          handleToast();
        }
        if (!isDisabled) {
          if (handleAction) {
            handleAction();
          } else {
            handleOption();
          }
        }
      }}
      disabled={(isDisabled && !tooltip) || isLoading}
      style={style}
    >
      <Space height={12} />
      <Row>
        {isLoading && (
          <React.Fragment>
            <ActivityIndicator size={'small'} color={colors.blue.interactive} />
            <Space width={8} />
          </React.Fragment>
        )}
        <OptionLabel
          vars={{isSelected}}
          disabled={isDisabled}
          color={
            color && !isDisabled && !isLoading
              ? color
              : getOptionLabelColor({isDisabled, isLoading, isSelected})
          }
        >
          {label}
        </OptionLabel>
        {isSelected && (
          <React.Fragment>
            <Space style={{flex: 1, minWidth: 8}} />
            <Icon
              source={Icon.CheckCircle}
              size={18}
              color={isDisabled ? colors.gray.tertiary : colors.blue.interactive}
            />
          </React.Fragment>
        )}
      </Row>
      {showDescriptionInOption && (
        <React.Fragment>
          <Space height={4} />
          <OptionDescription
            disabled={isDisabled}
            vars={{isSelected}}
            color={isSelected ? colors.blue.interactive : colors.gray.secondary}
          >
            {description}
          </OptionDescription>
        </React.Fragment>
      )}
      <Space height={12} />
    </OptionButton>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
DropdownSheetOption.propTypes = {
  option: PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    handleAction: PropTypes.func,
    isDisabled: PropTypes.bool,
  }).isRequired,
  showDescriptionInOption: PropTypes.bool,
  isSelected: PropTypes.bool,
  handleOption: PropTypes.func,
  style: PropTypes.object,
};

DropdownSheetOption.defaultProps = {
  showDescriptionInOption: false,
  isSelected: false,
  handleOption: () => {},
  style: null,
};

export default DropdownSheetOption;
