// Supermove
import {gql} from '@supermove/graphql';
import {Datetime, withFragment} from '@supermove/utils';

const _getDisplayTime = (datetime, format) => {
  if (!datetime) {
    return 'TBD';
  }
  return Datetime.toDisplayTime(Datetime.fromDatetime(datetime, true), format);
};

const getDisplayRangeFrom = withFragment(
  (timesheetBlock, format) => {
    return _getDisplayTime(timesheetBlock.rangeFrom, format);
  },
  gql`
    fragment TimesheetBlock_getDisplayRangeFrom on TimesheetBlock {
      id
      rangeFrom
    }
  `,
);

const getDisplayRangeTo = withFragment(
  (timesheetBlock, format) => {
    return _getDisplayTime(timesheetBlock.rangeTo, format);
  },
  gql`
    fragment TimesheetBlock_getDisplayRangeTo on TimesheetBlock {
      id
      rangeTo
    }
  `,
);

const getDisplayRange = withFragment(
  (timesheetBlock, format) => {
    return `${getDisplayRangeFrom(timesheetBlock, format)} - ${getDisplayRangeTo(
      timesheetBlock,
      format,
    )}`;
  },
  gql`
    ${getDisplayRangeFrom.fragment}
    ${getDisplayRangeTo.fragment}

    fragment TimesheetBlock_getDisplayRange on TimesheetBlock {
      id
      ...TimesheetBlock_getDisplayRangeFrom
      ...TimesheetBlock_getDisplayRangeTo
    }
  `,
);

const getMinutes = withFragment(
  (timesheetBlock) => {
    if (timesheetBlock.rangeTo) {
      const fromMoment = Datetime.fromDatetime(timesheetBlock.rangeFrom, true);
      const toMoment = Datetime.fromDatetime(timesheetBlock.rangeTo, true);
      return toMoment.diff(fromMoment, 'minutes');
    }
    return 0;
  },
  gql`
    fragment TimesheetBlock_getMinutes on TimesheetBlock {
      id
      rangeFrom
      rangeTo
    }
  `,
);

const TimesheetBlock = {
  getDisplayRangeFrom,
  getDisplayRangeTo,
  getDisplayRange,
  getMinutes,
};

export default TimesheetBlock;
