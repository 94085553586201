// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Styled, Space, DropdownInput, ScrollView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useReducer, useToast, useScrollView, useContext, useNavigation} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import SuccessCallout from '@shared/design/components/Callout/SuccessCallout';
import FieldInput from '@shared/design/components/Field/FieldInput';
import ErrorToast from '@shared/design/components/Toast/ErrorToast';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import RoomItemsForm from '@shared/modules/Inventory/forms/RoomItemsForm';
import DriverInventoryContext from 'modules/DriverInventory/context/DriverInventoryContext';

const Container = Styled.View`
  padding-top: 16px;
  height: 100%;
  background-color: ${colors.gray.background}
`;

const ContentContainer = Styled.View`
  flex: 1;
  padding-horizontal: 16px;
`;

const TitleText = Styled.Text`
  ${Typography.PageHeading}
`;

const BottomButtonContainer = Styled.View`
  background-color: ${colors.gray.background}
  padding: 16px;
  border-top-width: 1px;
  border-color: ${colors.gray.border}
`;

const validateRoom = (room) => {
  const errors = {};
  if (!room.name) {
    errors.name = 'Please enter a room name.';
  }
  return errors;
};

const addRoom = ({form, inventoryId, room, onSuccess, onError}) => {
  const errors = validateRoom(room);

  if (!_.isEmpty(errors)) {
    onError(errors);
  } else {
    const roomItemsForm = RoomItemsForm.new({
      inventoryId,
      roomTypeId: room.roomTypeId,
      name: room.name,
      description: room.description,
      primaryCategoryId: room.primaryCategoryId,
    });
    form.setFieldValue('inventoryRoomsForm.roomItemsForms', [
      ...form.values.inventoryRoomsForm.roomItemsForms,
      roomItemsForm,
    ]);
    onSuccess(roomItemsForm);
  }
};

const editRoom = ({form, roomUuid, room, onSuccess, onError}) => {
  const errors = validateRoom(room);

  if (!_.isEmpty(errors)) {
    onError(errors);
  } else {
    const existingRoomFormIndex = _.findIndex(form.values.inventoryRoomsForm.roomItemsForms, {
      uuid: roomUuid,
    });
    form.setFieldValue(
      `inventoryRoomsForm.roomItemsForms.${existingRoomFormIndex}.roomTypeId`,
      room.roomTypeId,
    );
    form.setFieldValue(
      `inventoryRoomsForm.roomItemsForms.${existingRoomFormIndex}.name`,
      room.name,
    );
    form.setFieldValue(
      `inventoryRoomsForm.roomItemsForms.${existingRoomFormIndex}.description`,
      room.description,
    );
    form.setFieldValue(
      `inventoryRoomsForm.roomItemsForms.${existingRoomFormIndex}.primaryCategoryId`,
      room.primaryCategoryId,
    );
    form.setFieldValue(`inventoryRoomsForm.roomItemsForms.${existingRoomFormIndex}.isDirty`, true);
    onSuccess();
  }
};

const SET_ROOM_TYPE = 'SET_ROOM_TYPE';
const SET_ROOM_NAME = 'SET_ROOM_NAME';
const SET_ROOM_DESCRIPTION = 'SET_ROOM_DESCRIPTION';
const SET_ERRORS = 'SET_ERRORS';

const getInitialState = (existingRoom) => {
  if (existingRoom) {
    return {
      roomTypeId: existingRoom.roomTypeId,
      name: existingRoom.name,
      description: existingRoom.description,
      primaryCategoryId: existingRoom.primaryCategoryId,
      errors: {},
    };
  }

  return {
    roomTypeId: null,
    name: '',
    description: '',
    primaryCategoryId: null,
    errors: {},
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case SET_ROOM_TYPE:
      return {
        ...state,
        roomTypeId: _.toNumber(action.payload.id),
        name: action.payload.name,
        primaryCategoryId: action.payload.primaryCategoryId,
      };
    case SET_ROOM_NAME:
      return {...state, name: action.payload};
    case SET_ROOM_DESCRIPTION:
      return {...state, description: action.payload};
    case SET_ERRORS:
      return {...state, errors: action.payload};
    default:
      return state;
  }
};

/**
 * NOTE(cooper): Driver inventory is designed to operate in suboptimal network conditions.
 * Therefore this component does not make any queries or mutations as we normally would,
 * rather we use a reducer to maintain the local form state and then commit to the
 * parent form upon submission.
 */
const DriverInventoryRoomFields = () => {
  const {form, inventory, refetch} = useContext(DriverInventoryContext);
  const {params, navigator} = useNavigation();

  const {roomTypes} = inventory.project.projectType.defaultDriverInventoryLibrary;
  const existingRoom = _.find(form.values.inventoryRoomsForm.roomItemsForms, {
    uuid: params.roomUuid,
  });
  const scrollView = useScrollView();
  const [state, dispatch] = useReducer(
    (state, action) => reducer(state, action),
    getInitialState(existingRoom),
  );

  const successToast = useToast({
    ToastComponent: SuccessToast,
    message: `${state.name} ${existingRoom ? 'edited' : 'added'}.`,
    isClosable: true,
  });
  const errorToast = useToast({
    ToastComponent: ErrorToast,
    message: 'Please fix errors before saving',
    isClosable: true,
  });

  return (
    <Container>
      <ContentContainer>
        <TertiaryButton
          iconLeft={Icon.AngleLeft}
          iconSize={14}
          text={`Back to ${existingRoom ? existingRoom.name : 'Driver Inventory'}`}
          onPress={() => {
            if (existingRoom) {
              navigator.navigate('ShowDriverInventoryRoom', {
                roomUuid: existingRoom.uuid,
              });
            } else {
              navigator.navigate('ShowDriverInventory');
              refetch();
            }
          }}
        />
        <Space height={16} />
        <ScrollView ref={scrollView.ref}>
          {inventory.isFinalized && (
            <React.Fragment>
              <SuccessCallout
                text={
                  'Inventory is finalized. To add more rooms and items, you must first unfinalize the inventory.'
                }
              />
              <Space height={16} />
            </React.Fragment>
          )}
          <TitleText>{existingRoom ? 'Edit Room' : 'Add Room'}</TitleText>
          <Space height={16} />
          <FieldInput
            label={'Room'}
            index={1}
            size={FieldInput.SIZE.MEDIUM}
            component={DropdownInput}
            input={{
              value: state.roomTypeId,
              fontSize: 16,
              options: roomTypes.map((roomType) => ({
                value: _.toNumber(roomType.id),
                label: roomType.name,
              })),
              placeholder: 'Select room',
              style: {flex: 1},
              setFieldValue: () => {},
              onChangeValue: (roomTypeId) => {
                dispatch({
                  type: SET_ROOM_TYPE,
                  payload: _.find(roomTypes, {id: _.toString(roomTypeId)}),
                });
                dispatch({type: SET_ERRORS, payload: {...state.errors, name: null}});
              },
            }}
          />
          <Space height={16} />
          <FieldInput
            label={'Room Name'}
            index={2}
            size={FieldInput.SIZE.MEDIUM}
            isRequired
            errorName={'name'}
            errors={state.errors}
            touched={state.errors}
            input={{
              value: state.name,
              placeholder: 'Enter room name',
              style: {flex: 1},
              onChangeText: (name) => {
                dispatch({type: SET_ROOM_NAME, payload: name});
                dispatch({type: SET_ERRORS, payload: {...state.errors, name: null}});
              },
            }}
          />
          <Space height={16} />
          <FieldInput
            label={'Description'}
            index={3}
            size={FieldInput.SIZE.MEDIUM}
            input={{
              value: state.description,
              multiline: true,
              placeholder: 'Add description',
              style: {height: 76, paddingTop: 12},
              onChangeText: (description) =>
                dispatch({type: SET_ROOM_DESCRIPTION, payload: description}),
            }}
          />
          <Space height={16} />
        </ScrollView>
      </ContentContainer>
      <BottomButtonContainer>
        <Button
          isDisabled={inventory.isFinalized}
          style={{height: '40px'}}
          isWidthOfContainer
          text={'Save'}
          onPress={() => {
            if (existingRoom) {
              editRoom({
                form,
                roomUuid: existingRoom.uuid,
                room: state,
                onSuccess: () => {
                  successToast.handleToast();
                  navigator.push('ShowDriverInventoryRoom', {
                    roomUuid: existingRoom.uuid,
                  });
                },
                onError: (payload) => {
                  dispatch({type: SET_ERRORS, payload});
                  scrollView.handleScrollToTop({animated: true});
                  errorToast.handleToast();
                },
              });
            } else {
              addRoom({
                form,
                inventoryId: inventory.id,
                room: state,
                onSuccess: (room) => {
                  successToast.handleToast();
                  navigator.push('ShowDriverInventoryRoom', {
                    roomUuid: room.uuid,
                  });
                },
                onError: (payload) => {
                  dispatch({type: SET_ERRORS, payload});
                  scrollView.handleScrollToTop({animated: true});
                  errorToast.handleToast();
                },
              });
            }
          }}
        />
      </BottomButtonContainer>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DriverInventoryRoomFields.fragment = gql`
  fragment DriverInventoryRoomFields on Inventory {
    id
    isFinalized
    project {
      id
      projectType {
        id
        defaultDriverInventoryLibrary {
          id
          roomTypes {
            id
            name
            primaryCategoryId
          }
        }
      }
    }
  }
`;

export default DriverInventoryRoomFields;
