// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {ScrollView, Icon, Styled, Space, Drawer} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useContext, useNavigation, useDrawer, useResponsive} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import SuccessCallout from '@shared/design/components/Callout/SuccessCallout';
import EmptyState from '@shared/design/components/EmptyState';
import FieldInput from '@shared/design/components/Field/FieldInput';
import FieldValue from '@shared/design/components/Field/FieldValue';
import InventoryRoomsForm from '@shared/modules/Inventory/forms/InventoryRoomsForm';
import RoomItemsForm from '@shared/modules/Inventory/forms/RoomItemsForm';
import UpdateInventoryIsFinalizedStatusForm from '@shared/modules/Inventory/forms/UpdateInventoryIsFinalizedStatusForm';
import useUpdateInventoryIsFinalizedStatusMutation from '@shared/modules/Inventory/hooks/useUpdateInventoryIsFinalizedStatusMutation';
import Line from 'modules/App/components/Line';
import LastSavedInfo from 'modules/DriverInventory/components/LastSavedInfo';
import DriverInventoryContext from 'modules/DriverInventory/context/DriverInventoryContext';

const View = Styled.View``;

const Container = Styled.View`
  padding-top: 16px;
  height: 100%;
  background-color: ${colors.gray.background}
`;

const ContentContainer = Styled.View`
  flex: 1;
  padding-horizontal: 16px;
`;

const BorderedContainer = Styled.View`
  border-radius: 4px;
  border-color: ${colors.gray.border};
  border-width: 1px;
  background-color: ${colors.white};
`;

const EmptyStateContainer = Styled.View`
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 4px;
  background-color: ${colors.white};
`;

const RoomDetails = Styled.View`
  padding-horizontal: 16px;
  flex-direction: row;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const FieldValueContainer = Styled.View`
  flex: 1;
  padding-right: 4px;
  padding-left: ${(props) => props.paddingLeft};
  border-left-width: ${(props) => props.showBorder && '1px'};
  border-left-color: ${(props) => (props.showBorder ? colors.gray.disabled : `none`)}
`;

const ItemContainer = Styled.View`
  width: 44px;
  padding-right: 4px;
`;

const Touchable = Styled.ButtonV2``;

const TitleText = Styled.Text`
  ${Typography.PageHeading}
`;

const SubtitleText = Styled.Text`
  ${Typography.Heading2}
`;

const LabelText = Styled.Text`
  ${Typography.Label}
  color: ${colors.blue.interactive};
`;

const DescriptionText = Styled.Text`
  ${Typography.Body}
  color: ${(props) => props.color};
`;

const ItalicText = Styled.Text`
  ${Typography.Micro}
  font-style: italic;
  color: ${colors.gray.secondary};
`;

const HeaderSubtitleText = Styled.Text`
  ${Typography.Micro}
`;

const InventoryMetricValueText = Styled.Text`
  ${Typography.Micro}
  color: ${colors.gray.primary};
`;

const MenuItemText = Styled.Text`
  ${Typography.Mobile.Body}
  color: ${(props) => props.color};
`;

const MenuItemTouchable = Styled.Touchable`
  padding: 16px;
`;

const RoomTile = Styled.View`
  padding: 16px;
  border-radius: 4px;
  border: 1px solid ${colors.gray.border};
  background-color: ${colors.white};
`;

const ColorDot = Styled.View`
  height: 8px;
  width: 8px;
  border-radius: 4px;
  border-width: 1px;
  border-color: ${({borderColor, color}) => borderColor || color};
  background-color: ${({color}) => color};
`;

const BottomButtonContainer = Styled.View`
  background-color: ${colors.gray.background}
  padding: 16px;
  border-top-width: 1px;
  border-color: ${colors.gray.border}
`;

const DrawerContainer = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background}
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const DrawerTitle = Styled.Text`
  ${Typography.PageHeading}
`;

const ActionMenuItem = ({icon, onPress, isDisabled, children}) => {
  return (
    <React.Fragment>
      <MenuItemTouchable onPress={onPress} disabled={isDisabled}>
        <Row style={{alignItems: 'center'}}>
          <Icon source={icon} color={colors.blue.interactive} size={16} />
          <Space width={16} />
          <MenuItemText color={isDisabled ? colors.gray.tertiary : colors.gray.primary}>
            {children}
          </MenuItemText>
        </Row>
      </MenuItemTouchable>
      <Space height={2} />
    </React.Fragment>
  );
};

const InventoryActionsDrawer = ({inventory, job, drawer, navigator, refetch}) => {
  const {mobile} = useResponsive();
  const updateInventoryIsFinalizedStatusForm = UpdateInventoryIsFinalizedStatusForm.new(inventory, {
    isFinalized: true,
    jobId: job.id,
  });
  const {form, handleSubmit} = useUpdateInventoryIsFinalizedStatusMutation({
    updateInventoryIsFinalizedStatusForm,
    onSuccess: () => {
      // NOTE(cooper): We do not make a practice of calling refetch within the Driver Inventory views
      // since we are optimized for speed and minimal queries. This is a rare exception
      refetch();
      drawer.handleClose();
    },
    onError: (errors) => console.log(errors),
  });

  return (
    <Drawer
      isOpen={drawer.isOpen}
      onClose={drawer.handleClose}
      position={mobile ? 'BOTTOM' : 'RIGHT'}
      contentContainerStyle={mobile ? {top: '50vh', height: '50vh'} : {}}
      shouldCloseOnClickOutside
    >
      <DrawerContainer style={mobile ? {} : {width: '350px'}}>
        <Space height={16} />
        <View>
          <Row style={{paddingHorizontal: 16}}>
            <DrawerTitle>Inventory</DrawerTitle>
            <Space style={{flex: 1}} />
            <TertiaryButton onPress={drawer.handleClose} text={'Close'} />
          </Row>
          <Space height={16} />
          <Line />
        </View>
        <View style={{flex: 1}}>
          <Space height={16} />
          {/* TODO(cooper): Disable this action if there is no connection */}
          {inventory.isFinalized ? (
            <ActionMenuItem
              icon={Icon.ArrowRotateLeft}
              onPress={() => {
                form.setFieldValue('updateInventoryIsFinalizedStatusForm.isFinalized', false);
                setTimeout(() => handleSubmit(), 0);
              }}
            >
              Unfinalize Inventory
            </ActionMenuItem>
          ) : (
            <ActionMenuItem
              icon={Icon.Check}
              onPress={() => {
                form.setFieldValue('updateInventoryIsFinalizedStatusForm.isFinalized', true);
                setTimeout(() => handleSubmit(), 0);
              }}
            >
              Finalize Inventory
            </ActionMenuItem>
          )}
          <ActionMenuItem icon={Icon.Boxes} onPress={() => navigator.push('ViewFullInventory')}>
            View Full Inventory
          </ActionMenuItem>
          <Space height={16} />
        </View>
      </DrawerContainer>
    </Drawer>
  );
};

const InventorySummaryCard = ({inventoryRoomsForm}) => {
  const inventoryRoomsInfo = InventoryRoomsForm.getInfo(inventoryRoomsForm);
  return (
    <BorderedContainer>
      <Space height={16} />
      <RoomDetails>
        <FieldValueContainer>
          <FieldValue
            label={'ITEMS'}
            labelColor={colors.gray.tertiary}
            value={inventoryRoomsInfo.itemCount}
            empty={'0'}
            numberOfLines={2}
            size={FieldValue.SIZE.SMALL}
          />
        </FieldValueContainer>
        <FieldValueContainer>
          <FieldValue
            label={'ROOMS'}
            labelColor={colors.gray.tertiary}
            value={inventoryRoomsInfo.roomCount}
            empty={'None'}
            numberOfLines={2}
            size={FieldValue.SIZE.SMALL}
          />
        </FieldValueContainer>
        <FieldValueContainer>
          <FieldValue
            label={'WEIGHT'}
            labelColor={colors.gray.tertiary}
            value={`${inventoryRoomsInfo.weight} lbs`}
            empty={'0 lbs'}
            numberOfLines={2}
            size={FieldValue.SIZE.SMALL}
          />
        </FieldValueContainer>
        <FieldValueContainer>
          <FieldValue
            label={'VOLUME'}
            labelColor={colors.gray.tertiary}
            value={`${inventoryRoomsInfo.volume} cu ft`}
            empty={'0 cu ft'}
            numberOfLines={2}
            size={FieldValue.SIZE.SMALL}
          />
        </FieldValueContainer>
      </RoomDetails>
      <Space height={12} />
    </BorderedContainer>
  );
};

const RoomMetric = ({label, value, numberOfLines}) => {
  const height = numberOfLines > 1 ? numberOfLines * 9 : 4;
  return (
    <>
      <HeaderSubtitleText>{label}</HeaderSubtitleText>
      <Space height={height} />
      <InventoryMetricValueText numberOfLines={1}>{value}</InventoryMetricValueText>
      <Space height={height} />
    </>
  );
};

const RoomTileCard = ({roomItemsForm}) => {
  const roomItemsInfo = RoomItemsForm.getInfo(roomItemsForm);
  const roomLotAndRangeInfo = _.orderBy(
    RoomItemsForm.getLotAndRangeInfo(roomItemsForm),
    ['lotNumber', 'color'],
    ['asc', 'asc'],
  );
  const numberOfLines = _.size(roomLotAndRangeInfo);
  return (
    <RoomTile>
      <Row>
        <LabelText>{roomItemsForm.name}</LabelText>
        <Space style={{flex: 1}} />
        <Icon source={Icon.AngleRight} color={colors.blue.interactive} size={16} />
      </Row>
      <Space height={8} />
      <Row>
        <ItemContainer>
          <RoomMetric
            label={'ITEMS'}
            value={roomItemsInfo.itemCount}
            numberOfLines={numberOfLines}
          />
        </ItemContainer>
        <FieldValueContainer>
          <RoomMetric
            label={'VOLUME'}
            value={`${roomItemsInfo.volume} cu ft`}
            numberOfLines={numberOfLines}
          />
        </FieldValueContainer>
        <FieldValueContainer>
          <RoomMetric
            label={'WEIGHT'}
            value={`${roomItemsInfo.weight} lbs`}
            numberOfLines={numberOfLines}
          />
        </FieldValueContainer>
        <FieldValueContainer showBorder paddingLeft={'20px'}>
          <HeaderSubtitleText>{'LOT'}</HeaderSubtitleText>
          {_.size(roomLotAndRangeInfo) === 0 && <ItalicText>None</ItalicText>}
          {roomLotAndRangeInfo.map((info, index) => (
            <React.Fragment key={index}>
              <Space height={4} />
              <Row style={{alignItems: 'center'}}>
                {info.color ? (
                  <>
                    <ColorDot
                      color={info.color}
                      borderColor={
                        _.toLower(info.color) === _.toLower(colors.white) && colors.gray.secondary
                      }
                    />
                    <Space width={4} />
                  </>
                ) : (
                  <Space width={12} />
                )}
                {info.lotNumber ? (
                  <InventoryMetricValueText numberOfLines={1}>
                    {info.lotNumber}
                  </InventoryMetricValueText>
                ) : (
                  <ItalicText>None</ItalicText>
                )}
              </Row>
            </React.Fragment>
          ))}
        </FieldValueContainer>
        <FieldValueContainer>
          <HeaderSubtitleText>{'RANGE'}</HeaderSubtitleText>
          {_.size(roomLotAndRangeInfo) === 0 && <ItalicText>None</ItalicText>}
          {roomLotAndRangeInfo.map((info, index) => (
            <React.Fragment key={index}>
              <Space height={4} />
              <Row>
                <InventoryMetricValueText numberOfLines={1}>{info.range}</InventoryMetricValueText>
              </Row>
            </React.Fragment>
          ))}
        </FieldValueContainer>
      </Row>
    </RoomTile>
  );
};

const ShowDriverInventory = () => {
  const {form, inventory, job, onExitDriverInventoryExperience, refetch} = useContext(
    DriverInventoryContext,
  );
  const {navigator, params} = useNavigation();
  const inventoryRoomsForm = _.get(form.values, 'inventoryRoomsForm');
  const roomItemsForms = _.get(form.values, `inventoryRoomsForm.roomItemsForms`);
  const captureInventoryActionsDrawer = useDrawer({name: 'Capture Inventory Actions Drawer'});

  return (
    <Container>
      <ContentContainer>
        {!params.hideHeader && (
          <React.Fragment>
            <TertiaryButton
              iconLeft={Icon.AngleLeft}
              iconSize={14}
              text={'Back to Job'}
              onPress={() => {
                onExitDriverInventoryExperience();
                navigator.push('Job', {
                  jobUuid: job.uuid,
                });
              }}
            />
            <Space height={16} />
          </React.Fragment>
        )}
        <ScrollView>
          {inventory.isFinalized && (
            <React.Fragment>
              <SuccessCallout
                text={
                  'Inventory is finalized. To add more rooms and items, you must first unfinalize the inventory.'
                }
              />
              <Space height={16} />
            </React.Fragment>
          )}
          <Row style={{alignItems: 'flex-start'}}>
            <TitleText>Inventory for Job {job.identifier}</TitleText>
            <Space style={{flex: 1}} />
            <SecondaryButton
              iconLeft={Icon.EllipsisV}
              iconSize={14}
              onPress={captureInventoryActionsDrawer.handleOpen}
            />
          </Row>
          <Space height={16} />
          <Row>
            <DescriptionText color={colors.gray.tertiary}>Customer: </DescriptionText>
            <DescriptionText>{job.project.client.primaryContact.fullName}</DescriptionText>
          </Row>
          <Space height={16} />
          <InventorySummaryCard inventoryRoomsForm={inventoryRoomsForm} />
          <Space height={8} />
          <LastSavedInfo lastSyncedAt={inventoryRoomsForm.lastSyncedAt} />
          <Space height={16} />
          <FieldInput
            name={'Notes'}
            index={0}
            size={FieldInput.SIZE.MEDIUM}
            input={{
              value: inventoryRoomsForm.notes,
              multiline: true,
              placeholder: 'Add notes',
              style: {height: 76, paddingTop: 12},
              onChangeText: (notes) => {
                form.setFieldValue(`inventoryRoomsForm.notes`, notes);
                form.setFieldValue(`inventoryRoomsForm.isDirty`, true);
              },
            }}
          />
          <Space height={16} />
          <SubtitleText>Rooms</SubtitleText>
          <Space height={16} />
          {_.size(roomItemsForms) === 0 && (
            <EmptyStateContainer>
              <EmptyState title={'No rooms added yet.'} message={'To get started, add a room.'} />
              <Space height={16} />
            </EmptyStateContainer>
          )}
          {form.values.inventoryRoomsForm.roomItemsForms.map((roomItemsForm, index) => {
            if (!roomItemsForm.isDeleted) {
              return (
                <React.Fragment key={index}>
                  <Touchable
                    onPress={() =>
                      navigator.push('ShowDriverInventoryRoom', {
                        roomUuid: roomItemsForm.uuid,
                      })
                    }
                  >
                    <RoomTileCard key={index} roomItemsForm={roomItemsForm} />
                  </Touchable>
                  <Space height={16} />
                </React.Fragment>
              );
            } else {
              return null;
            }
          })}
        </ScrollView>
      </ContentContainer>
      <BottomButtonContainer>
        <Button
          isDisabled={inventory.isFinalized}
          style={{height: '40px'}}
          isWidthOfContainer
          text={'Add Room'}
          onPress={() => navigator.push('CreateDriverInventoryRoom')}
        />
      </BottomButtonContainer>
      <InventoryActionsDrawer
        inventory={inventory}
        job={job}
        drawer={captureInventoryActionsDrawer}
        navigator={navigator}
        refetch={refetch}
      />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ShowDriverInventory.inventoryFragment = gql`
  fragment ShowDriverInventory_inventory on Inventory {
    id
    isFinalized
  }
`;

ShowDriverInventory.jobFragment = gql`
  fragment ShowDriverInventory_job on Job {
    id
    uuid
    identifier
    project {
      id
      client {
        id
        primaryContact {
          id
          fullName
        }
      }
    }
  }
`;

export default ShowDriverInventory;
