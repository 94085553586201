// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {
  CurrencyInput,
  DropdownInput,
  Icon,
  Styled,
  Space,
  Popover,
  ScrollView,
} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useDrawer,
  useReducer,
  useToast,
  useState,
  useMountEffect,
  useScrollView,
  useModal,
  usePopover,
  useContext,
  useNavigation,
} from '@supermove/hooks';
import ResponsivePopover from '@supermove/manager/src/modules/App/components/ResponsivePopover';
import {Inventory} from '@supermove/models';
import {Typography, colors} from '@supermove/styles';
import {Currency, uuid} from '@supermove/utils';

// App
import ResponsiveBadge from '@shared/design/components/Badge/ResponsiveBadge';
import Button from '@shared/design/components/Button';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Callout from '@shared/design/components/Callout';
import SuccessCallout from '@shared/design/components/Callout/SuccessCallout';
import FieldInput from '@shared/design/components/Field/FieldInput';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import Table from '@shared/design/components/Table';
import ErrorToast from '@shared/design/components/Toast/ErrorToast';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import ItemTagItemKind from '@shared/modules/Inventory/enums/ItemTagItemKind';
import ItemTagKind from '@shared/modules/Inventory/enums/ItemTagKind';
import ItemTypeKind from '@shared/modules/Inventory/enums/ItemTypeKind';
import ItemAttachmentForm from '@shared/modules/Inventory/forms/ItemAttachmentForm';
import ItemFormV2 from '@shared/modules/Inventory/forms/ItemFormV2';
import ItemTagItemForm from '@shared/modules/Inventory/forms/ItemTagItemForm';
import ExceptionsDrawer from 'modules/DriverInventory/Items/ExceptionsDrawer';
import ItemPhotosSection from 'modules/DriverInventory/Items/ItemPhotosSection';
import ItemTagsDrawer from 'modules/DriverInventory/Items/ItemTagsDrawer';
import SelectItemTypeDrawer from 'modules/DriverInventory/Items/SelectItemTypeDrawer';
import UploadPhotosDrawer from 'modules/DriverInventory/Items/UploadPhotosDrawer';
import DriverInventoryContext from 'modules/DriverInventory/context/DriverInventoryContext';

const Container = Styled.View`
  padding-top: 16px;
  height: 100%;
  background-color: ${colors.gray.background}
`;

const ContentContainer = Styled.View`
  flex: 1;
  padding-horizontal: 16px;
`;

const TitleText = Styled.Text`
  ${Typography.PageHeading}
`;

const SubHeadingText = Styled.Text`
  ${Typography.Heading2}
`;

const FieldRowContainer = Styled.View`
  flex-direction: row;
`;

const FieldContainer = Styled.View`
  flex-direction: column;
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const BodyText = Styled.Text`
  ${Typography.Mobile.Body}
  color: ${({color}) => color || colors.gray.primary};
`;

const LabelText = Styled.Text`
  ${Typography.Body2}
  color: ${colors.gray.primary};
`;

const ColorDot = Styled.View`
  height: 16px;
  width: 16px;
  border-radius: 8px;
  border-width: 1px;
  border-color: ${({borderColor, color}) => borderColor || color};
  background-color: ${({color}) => color};
`;

const BottomButtonContainer = Styled.View`
  background-color: ${colors.gray.background}
  padding: 16px;
  border-top-width: 1px;
  border-color: ${colors.gray.border}
`;

const MenuItemText = Styled.Text`
  ${Typography.Body}
  padding-vertical: 12px;
  padding-horizontal: 12px;
  color: ${(props) => props.color};
`;

const Touchable = Styled.Touchable``;

const ActionMenuItem = ({onPress, isDisabled, children}) => {
  return (
    <React.Fragment>
      <Touchable onPress={onPress} disabled={isDisabled}>
        <MenuItemText color={isDisabled ? colors.gray.tertiary : colors.gray.primary}>
          {children}
        </MenuItemText>
      </Touchable>
      <Space height={2} />
    </React.Fragment>
  );
};

const ItemActionsPopover = ({popover, state, handleVoidOrUnvoidLabel, handleDuplicateItem}) => {
  return (
    <Popover
      placement={Popover.Positions.BottomStart}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
      offset={[0, 4]}
    >
      <ResponsivePopover.StaticContainer width={200}>
        <Space height={8} />
        <ActionMenuItem isDisabled={!state.itemNumber} onPress={handleVoidOrUnvoidLabel}>
          {state.isVoid ? 'Unvoid' : 'Void'} label
        </ActionMenuItem>
        <Space height={8} />
        <ActionMenuItem isDisabled={state.isVoid} onPress={handleDuplicateItem}>
          Duplicate item
        </ActionMenuItem>
        <Space height={8} />
      </ResponsivePopover.StaticContainer>
    </Popover>
  );
};

const ExceptionsSection = ({
  itemTagItems,
  exceptionsDrawer,
  setExceptionForEdit,
  setExceptionForDelete,
  inventoryLibrary,
  deleteExceptionModal,
}) => {
  return (
    <React.Fragment>
      <SubHeadingText>Exceptions</SubHeadingText>
      <Space height={16} />
      <SecondaryButton
        isWidthOfContainer
        text={'Add Exception'}
        onPress={exceptionsDrawer.handleOpen}
      />
      <Space height={16} />
      <Table
        columnDefinitions={[
          {
            width: 32,
            headerContent: () => {},
            cellContent: ({rowIndex}) => (
              <Row>
                <BodyText style={{color: colors.gray.secondary}}>{rowIndex + 1}.</BodyText>
              </Row>
            ),
          },
          {
            flex: 1,
            headerContent: () => {},
            cellContent: ({item: itemTagItem}) => {
              const selectedExceptionLocations = ItemTagItemForm.getSelectedItemTagsForItemTagKind(
                inventoryLibrary,
                {
                  selectedItemTagIds: itemTagItem.itemTagIds,
                  itemTagKind: ItemTagKind.EXCEPTION_LOCATION,
                },
              );
              const selectedExceptionTypes = ItemTagItemForm.getSelectedItemTagsForItemTagKind(
                inventoryLibrary,
                {
                  selectedItemTagIds: itemTagItem.itemTagIds,
                  itemTagKind: ItemTagKind.EXCEPTION_TYPE,
                },
              );

              return (
                <BodyText numberOfLines={1}>
                  {`${selectedExceptionLocations.map(({name}) => name).join(', ')}${
                    selectedExceptionLocations.length && selectedExceptionTypes.length ? ' - ' : ''
                  }${selectedExceptionTypes.map(({name}) => name).join(', ')}`}
                </BodyText>
              );
            },
          },
          {
            width: 32,
            headerContent: () => {},
            cellContent: ({item: itemTagItem}) => (
              <Touchable
                onPress={() => {
                  setExceptionForEdit(itemTagItem);
                  exceptionsDrawer.handleOpen();
                }}
              >
                <Row>
                  <Icon source={Icon.Pen} color={colors.blue.interactive} size={16} />
                </Row>
              </Touchable>
            ),
          },
          {
            width: 32,
            headerContent: () => {},
            cellContent: ({item: itemTagItem}) => (
              <Touchable
                onPress={() => {
                  setExceptionForDelete(itemTagItem);
                  deleteExceptionModal.handleOpen();
                }}
              >
                <Row>
                  <Icon source={Icon.Trash} color={colors.red.warning} size={16} />
                </Row>
              </Touchable>
            ),
          },
        ]}
        emptyStateText='No exceptions'
        isClickable
        onRowPress={(itemTagItem) => {
          setExceptionForEdit(itemTagItem);
          exceptionsDrawer.handleOpen();
        }}
        items={_.filter(
          itemTagItems,
          (itemTagItem) => itemTagItem.kind === ItemTagItemKind.EXCEPTION && !itemTagItem.isDeleted,
        )}
        containerStyle={{borderTopWidth: 0}}
        headerStyle={{
          display: 'none',
        }}
        isDense
      />
    </React.Fragment>
  );
};

const DeleteExceptionModal = ({deleteExceptionModal, handleDelete, handleClose}) => {
  return (
    <DeleteModal
      isOpen={deleteExceptionModal.isOpen}
      title={'Delete exception?'}
      subtitle={'This action cannot be undone.'}
      handleClose={() => {
        handleClose();
        deleteExceptionModal.handleClose();
      }}
      handleDelete={handleDelete}
    />
  );
};

const UnsavedChangesModal = ({unsavedChangesModal, handleNavigate}) => {
  return (
    <SmallModal isOpen={unsavedChangesModal.isOpen} style={{alignItems: 'center'}}>
      <SmallModal.HeaderIcon source={Icon.InfoCircle} color={colors.orange.status} />
      <Space height={12} />
      <SmallModal.HeaderText style={{color: colors.orange.status}}>
        There are unsaved changes.
      </SmallModal.HeaderText>
      <Space height={12} />
      <BodyText>
        You have unsaved changes that will be lost if you leave the page. Are you sure you want to
        continue?
      </BodyText>
      <Space height={12} />
      <SmallModal.Footer>
        <SmallModal.Button onPress={unsavedChangesModal.handleClose}>Cancel</SmallModal.Button>
        <Space width={8} />
        <SmallModal.Button color={colors.orange.status} onPress={handleNavigate}>
          Confirm & Close
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

const incrementItemNumber = (itemNumber) => {
  // Increment an item number _retaining any leading zeros_
  // Note that item "numbers" are actually strings
  const stringLength = itemNumber.length;
  const incrementedNumber = _.toString(_.toNumber(itemNumber) + 1);
  return _.padStart(incrementedNumber, stringLength, '0');
};

const validateItem = ({item, form, itemUuid}) => {
  const errors = {};
  if (!item.itemNumber) {
    errors.itemNumber = 'Please enter an item number.';
  }
  if (item.itemNumber && !Inventory.containsOnlyDigits(item.itemNumber)) {
    errors.itemNumber = 'Can only contain numeric digits.';
  }
  if (!item.name) {
    errors.name = 'Please enter an item name.';
  }

  const hasPreviouslyUsedItemNumber = _.some(
    form.values.inventoryRoomsForm.roomItemsForms,
    (roomForm) => {
      return _.some(
        _.filter(roomForm.itemForms, (itemForm) => itemForm.uuid !== itemUuid),
        {
          itemNumber: item.itemNumber,
          lotNumber: item.lotNumber,
          color: item.color,
        },
      );
    },
  );
  if (hasPreviouslyUsedItemNumber) {
    errors.itemNumber = 'This item number is already in use for this lot.';
  }

  return errors;
};

const addItem = ({form, item, room, roomUuid, onSuccess, onError}) => {
  const errors = validateItem({item, form});

  if (!_.isEmpty(errors)) {
    onError(errors);
  } else {
    const itemForm = ItemFormV2.new({
      collectionId: room.collectionId,
      lotNumber: item.lotNumber,
      color: item.color,
      itemNumber: item.itemNumber,
      name: item.name,
      volume: Inventory.getFloatValue(item.volume),
      weight: Inventory.getFloatValue(item.weight),
      // NOTE(cooper): Due to backend requirements for the surveys tool, we
      // explicitly send prices to the backend in raw cents rather than
      // the usual "Currency.toMutation()"
      price: Currency.convertToCents(item.price),
      notes: item.notes,
      isVoid: item.isVoid,
      // NOTE(cassie): Kind is needed for room summary cards to update info
      kind: ItemTypeKind.ITEM,
      itemTagItems: item.itemTagItems,
      itemAttachments: item.itemAttachments,
    });
    const roomItemFormsIndex = _.findIndex(form.values.inventoryRoomsForm.roomItemsForms, {
      uuid: roomUuid,
    });
    form.setFieldValue(`inventoryRoomsForm.roomItemsForms.${roomItemFormsIndex}.itemForms`, [
      ...form.values.inventoryRoomsForm.roomItemsForms[roomItemFormsIndex].itemForms,
      itemForm,
    ]);
    form.setFieldValue(`inventoryRoomsForm.roomItemsForms.${roomItemFormsIndex}.isDirty`, true);
    onSuccess(itemForm);
  }
};

const editItem = ({form, item, roomUuid, itemUuid, onSuccess, onError}) => {
  const errors = validateItem({item, form, itemUuid});

  if (!_.isEmpty(errors)) {
    onError(errors);
  } else {
    const existingRoomFormIndex = _.findIndex(form.values.inventoryRoomsForm.roomItemsForms, {
      uuid: roomUuid,
    });
    const existingItemFormIndex = _.findIndex(
      form.values.inventoryRoomsForm.roomItemsForms[existingRoomFormIndex].itemForms,
      {
        uuid: itemUuid,
      },
    );
    // TODO(cooper): Update these in bulk so it's not so verbose
    form.setFieldValue(
      `inventoryRoomsForm.roomItemsForms.${existingRoomFormIndex}.itemForms.${existingItemFormIndex}.lotNumber`,
      item.lotNumber,
    );
    form.setFieldValue(
      `inventoryRoomsForm.roomItemsForms.${existingRoomFormIndex}.itemForms.${existingItemFormIndex}.color`,
      item.color,
    );
    form.setFieldValue(
      `inventoryRoomsForm.roomItemsForms.${existingRoomFormIndex}.itemForms.${existingItemFormIndex}.itemNumber`,
      item.itemNumber,
    );
    form.setFieldValue(
      `inventoryRoomsForm.roomItemsForms.${existingRoomFormIndex}.itemForms.${existingItemFormIndex}.name`,
      item.name,
    );
    form.setFieldValue(
      `inventoryRoomsForm.roomItemsForms.${existingRoomFormIndex}.itemForms.${existingItemFormIndex}.volume`,
      Inventory.getFloatValue(item.volume),
    );
    form.setFieldValue(
      `inventoryRoomsForm.roomItemsForms.${existingRoomFormIndex}.itemForms.${existingItemFormIndex}.weight`,
      Inventory.getFloatValue(item.weight),
    );
    form.setFieldValue(
      `inventoryRoomsForm.roomItemsForms.${existingRoomFormIndex}.itemForms.${existingItemFormIndex}.price`,
      Currency.convertToCents(item.price),
    );
    form.setFieldValue(
      `inventoryRoomsForm.roomItemsForms.${existingRoomFormIndex}.itemForms.${existingItemFormIndex}.notes`,
      item.notes,
    );
    form.setFieldValue(
      `inventoryRoomsForm.roomItemsForms.${existingRoomFormIndex}.itemForms.${existingItemFormIndex}.isVoid`,
      item.isVoid,
    );
    // Set itemTagItemForms
    form.setFieldValue(
      `inventoryRoomsForm.roomItemsForms.${existingRoomFormIndex}.itemForms.${existingItemFormIndex}.itemTagItemForms`,
      item.itemTagItems.map((itemTagItem) => {
        if (itemTagItem.uuid) {
          return ItemTagItemForm.edit(itemTagItem);
        } else {
          return ItemTagItemForm.new(itemTagItem);
        }
      }),
    );
    // Set itemAttachmentForms
    form.setFieldValue(
      `inventoryRoomsForm.roomItemsForms.${existingRoomFormIndex}.itemForms.${existingItemFormIndex}.itemAttachmentForms`,
      item.itemAttachments.map((itemAttachment) =>
        ItemAttachmentForm.newWithAttachmentId(itemAttachment),
      ),
    );
    // Mark both the item and the room as dirty
    form.setFieldValue(
      `inventoryRoomsForm.roomItemsForms.${existingRoomFormIndex}.itemForms.${existingItemFormIndex}.isDirty`,
      true,
    );
    form.setFieldValue(`inventoryRoomsForm.roomItemsForms.${existingRoomFormIndex}.isDirty`, true);
    onSuccess();
  }
};

const SET_LOT_NUMBER = 'SET_LOT_NUMBER';
const SET_COLOR = 'SET_COLOR';
const SET_ITEM_NUMBER = 'SET_ITEM_NUMBER';
const SET_NAME = 'SET_NAME';
const SET_VOLUME = 'SET_VOLUME';
const SET_WEIGHT = 'SET_WEIGHT';
const SET_PRICE = 'SET_PRICE';
const SET_NOTES = 'SET_NOTES';
const SET_IS_VOID = 'SET_IS_VOID';
const SET_FIELDS_FOR_ITEM_TYPE = 'SET_FIELDS_FOR_ITEM_TYPE';
const RESET_FIELDS = 'RESET_FIELDS';
const SET_ITEM_TAG_ITEMS = 'SET_ITEM_TAG_ITEMS';
const RESET_ITEM_TAG_ITEMS = 'RESET_ITEM_TAG_ITEMS';
const SET_ITEM_ATTACHMENTS = 'SET_ITEM_ATTACHMENTS';
const RESET_ITEM_ATTACHMENTS = 'RESET_ITEM_ATTACHMENTS';
const SET_ERRORS = 'SET_ERRORS';

const getInitialState = ({existingItem}) => {
  if (existingItem) {
    return {
      lotNumber: existingItem.lotNumber,
      color: existingItem.color,
      itemNumber: existingItem.itemNumber,
      name: existingItem.name,
      volume: _.toString(existingItem.volume),
      weight: _.toString(existingItem.weight),
      price: Currency.toForm(existingItem.price),
      notes: existingItem.notes,
      isVoid: existingItem.isVoid,
      itemTagItems: existingItem.itemTagItemForms.map((itemTagItemForm) => ({
        id: itemTagItemForm.itemTagItemId,
        itemTagIds: itemTagItemForm.itemTagIds.map((itemTagId) => _.toString(itemTagId)),
        uuid: itemTagItemForm.uuid,
        kind: itemTagItemForm.kind,
        isDeleted: itemTagItemForm.isDeleted,
      })),
      itemAttachments: existingItem.itemAttachmentForms.map((itemAttachmentForm) => ({
        id: itemAttachmentForm.attachmentId,
        fileId: itemAttachmentForm.fileId,
        uuid: itemAttachmentForm.uuid,
        filename: itemAttachmentForm.filename,
        description: itemAttachmentForm.description,
        downloadUrl: itemAttachmentForm.downloadUrl,
        isDeleted: itemAttachmentForm.isDeleted,
      })),
      errors: {},
    };
  }

  return {
    lotNumber: '',
    color: '',
    itemNumber: '',
    name: '',
    volume: '',
    weight: '',
    price: '',
    notes: '',
    isVoid: false,
    itemTagItems: [],
    itemAttachments: [],
    errors: {},
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case SET_LOT_NUMBER:
      return {...state, lotNumber: action.payload};
    case SET_COLOR:
      return {...state, color: action.payload};
    case SET_ITEM_NUMBER:
      return {...state, itemNumber: action.payload};
    case SET_NAME:
      return {...state, name: action.payload};
    case SET_VOLUME:
      return {...state, volume: action.payload};
    case SET_WEIGHT:
      return {...state, weight: action.payload};
    case SET_PRICE:
      return {...state, price: action.payload};
    case SET_NOTES:
      return {...state, notes: action.payload};
    case SET_IS_VOID:
      return {...state, isVoid: action.payload};
    case SET_FIELDS_FOR_ITEM_TYPE:
      return {
        ...state,
        name: action.payload.name,
        volume: _.toString(action.payload.volume),
        weight: _.toString(action.payload.weight),
        price: Currency.toForm(action.payload.price),
      };
    case SET_ERRORS:
      return {...state, errors: action.payload};
    case RESET_FIELDS:
      return {
        ...state,
        name: '',
        volume: '',
        weight: '',
        price: '',
        notes: '',
        isVoid: false,
        errors: {},
      };
    case SET_ITEM_TAG_ITEMS:
      return {...state, itemTagItems: action.payload};
    case RESET_ITEM_TAG_ITEMS:
      return {...state, itemTagItems: []};
    case SET_ITEM_ATTACHMENTS:
      return {...state, itemAttachments: action.payload};
    case RESET_ITEM_ATTACHMENTS:
      return {...state, itemAttachments: []};
    default:
      return state;
  }
};

const getCalloutText = ({nextItemInitialState, state}) => {
  if (nextItemInitialState && state) {
    const {
      lotNumber: initialLotNumber,
      color: initialColor,
      itemNumber: initialItemNumber,
    } = nextItemInitialState;
    const {lotNumber, color, itemNumber} = state;

    if (
      initialLotNumber !== lotNumber ||
      initialColor !== color ||
      initialItemNumber !== itemNumber
    ) {
      return `You're entering a new inventory label. Items will use the new lot and color unless changed again.`;
    }
    return '';
  }

  return '';
};

const handleBackNavigate = ({navigator, fromViewFullInventory, roomUuid}) => {
  fromViewFullInventory
    ? navigator.navigate('ViewFullInventory')
    : navigator.navigate('ShowDriverInventoryRoom', {
        roomUuid,
      });
};

const trackItemTagItemsChanges = ({state, existingItem}) => {
  // Checks both item tags and exceptions
  const stateItemTagItems = _.filter(state.itemTagItems, {isDeleted: false});
  const existingItemTagItems = _.filter(existingItem.itemTagItemForms, {isDeleted: false});
  // If the number of itemTagItems is different, then there are unsaved changes
  if (stateItemTagItems.length !== existingItemTagItems.length) {
    return true;
  }
  const existingItemTagItemFormsMap = _.keyBy(existingItemTagItems, 'itemTagItemId');
  // Checks for changes in itemTagIds and whether each item tag matches
  return !_.every(stateItemTagItems, (stateItem) => {
    const currentItem = existingItemTagItemFormsMap[stateItem.id];
    if (!currentItem) {
      return false;
    }
    return _.every(stateItem.itemTagIds, (id) => {
      return _.includes(currentItem.itemTagIds, _.parseInt(id));
    });
  });
};

const trackItemAttachmentsChanges = ({state, existingItem}) => {
  const stateAttachments = _.filter(state.itemAttachments, {isDeleted: false});
  const existingAttachments = _.filter(existingItem.itemAttachmentForms, {isDeleted: false});
  if (stateAttachments.length !== existingAttachments.length) {
    return true;
  }
  const existingAttachmentsMap = _.keyBy(existingAttachments, 'fileId');
  // Checks for changes in description and whether each attachment matches
  return !_.every(stateAttachments, (stateAttachment) => {
    const currentAttachment = existingAttachmentsMap[stateAttachment.fileId];
    if (!currentAttachment) {
      return false;
    }
    return stateAttachment.description === currentAttachment.description;
  });
};

const trackUnsavedChanges = ({state, existingItem}) => {
  const fieldsToCheck = ['isVoid', 'name', 'notes', 'price', 'volume', 'weight'];
  // Create flow
  if (!existingItem) {
    const blankItem = getInitialState({existingItem: null});
    return (
      _.some(fieldsToCheck, (field) => state[field] !== blankItem[field]) ||
      !_.isEmpty(state.itemTagItems) ||
      !_.isEmpty(state.itemAttachments)
    );
  }
  // Edit flow
  return (
    _.some(fieldsToCheck, (field) => {
      if (field === 'price') {
        return !_.isEqual(Currency.convertToCents(state[field]), existingItem[field]);
      } else if (field === 'volume' || field === 'weight') {
        return !_.isEqual(_.toNumber(state[field]), existingItem[field]);
      }
      return !_.isEqual(state[field], existingItem[field]);
    }) ||
    trackItemTagItemsChanges({state, existingItem}) ||
    trackItemAttachmentsChanges({state, existingItem})
  );
};

const ColorOption = ({isSelected, value, label}) => {
  return (
    <Row>
      <ColorDot
        color={value}
        borderColor={_.toLower(value) === _.toLower(colors.white) && colors.gray.secondary}
      />
      <Space width={8} />
      <BodyText style={{color: isSelected ? colors.white : colors.black}}>{label}</BodyText>
    </Row>
  );
};

/**
 * NOTE(cooper): Driver inventory is designed to operate in suboptimal network conditions.
 * Therefore this component does not make any queries or mutations as we normally would,
 * rather we use a reducer to maintain the local form state and then commit to the
 * parent form upon submission.
 */
const DriverInventoryItemFields = () => {
  const {
    form,
    inventory: {
      isFinalized,
      project: {
        projectType: {defaultDriverInventoryLibrary: inventoryLibrary},
        organization: {id: organizationId},
      },
    },
  } = useContext(DriverInventoryContext);
  const {
    navigator,
    params: {roomUuid, itemUuid, fromViewFullInventory, scrollToBottom},
  } = useNavigation();
  const room = _.find(form.values.inventoryRoomsForm.roomItemsForms, {uuid: roomUuid});
  const existingItem = _.find(room.itemForms, {uuid: itemUuid});
  const [nextItemInitialState, setNextItemInitialState] = useState(null);
  const [state, dispatch] = useReducer(
    (state, action) => reducer(state, action),
    getInitialState({existingItem}),
  );
  const calloutText = !existingItem ? getCalloutText({nextItemInitialState, state}) : '';
  const selectItemTypeDrawer = useDrawer({name: 'Select Item Drawer', enableTracking: true});
  const itemTagsDrawer = useDrawer({name: 'Item Tags Drawer', enableTracking: true});
  const exceptionsDrawer = useDrawer({name: 'Exceptions Drawer', enableTracking: true});
  const uploadPhotosDrawer = useDrawer({name: 'Upload Photos Drawer', enableTracking: true});
  const deleteExceptionModal = useModal({name: 'Delete Exception Modal', enableTracking: true});
  const itemActionsPopover = usePopover({name: 'Driver Inventory Item Actions Popover'});
  const unsavedChangesModal = useModal({name: 'Unsaved Changes Modal', enableTracking: true});

  const scrollView = useScrollView();
  useMountEffect(() => {
    if (scrollToBottom) {
      scrollView.handleScrollToEnd({animated: true});
    }
  });

  const successToast = useToast({
    ToastComponent: SuccessToast,
    message: `${state.name} ${existingItem ? 'edited' : 'added'}.`,
    isClosable: true,
  });
  const errorToast = useToast({
    ToastComponent: ErrorToast,
    message: 'Please fix errors before saving',
    isClosable: true,
  });
  const duplicateToast = useToast({
    ToastComponent: SuccessToast,
    message: `${state.name} duplicated.`,
    isClosable: true,
  });

  const [exceptionForEdit, setExceptionForEdit] = useState(null);
  const [exceptionForDelete, setExceptionForDelete] = useState(null);

  const selectedItemTagIds = _.map(
    _.filter(
      state.itemTagItems,
      ({kind, isDeleted}) => kind === ItemTagItemKind.ITEM_TAG && !isDeleted,
    ),
    ({itemTagIds}) => itemTagIds[0],
  );

  return (
    <Container>
      <ContentContainer>
        <TertiaryButton
          iconLeft={Icon.AngleLeft}
          iconSize={14}
          text={fromViewFullInventory ? 'View Full Inventory' : `Back to ${room.name}`}
          onPress={() => {
            const unsavedChanges = trackUnsavedChanges({state, existingItem});
            if (unsavedChanges) {
              unsavedChangesModal.handleOpen();
            } else {
              handleBackNavigate({navigator, fromViewFullInventory, roomUuid});
            }
          }}
        />
        <Space height={16} />
        {state.isVoid && (
          <Row>
            <ResponsiveBadge
              textColor={colors.red700}
              backgroundColor={colors.red50}
              label={'Void'}
            />
          </Row>
        )}
        <Space height={16} />
        <ScrollView ref={scrollView.ref}>
          {isFinalized && (
            <React.Fragment>
              <SuccessCallout
                text={
                  'Inventory is finalized. To add more rooms and items, you must first unfinalize the inventory.'
                }
              />
              <Space height={16} />
            </React.Fragment>
          )}
          <Row style={{alignItems: 'flex-start'}}>
            <TitleText>{existingItem ? 'Edit Item' : 'Add Item'}</TitleText>
            <Space style={{flex: 1}} />
            <Popover.Content innerRef={itemActionsPopover.ref}>
              <SecondaryButton
                iconLeft={Icon.EllipsisV}
                iconSize={14}
                onPress={itemActionsPopover.handleToggle}
              />
            </Popover.Content>
          </Row>
          <Space height={16} />
          <SubHeadingText>Label</SubHeadingText>
          <Space height={16} />
          <FieldRowContainer>
            <FieldContainer>
              <FieldInput
                label={'Lot Number'}
                index={0}
                size={FieldInput.SIZE.MEDIUM}
                input={{
                  value: state.lotNumber,
                  disabled: existingItem && state.isVoid,
                  placeholder: 'Lot Number',
                  style: {flex: 1},
                  onChangeText: (lotNumber) => {
                    dispatch({type: SET_LOT_NUMBER, payload: lotNumber});
                  },
                }}
              />
            </FieldContainer>
            <Space width={8} />
            <FieldContainer>
              <FieldInput
                label={'Color'}
                index={1}
                size={FieldInput.SIZE.MEDIUM}
                component={DropdownInput}
                input={{
                  value: state.color,
                  fontSize: 16,
                  disabled: existingItem && state.isVoid,
                  isPortaled: true,
                  isSearchable: false,
                  options: Inventory.getColorOptions(),
                  placeholder: 'Color',
                  setFieldValue: () => {},
                  onChangeValue: (color) => {
                    dispatch({type: SET_COLOR, payload: color});
                  },
                  style: {flex: 1},
                  components: {
                    SingleValue: (props) => {
                      return (
                        <DropdownInput.SingleValueContainer {...props}>
                          <ColorOption value={props.data.value} label={props.data.label} />
                        </DropdownInput.SingleValueContainer>
                      );
                    },
                    Option: (props) => {
                      return (
                        <DropdownInput.OptionContainer {...props}>
                          <ColorOption
                            isSelected={props.isSelected}
                            value={props.data.value}
                            label={props.data.label}
                          />
                        </DropdownInput.OptionContainer>
                      );
                    },
                  },
                }}
              />
            </FieldContainer>
            <Space width={8} />
            <FieldContainer>
              <FieldInput
                label={'Item Number'}
                index={2}
                size={FieldInput.SIZE.MEDIUM}
                isRequired
                isTruncatedRequiredLabel
                showErrorMessage={false}
                errorName={'itemNumber'}
                errors={state.errors}
                touched={state.errors}
                input={{
                  value: state.itemNumber,
                  disabled: existingItem && state.isVoid,
                  placeholder: 'Item number',
                  keyboardType: 'phone-pad',
                  style: {flex: 1},
                  onChangeText: (itemNumber) => {
                    dispatch({type: SET_ITEM_NUMBER, payload: itemNumber});
                    dispatch({type: SET_ERRORS, payload: {...state.errors, itemNumber: null}});
                  },
                }}
              />
            </FieldContainer>
          </FieldRowContainer>
          {/* Error container for top three fields */}
          {state.errors.itemNumber && (
            <React.Fragment>
              <Space height={8} />
              <FieldRowContainer>
                <BodyText color={colors.red.warning}>{state.errors.itemNumber}</BodyText>
              </FieldRowContainer>
            </React.Fragment>
          )}
          <Space height={16} />
          {calloutText !== '' && (
            <>
              <Callout text={calloutText} />
              <Space height={16} />
            </>
          )}
          <SubHeadingText>Item</SubHeadingText>
          <Space height={16} />
          <SecondaryButton
            isWidthOfContainer
            text={'Select item'}
            onPress={selectItemTypeDrawer.handleOpen}
          />
          <Space height={16} />
          <FieldInput
            label={'Item'}
            index={3}
            size={FieldInput.SIZE.MEDIUM}
            isRequired
            errorName={'name'}
            errors={state.errors}
            touched={state.errors}
            input={{
              value: state.name,
              placeholder: 'Item name',
              style: {flex: 1},
              onChangeText: (name) => {
                dispatch({type: SET_NAME, payload: name});
                dispatch({type: SET_ERRORS, payload: {...state.errors, name: null}});
              },
            }}
          />
          <Space height={16} />
          <FieldRowContainer>
            <FieldContainer>
              <FieldInput
                label={'Volume (cu ft)'}
                index={4}
                size={FieldInput.SIZE.MEDIUM}
                input={{
                  value: state.volume,
                  placeholder: 'Enter volume',
                  keyboardType: 'phone-pad',
                  style: {flex: 1},
                  onChangeText: (volume) => {
                    dispatch({type: SET_VOLUME, payload: volume});
                  },
                }}
              />
            </FieldContainer>
            <Space width={8} />
            <FieldContainer>
              <FieldInput
                label={'Weight (lbs)'}
                index={5}
                size={FieldInput.SIZE.MEDIUM}
                input={{
                  value: state.weight,
                  placeholder: 'Enter weight',
                  keyboardType: 'phone-pad',
                  style: {flex: 1},
                  onChangeText: (weight) => {
                    dispatch({type: SET_WEIGHT, payload: weight});
                  },
                }}
              />
            </FieldContainer>
            <Space width={8} />
            <FieldContainer>
              <LabelText>Price</LabelText>
              <Space height={8} />
              <CurrencyInput
                label={'Price'}
                component={FieldInput.TextInput}
                placeholder={'$0.00'}
                index={6}
                size={FieldInput.SIZE.MEDIUM}
                value={state.price}
                setFieldValue={() => {}}
                setFieldTouched={() => {}}
                onChangeText={(price) => {
                  dispatch({type: SET_PRICE, payload: price});
                }}
                style={{
                  flex: 1,
                  maxHeight: '48px',
                }}
              />
            </FieldContainer>
          </FieldRowContainer>
          <Space height={16} />
          <FieldInput
            label={'Notes'}
            index={7}
            size={FieldInput.SIZE.MEDIUM}
            input={{
              value: state.notes,
              placeholder: 'Add notes',
              multiline: true,
              style: {height: 72, paddingTop: 8},
              onChangeText: (notes) => {
                dispatch({type: SET_NOTES, payload: notes});
              },
            }}
          />
          <Space height={16} />
          <SubHeadingText>Item Tags</SubHeadingText>
          <Space height={16} />
          <SecondaryButton
            isWidthOfContainer
            text={'Select Item Tags'}
            onPress={itemTagsDrawer.handleOpen}
          />
          <Space height={16} />
          {selectedItemTagIds.length ? (
            <React.Fragment>
              <ItemTagsDrawer.ItemTagPreview
                selectedItemTagIds={selectedItemTagIds}
                inventoryLibrary={inventoryLibrary}
              />
              <Space height={16} />
            </React.Fragment>
          ) : null}
          <ExceptionsSection
            itemTagItems={state.itemTagItems}
            inventoryLibrary={inventoryLibrary}
            exceptionsDrawer={exceptionsDrawer}
            setExceptionForEdit={setExceptionForEdit}
            setExceptionForDelete={setExceptionForDelete}
            deleteExceptionModal={deleteExceptionModal}
          />
          <Space height={16} />
          <ItemPhotosSection
            itemAttachments={state.itemAttachments}
            uploadPhotosDrawer={uploadPhotosDrawer}
          />
        </ScrollView>
      </ContentContainer>
      <BottomButtonContainer>
        <Button
          isDisabled={isFinalized}
          style={{height: '40px'}}
          isWidthOfContainer
          text={existingItem ? 'Save' : 'Save and Add Next Item'}
          onPress={() => {
            if (existingItem) {
              editItem({
                form,
                roomUuid,
                itemUuid,
                item: state,
                onSuccess: () => {
                  successToast.handleToast();
                  navigator.push('ShowDriverInventoryRoom', {roomUuid});
                },
                onError: (errors) => {
                  dispatch({type: SET_ERRORS, payload: errors});
                  scrollView.handleScrollToTop({animated: true});
                  errorToast.handleToast();
                },
              });
            } else {
              addItem({
                form,
                room,
                roomUuid,
                item: state,
                onSuccess: (item) => {
                  successToast.handleToast();
                  dispatch({type: RESET_FIELDS});
                  dispatch({type: RESET_ITEM_TAG_ITEMS});
                  dispatch({type: RESET_ITEM_ATTACHMENTS});
                  dispatch({
                    type: SET_ITEM_NUMBER,
                    payload: incrementItemNumber(item.itemNumber),
                  });
                  setNextItemInitialState({
                    lotNumber: item.lotNumber,
                    color: item.color,
                    itemNumber: incrementItemNumber(item.itemNumber),
                  });
                  scrollView.handleScrollToTop({animated: true});
                },
                onError: (errors) => {
                  dispatch({type: SET_ERRORS, payload: errors});
                  scrollView.handleScrollToTop({animated: true});
                  errorToast.handleToast();
                },
              });
            }
          }}
        />
      </BottomButtonContainer>
      <SelectItemTypeDrawer
        key={selectItemTypeDrawer.key}
        inventoryLibrary={inventoryLibrary}
        isOpen={selectItemTypeDrawer.isOpen}
        handleClose={() => {
          // HACK(cooper): We have an issue with our modals/drawers where the onClose click event is propagating
          // to the parent component which results in accidentally selecting inputs on this screen. This is
          // particularly bothersome on mobile since the keyboard will pop up at unwanted times.
          // Waiting until the next tick to close the drawer fixes this until we can solve the greater issue.
          setTimeout(selectItemTypeDrawer.handleClose, 0);
        }}
        handleItemTypeSelect={(itemType) => {
          dispatch({type: SET_FIELDS_FOR_ITEM_TYPE, payload: itemType});
          dispatch({type: SET_ERRORS, payload: {...state.errors, name: null}});
        }}
        primaryCategoryId={room.primaryCategoryId}
      />
      <ItemTagsDrawer
        key={itemTagsDrawer.key}
        inventoryLibrary={inventoryLibrary}
        isOpen={itemTagsDrawer.isOpen}
        handleClose={itemTagsDrawer.handleClose}
        selectedItemTagIds={selectedItemTagIds}
        handleSelectItemTag={(selectedItemTagId) => {
          // First, check if this selectedItemTagId was previously added and then removed (isDeleted=true)
          const previouslyDeleted = _.find(state.itemTagItems, {itemTagIds: [selectedItemTagId]});
          if (previouslyDeleted) {
            dispatch({
              type: SET_ITEM_TAG_ITEMS,
              payload: [
                ..._.filter(state.itemTagItems, ({uuid}) => uuid !== previouslyDeleted.uuid),
                {
                  ...previouslyDeleted,
                  isDeleted: false,
                },
              ],
            });
          } else {
            // Add a completely new itemTagItem
            dispatch({
              type: SET_ITEM_TAG_ITEMS,
              payload: [
                ...state.itemTagItems,
                {
                  itemTagIds: [selectedItemTagId],
                  kind: ItemTagItemKind.ITEM_TAG,
                  uuid: uuid(),
                },
              ],
            });
          }
        }}
        handleDeselectItemTag={(deselectedItemTagId) => {
          // First, find the itemTag that was previously added so that we can delete it
          const previouslyAdded = _.find(state.itemTagItems, {itemTagIds: [deselectedItemTagId]});
          dispatch({
            type: SET_ITEM_TAG_ITEMS,
            payload: [
              ..._.filter(state.itemTagItems, ({uuid}) => uuid !== previouslyAdded.uuid),
              {
                ...previouslyAdded,
                isDeleted: true,
              },
            ],
          });
        }}
      />
      <ExceptionsDrawer
        key={exceptionsDrawer.key}
        existingItemTagItem={exceptionForEdit}
        inventoryLibrary={inventoryLibrary}
        isOpen={exceptionsDrawer.isOpen}
        handleClose={() => {
          setExceptionForEdit(null);
          exceptionsDrawer.handleClose();
        }}
        handleCreateException={(itemTagItem) => {
          dispatch({type: SET_ITEM_TAG_ITEMS, payload: [...state.itemTagItems, itemTagItem]});
        }}
        handleEditException={({uuid: itemTagItemUuid, itemTagIds}) => {
          dispatch({
            type: SET_ITEM_TAG_ITEMS,
            payload: state.itemTagItems.reduce((acc, itemTagItem) => {
              if (itemTagItem.uuid === itemTagItemUuid) {
                return [...acc, {...itemTagItem, itemTagIds}];
              } else {
                return [...acc, itemTagItem];
              }
            }, []),
          });
        }}
      />
      <DeleteExceptionModal
        deleteExceptionModal={deleteExceptionModal}
        handleClose={() => setExceptionForDelete(null)}
        handleDelete={() => {
          const {uuid: itemTagItemUuid} = exceptionForDelete;
          dispatch({
            type: SET_ITEM_TAG_ITEMS,
            payload: state.itemTagItems.reduce((acc, itemTagItem) => {
              if (itemTagItem.uuid === itemTagItemUuid) {
                return [...acc, {...itemTagItem, isDeleted: true}];
              } else {
                return [...acc, itemTagItem];
              }
            }, []),
          });
          deleteExceptionModal.handleClose();
        }}
      />
      <UnsavedChangesModal
        unsavedChangesModal={unsavedChangesModal}
        handleNavigate={() => handleBackNavigate({navigator, fromViewFullInventory, roomUuid})}
      />
      <UploadPhotosDrawer
        key={uploadPhotosDrawer.key}
        organizationId={organizationId}
        existingItemAttachments={state.itemAttachments}
        isOpen={uploadPhotosDrawer.isOpen}
        handleClose={uploadPhotosDrawer.handleClose}
        handleSave={(itemAttachments) => {
          dispatch({type: SET_ITEM_ATTACHMENTS, payload: itemAttachments});
          uploadPhotosDrawer.handleClose();
        }}
      />
      <ItemActionsPopover
        popover={itemActionsPopover}
        state={state}
        handleVoidOrUnvoidLabel={() => {
          if (!state.isVoid && !state.name) {
            dispatch({type: SET_NAME, payload: 'Void item'});
          }
          dispatch({type: SET_IS_VOID, payload: !state.isVoid});
          itemActionsPopover.handleClose();
        }}
        handleDuplicateItem={() => {
          const handleSuccess = () => {
            duplicateToast.handleToast();
            dispatch({type: SET_ITEM_NUMBER, payload: incrementItemNumber(state.itemNumber)});
            dispatch({type: RESET_ITEM_TAG_ITEMS});
            dispatch({type: RESET_ITEM_ATTACHMENTS});
            setNextItemInitialState({
              lotNumber: state.lotNumber,
              color: state.color,
              itemNumber: incrementItemNumber(state.itemNumber),
            });
            scrollView.handleScrollToTop({animated: true});
            itemActionsPopover.handleClose();
            navigator.push('CreateDriverInventoryItem', {
              roomUuid: room.uuid,
            });
          };

          const handleError = (errors) => {
            dispatch({type: SET_ERRORS, payload: errors});
            scrollView.handleScrollToTop({animated: true});
            errorToast.handleToast();
            itemActionsPopover.handleClose();
          };

          if (existingItem) {
            editItem({
              form,
              roomUuid,
              itemUuid,
              item: state,
              onSuccess: handleSuccess,
              onError: handleError,
            });
          } else {
            addItem({
              form,
              room,
              roomUuid,
              item: state,
              onSuccess: handleSuccess,
              onError: handleError,
            });
          }
        }}
      />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DriverInventoryItemFields.fragment = gql`
  ${SelectItemTypeDrawer.fragment}
  ${ItemTagsDrawer.fragment}
  ${ExceptionsDrawer.fragment}
  ${ItemTagItemForm.getSelectedItemTagsForItemTagKind.fragment}

  fragment DriverInventoryItemFields on Inventory {
    id
    isFinalized
    project {
      id
      projectType {
        id
        defaultDriverInventoryLibrary {
          id
          ...SelectItemTypeDrawer
          ...ItemTagsDrawer
          ...ExceptionsDrawer
          ...ItemTagItemForm_getSelectedItemTagsForItemTagKind
        }
      }
      organization {
        id
      }
    }
  }
`;

export default DriverInventoryItemFields;
