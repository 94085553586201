// Supermove
import _ from 'lodash';

import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import LocationKind from '@shared/modules/Location/enums/LocationKind';

const BUILDING_TYPE = 'buildingType';
const UNIT = 'unit';
const FLOOR_NUMBER = 'floorNumber';
const STAIR_COUNT = 'stairCount';
const HAS_ELEVATOR = 'hasElevator';
const HAS_LONG_WALK = 'hasLongWalk';

const AdditionalInformation = {
  BUILDING_TYPE,
  UNIT,
  FLOOR_NUMBER,
  STAIR_COUNT,
  HAS_ELEVATOR,
  HAS_LONG_WALK,
  Values: [BUILDING_TYPE, UNIT, FLOOR_NUMBER, STAIR_COUNT, HAS_ELEVATOR, HAS_LONG_WALK],
  fragment: gql`
    fragment Location_AdditionalInformation on Location {
      id
      buildingType
      unit
      floorNumber
      stairCount
      hasElevator
      hasLongWalk
    }
  `,
};

const getDisplayName = withFragment(
  (location, {count}) => {
    return `${LocationKind.display(location.kind)} Location (${location.index + 1} of ${count})`;
  },
  gql`
    fragment Location_getDisplayName on Location {
      id
      kind
      index
    }
  `,
);

/*
 * Return location for display purposes.
 *
 * Custom name
 * Address with zip code
 * Unit number
 */
const getDisplayLocation = withFragment(
  ({address, name, unit, zipCode}) => {
    if (!address) {
      return 'Unknown';
    }
    let location = address.replace(', USA', '');
    if (zipCode && !location.includes(zipCode)) {
      location = `${location}, ${zipCode}`;
    }
    if (unit) {
      location = `${location}\nUnit ${unit}`;
    }
    if (name) {
      location = `${name}\n${location}`;
    }
    return location;
  },
  gql`
    fragment Location_getDisplayLocation on Location {
      id
      address
      name
      unit
      zipCode
    }
  `,
);

/**
 * Return location as City, Zip
 * e.g
 * "New York 88888"
 */
const getDisplayCityZip = withFragment(
  ({city, zipCode}) => {
    if (!city && !zipCode) {
      return 'Unknown';
    }
    if (!city) {
      return zipCode;
    }
    if (!zipCode) {
      return city;
    }
    return `${city} ${zipCode}`;
  },
  gql`
    fragment Location_getDisplayCityZip on Location {
      id
      city
      zipCode
    }
  `,
);

/**
 * Return location as City, State
 * e.g
 * "New York, NY"
 */
const getDisplayCityState = withFragment(
  (location, emptyText) => {
    const city = _.get(location, 'city');
    const state = _.get(location, 'state');

    if (!city && !state) {
      return emptyText || '';
    }

    if (city && state) {
      return `${city}, ${state}`;
    }

    return city || state;
  },
  gql`
    fragment Location_getDisplayCityState on Location {
      id
      city
      state
    }
  `,
);

/**
 * Return location as City, State Zip
 * e.g
 * "New York, NY 88888"
 */
const getDisplayCityStateZip = withFragment(
  (location, emptyText) => {
    const zipCode = _.get(location, 'zipCode');

    if (!zipCode) {
      return getDisplayCityState(location, emptyText);
    }

    const displayCityState = getDisplayCityState(location);
    return displayCityState ? `${displayCityState} ${zipCode}` : zipCode;
  },
  gql`
    ${getDisplayCityState.fragment}

    fragment Location_getDisplayCityStateZip on Location {
      id
      zipCode
      ...Location_getDisplayCityState
    }
  `,
);

/**
 * Return location as Address, City, State Zip
 * e.g
 * "111 2nd Ave, New York, NY 88888"
 */
const getDisplayAddressCityStateZip = withFragment(
  (location, emptyText) => {
    if (!location.address) {
      return getDisplayCityStateZip(location, emptyText);
    }

    const addressWithoutCountry = location.address.replace(', USA', '');
    return `${addressWithoutCountry} ${location.zipCode}`;
  },
  gql`
    ${getDisplayCityStateZip.fragment}

    fragment Location_getDisplayAddressCityStateZip on Location {
      id
      address
      ...Location_getDisplayCityStateZip
    }
  `,
);

const salesAppBuildingTypes = {
  House: 'House',
  Apartment: 'Apartment',
  Storage: 'Storage',
  Condo: 'Condo',
  Office: 'Office',
  Townhouse: 'Townhouse',
  Warehouse: 'Warehouse',
};

const getSalesAppBuildingTypesDropdoownOptions = () => {
  return Object.keys(salesAppBuildingTypes).map((key) => ({
    label: salesAppBuildingTypes[key],
    value: salesAppBuildingTypes[key],
  }));
};

const getLocationType = ({index, total}) => {
  if (index === 0) {
    return 'Origin';
  }
  if (index === total - 1) {
    return 'Destination';
  }
  return 'Additional Stop';
};

const Location = {
  getDisplayLocation,
  getDisplayName,
  getLocationType,
  getDisplayAddressCityStateZip,
  getDisplayCityZip,
  getDisplayCityStateZip,
  getDisplayCityState,

  salesAppBuildingTypes,
  getSalesAppBuildingTypesDropdoownOptions,

  AdditionalInformation,
};

export default Location;
