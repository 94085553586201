// Libraries
import React from 'react';

// Supermove
import {Space, Styled, SupermoveLogo, ScrollView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import Avatar from '@supermove/manager/src/modules/App/components/Avatar';
import {User} from '@supermove/models';
import {Storage} from '@supermove/sdk';
import {Typography, colors} from '@supermove/styles';

// App
import MoverAppSelectAccountForm from '@shared/modules/User/forms/MoverAppSelectAccountForm';
import useMoverAppSelectAccountMutation from '@shared/modules/User/hooks/useMoverAppSelectAccountMutation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';

const Content = Styled.View`
  flex: 1;
  width: 100%;
  max-width: 600px;
  padding-horizontal: 24px;
  padding-vertical: 16px;
  flex-direction: column;
  justify-content: center;
`;

const TitleText = Styled.Text`
  ${Typography.PageHeading}
`;

const MobileLabelText = Styled.Text`
  ${Typography.Mobile.Label}
`;

const MobileBodyText = Styled.Text`
  ${Typography.Mobile.Body}
`;

const AccountTile = Styled.View`
  flex-direction: row;
  align-items: center;
  border-width: 1px;
  border-radius: 4px;
  border-color: ${colors.gray.border}
  padding: 16px;
`;

const View = Styled.View``;

const Touchable = Styled.ButtonV2``;

const MoverAppSelectAccountPageContent = ({viewer, accounts}) => {
  const {form, handleSubmit} = useMoverAppSelectAccountMutation({
    moverAppSelectAccountForm: MoverAppSelectAccountForm.new({userId: viewer.id}),
    onSuccess: async ({token, user}) => {
      await Storage.setItem('token', token);
      await Storage.setItem('userId', user.id);

      // Hard refresh the page
      window.location.href = '/calendar';
    },
    onError: (errors) => {
      console.log(errors);
    },
  });

  return (
    <Content>
      <Space height={48} />
      <SupermoveLogo.Name color={SupermoveLogo.COLORS.BLACK} size={36} />
      <Space height={24} />
      <TitleText>{`Welcome back ${viewer.firstName}!`}</TitleText>
      <Space height={16} />
      <MobileBodyText>Select the company that you'd like to log in to.</MobileBodyText>
      <Space height={24} />
      <ScrollView>
        {accounts.map((account, index) => {
          return (
            <React.Fragment key={index}>
              <Touchable
                onPress={() => {
                  form.setFieldValue('moverAppSelectAccountForm.userId', account.id);
                  setTimeout(() => handleSubmit(), 0);
                }}
              >
                <AccountTile>
                  <Avatar size={40} text={User.getInitials(account)} />
                  <Space width={16} />
                  <View>
                    <MobileLabelText>{account.fullName}</MobileLabelText>
                    <Space height={4} />
                    <MobileBodyText>{account.organization.name}</MobileBodyText>
                  </View>
                </AccountTile>
              </Touchable>
              <Space height={16} />
            </React.Fragment>
          );
        })}
      </ScrollView>
    </Content>
  );
};

const MoverAppSelectAccountPage = () => {
  const {loading, data} = useQuery(MoverAppSelectAccountPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  if (loading) return <PageLoadingIndicator />;

  return (
    <MoverAppSelectAccountPageContent
      viewer={data.viewer}
      accounts={data.employeeAccountsForViewerPhoneNumber}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
MoverAppSelectAccountPage.query = gql`
  query MoverAppSelectAccountPage {
    ${gql.query}
    viewer {
      id
      firstName
    }
    employeeAccountsForViewerPhoneNumber {
      id
      fullName
      firstName
      organization {
        id
        name
      }
    }
  }
`;

export default MoverAppSelectAccountPage;
