// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Drawer, Icon, Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';

const ContentContainer = Styled.View`
  background-color: ${colors.gray.background};
  max-height: 75%;
  height: ${({isFixedHeight}: {isFixedHeight: boolean}) => (isFixedHeight ? '75%' : 'auto')};
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
`;

const HeaderRow = Styled.View`
  flex-direction: row;
  height: 60px;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 8px;
  padding-horizontal: 16px;
`;

const HeaderSideColumn = Styled.View`
  width: 24px;
`;

const HeaderLabel = Styled.Text`
  ${Typography.Mobile.Heading2}
  font-size: 20px;
`;

const View = Styled.View`
`;

const DoneText = Styled.Text`
  ${Typography.Responsive.Label}
  color: ${colors.blue.interactive};
`;

const PreventPropagationContainer = ({
  children,
  style,
}: {
  children: React.ReactNode;
  style: object;
}) => {
  return (
    <View
      onStartShouldSetResponder={(event: any) => true}
      onTouchEnd={(e: any) => {
        e.stopPropagation();
      }}
      style={style}
    >
      {children}
    </View>
  );
};

const DoneButton = ({handleClose}: {handleClose: () => void}) => {
  const responsive = useResponsive();

  return (
    <TertiaryButton onPress={() => setTimeout(handleClose, 0)} isHitSlop>
      <DoneText responsive={responsive}>Done</DoneText>
    </TertiaryButton>
  );
};

const HeaderActionIcon = ({
  icon,
  size,
  handleAction,
  isDisabled,
}: {
  icon: any;
  size?: number;
  handleAction: () => void;
  isDisabled: boolean;
}) => {
  return (
    <TertiaryButton onPress={handleAction} isHitSlop isDisabled={isDisabled}>
      <Icon source={icon} color={colors.gray.primary} size={size || 20} />
    </TertiaryButton>
  );
};

interface SheetProps {
  isOpen?: boolean;
  handleClose?: () => void;
  shouldCloseOnClickOutside?: boolean;
  headerText?: string;
  headerStyle?: object;
  style?: object;
  isFixedHeight?: boolean;
  isDisabledClose?: boolean;
  isAnimated?: boolean;
  HeaderLeftComponent?: React.FC<{
    isDisabledClose?: boolean;
  }>;
  HeaderRightComponent?: React.FC<{
    isDisabledClose: boolean;
    handleClose: () => void;
  }>;
  handleGoBack?: () => void;
  children: React.ReactNode;
}

const Sheet = ({
  isOpen,
  handleClose = () => {},
  shouldCloseOnClickOutside,
  headerText,
  headerStyle,
  style,
  isFixedHeight,
  isDisabledClose = false,
  isAnimated = true,
  HeaderLeftComponent = () => null,
  HeaderRightComponent,
  handleGoBack,
  children,
}: SheetProps) => {
  return (
    <Drawer
      isOpen={isOpen}
      onClose={handleClose}
      shouldCloseOnClickOutside={shouldCloseOnClickOutside && !isDisabledClose}
      position={Drawer.POSITIONS.BOTTOM}
      isAnimated={isAnimated}
    >
      <ContentContainer style={style} isFixedHeight={isFixedHeight}>
        <HeaderRow style={headerStyle}>
          <HeaderSideColumn style={{alignItems: 'flex-start'}}>
            {handleGoBack ? (
              <HeaderActionIcon
                icon={Icon.ChevronLeft}
                handleAction={handleGoBack}
                isDisabled={isDisabledClose}
              />
            ) : (
              <HeaderLeftComponent isDisabledClose={isDisabledClose} />
            )}
          </HeaderSideColumn>
          <Space style={{flex: 1}} />
          <HeaderLabel numberOfLines={1}>{headerText}</HeaderLabel>
          <Space style={{flex: 1}} />
          <HeaderSideColumn style={{alignItems: 'flex-end'}}>
            {HeaderRightComponent ? (
              <HeaderRightComponent isDisabledClose={isDisabledClose} handleClose={handleClose} />
            ) : (
              <HeaderActionIcon
                icon={Icon.Xmark}
                handleAction={handleClose}
                isDisabled={isDisabledClose}
              />
            )}
          </HeaderSideColumn>
        </HeaderRow>
        {children}
      </ContentContainer>
    </Drawer>
  );
};

Sheet.HeaderActionIcon = HeaderActionIcon;
Sheet.PreventPropagationContainer = PreventPropagationContainer;
Sheet.DoneButton = DoneButton;

// --------------------------------------------------
// Props
// --------------------------------------------------
Sheet.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  shouldCloseOnClickOutside: PropTypes.bool,
  headerText: PropTypes.string,
  headerStyle: PropTypes.object,
  style: PropTypes.object,
  HeaderLeftComponent: PropTypes.func,
  HeaderRightComponent: PropTypes.func,
  isFixedHeight: PropTypes.bool,
  children: PropTypes.node.isRequired,
  isDisabledClose: PropTypes.bool,
  isAnimated: PropTypes.bool,
};

Sheet.defaultProps = {
  isOpen: false,
  handleClose: () => {},
  shouldCloseOnClickOutside: true,
  headerText: '',
  headerStyle: null,
  style: null,
  isFixedHeight: false,
  HeaderLeftComponent: () => null,
  HeaderRightComponent: null,
  isDisabledClose: false,
  isAnimated: true,
};

export default Sheet;
