// Libararies
import React from 'react';

// Supermove
import {useResponsive} from '@supermove/hooks';

// App
import Badge from '@shared/design/components/Badge';

interface ResponsiveBadgeProps {
  label: string;
  color?: string;
  width?: number;
  backgroundColor: string;
  textColor: string;
  iconLeft?: any;
  iconRight?: any;
  iconSize?: any;
  isWidthOfContainer?: boolean;
  onIconRightPress?: () => void;
  style?: React.CSSProperties;
}

const ResponsiveBadge = ({
  label,
  color,
  width,
  backgroundColor,
  textColor,
  iconLeft,
  iconRight,
  iconSize,
  isWidthOfContainer,
  onIconRightPress,
  style,
}: ResponsiveBadgeProps) => {
  const responsive = useResponsive();
  const responsiveSize = responsive.mobile ? Badge.SIZE.LARGE : Badge.SIZE.SMALL;
  return (
    <Badge
      label={label}
      color={color}
      width={width}
      size={responsiveSize}
      backgroundColor={backgroundColor}
      textColor={textColor}
      iconLeft={iconLeft}
      iconRight={iconRight}
      iconSize={iconSize}
      isWidthOfContainer={isWidthOfContainer}
      onIconRightPress={onIconRightPress}
      style={style}
    />
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
ResponsiveBadge.propTypes = {};

ResponsiveBadge.defaultProps = {};

export default ResponsiveBadge;
