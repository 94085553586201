// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Drawer, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useRef, useState, useEffect} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Checkbox from '@shared/design/components/Checkbox';
import SearchBar from '@shared/design/components/SearchBar';
import ItemTagKind from '@shared/modules/Inventory/enums/ItemTagKind';
import ItemTagItemForm from '@shared/modules/Inventory/forms/ItemTagItemForm';

const View = Styled.View`
`;

const DrawerContainer = Styled.View`
  flex: 1;
  padding-horizontal: 16px;
  background-color: ${colors.gray.background}
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const Row = Styled.View`
  align-items: center;
  flex-direction: row;
`;

const DrawerTitle = Styled.Text`
  ${Typography.PageHeading}
`;

const ItemTagPreviewContainer = Styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  padding-vertical: 4px;
  padding-horizontal: 8px;
  background-color: ${colors.gray.background}
  border-color: ${colors.gray.border}
  border-width: 1px;
  border-radius: 8px;
`;

const ItemTagBadge = Styled.View`
  margin-vertical: 4px;
  padding-vertical: 4px;
  padding-horizontal: 12px;
  margin-right: 8px;
  background-color: ${colors.blue.accent}
  border-radius: 4px;
`;

const ItemTagBadgeText = Styled.Text`
  ${Typography.Mobile.Label}
  color: ${colors.blue.interactive}
`;

const SubHeadingText = Styled.Text`
  ${Typography.Heading2}
`;

const MobileBodyText = Styled.Text`
  ${Typography.Mobile.Body}
`;

const CheckboxContainer = Styled.ButtonV2`
  flex: 1;
`;

const ItemTagsDrawerContent = ({
  selectedItemTagIds,
  inventoryLibrary,
  handleSelectItemTag,
  handleDeselectItemTag,
}) => {
  const ref = useRef();
  const [filteredItemTags, setFilteredItemTags] = useState(inventoryLibrary.itemTags);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    setFilteredItemTags(
      inventoryLibrary.itemTags.filter(({name}) =>
        _.includes(name.toLowerCase(), searchTerm.toLowerCase()),
      ),
    );
  }, [searchTerm, inventoryLibrary]);

  const handleCheckboxPress = (isSelected, itemTag) => {
    if (isSelected) {
      handleDeselectItemTag(itemTag.id);
    } else {
      handleSelectItemTag(itemTag.id);
    }
  };

  return (
    <React.Fragment>
      <Row ref={ref}>
        <SearchBar
          placeholder={'Search item tags'}
          iconColor={colors.gray.tertiary}
          containerStyle={{flex: 1}}
          style={{width: '100%'}}
          onChangeText={(value) => {
            setSearchTerm(value);
          }}
          valueOverride={searchTerm}
          isResponsive
          isClearable
        />
      </Row>
      <Space height={16} />
      <View style={{flex: 1}}>
        <ScrollView>
          {filteredItemTags.map((itemTag, index) => {
            const isSelected = _.includes(selectedItemTagIds, itemTag.id);
            return (
              <Row key={index} style={{paddingHorizontal: 16, paddingVertical: 12}}>
                <CheckboxContainer onPress={() => handleCheckboxPress(isSelected, itemTag)}>
                  <Checkbox
                    isChecked={isSelected}
                    isMobile
                    label={itemTag.name}
                    handleToggle={() => handleCheckboxPress(isSelected, itemTag)}
                  />
                </CheckboxContainer>
              </Row>
            );
          })}
          <Space height={104} />
        </ScrollView>
      </View>
    </React.Fragment>
  );
};

const ItemTagPreview = ({selectedItemTagIds, inventoryLibrary}) => {
  const selectedItemTags = ItemTagItemForm.getSelectedItemTagsForItemTagKind(inventoryLibrary, {
    selectedItemTagIds,
    itemTagKind: ItemTagKind.ITEM_TAG,
  });

  return (
    <ItemTagPreviewContainer style={{maxHeight: '128px', overflowY: 'auto'}}>
      {!selectedItemTagIds.length && (
        <MobileBodyText style={{color: colors.gray.tertiary}}>
          Make a selection to add an item tag
        </MobileBodyText>
      )}
      {selectedItemTags.map((itemTag, index) => (
        <React.Fragment key={index}>
          <ItemTagBadge>
            <ItemTagBadgeText>{itemTag.name}</ItemTagBadgeText>
          </ItemTagBadge>
        </React.Fragment>
      ))}
    </ItemTagPreviewContainer>
  );
};

const ItemTagsDrawer = ({
  selectedItemTagIds,
  inventoryLibrary,
  handleSelectItemTag,
  handleDeselectItemTag,
  isOpen,
  handleClose,
}) => {
  return (
    <Drawer
      isOpen={isOpen}
      onClose={handleClose}
      position={'BOTTOM'}
      contentContainerStyle={{top: '15svh', height: '85svh'}}
      // TODO(cooper): shouldCloseOnClickOutside must be disabled on mobile views due to an intermittent issue
      // where the popover opens and immediately closes. We should look into why this happens and how to get
      // around it. See this GitHub issue for more info
      // https://github.com/mui/material-ui/issues/3335
      shouldCloseOnClickOutside={false}
    >
      <DrawerContainer>
        <Space height={16} />
        <Row>
          <DrawerTitle>Select Item Tags</DrawerTitle>
          <Space style={{flex: 1}} />
          <TertiaryButton onPress={handleClose} text={'Close'} />
        </Row>
        <View style={{flex: 1}}>
          {selectedItemTagIds.length ? (
            <React.Fragment>
              <Space height={16} />
              <SubHeadingText>Tags Preview</SubHeadingText>
              <Space height={8} />
              <ItemTagPreview
                selectedItemTagIds={selectedItemTagIds}
                inventoryLibrary={inventoryLibrary}
              />
            </React.Fragment>
          ) : null}
          <Space height={16} />
          <ItemTagsDrawerContent
            selectedItemTagIds={selectedItemTagIds}
            inventoryLibrary={inventoryLibrary}
            handleSelectItemTag={handleSelectItemTag}
            handleDeselectItemTag={handleDeselectItemTag}
          />
        </View>
      </DrawerContainer>
    </Drawer>
  );
};

ItemTagsDrawer.ItemTagPreview = ItemTagPreview;

// --------------------------------------------------
// Data
// --------------------------------------------------
ItemTagsDrawer.fragment = gql`
  fragment ItemTagsDrawer on InventoryLibrary {
    id
    itemTags: itemTagsByKind(itemTagKind: "${ItemTagKind.ITEM_TAG}") {
      id
      kind
      name
    }
  }
`;

export default ItemTagsDrawer;
